* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  letter-spacing: 0;
}

.modal .show {
  overflow: hidden;
  position: fixed;
}

:root {
  --dark-color: #0b132b;
  --theme-color: #2bc7c9;
  --light-theme: #f5feff;
  --light-theme-grey: #fbfbfb;
  --heading-color: #021325;
  --sub-text-color: #242b35;
  --list-color: #727272;
  --red: #ff3300;
  --white: #fff;
  --small-text: 12px;
  --main-spacing-top: 40px;
  --success: #51b74e;
}

.bg-none {
  background-color: transparent;
}

.top-band {
  overflow-x: hidden !important;
}

.top-band .slick-slide.slick-active:hover {
  border: transparent;
  box-shadow: 3px 2px 19px 0 #1de5f01c;
}

.freedo-primary-button:focus,
.freedo-primary-button-lg:focus,
.freedo-outline-btn:focus,
.freedo-outline-btn-small:focus,
button:focus,
.accordion-button:focus {
  border-color: var(--theme-color);
  outline: none;
  box-shadow: none;
}

.cancel-booking-btn {
  border: solid 1px red;
  padding: 6px 15px;
  font-size: 0.9em;
  background-color: transparent;
  transition: all ease-in 0.3s;
  color: red;
}

.cancel-booking-btn:hover {
  color: white !important;
  background-color: red !important;
  border: none;
}

#cancel-modal {
  display: flex !important;
  align-items: center !important;
}

.freedo-primary-button:hover {
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  background-color: #2bc7c9;
  border: none;
}

.btn-primary:hover {
  background-color: #2bc7c9;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  border: none;
}

.freedo-txt {
  color: var(--theme-color);
}

a {
  color: var(--theme-color);
}

h1 {
  font-size: 2.1875em;
}

h2 {
  font-size: 1.5625em;
  font-weight: 600;
}

h3 {
  font-size: 1.375em;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--theme-color);
}

.table > thead {
  vertical-align: top;
}

/* #titlediv.hide_tooltip, .zsiq_cnt .siqico-close, .zsiq_theme1 div.zsiq_min.zsiq_cnt {
  display: unset !important;
} */

/* ------------------- 20px ------------------- */
h4 {
  font-size: 1.25em;
}

/* ------------------- 18px ------------------- */
h5 {
  font-size: 1.125em;
}

/* ------------------- 16px ------------------- */
h6,
p,
.nav-link {
  font-size: 1em;
}

/* ------------------- 15px ------------------- */
.small-text,
span,
a,
.form-check,
label {
  font-size: 0.87em;
}

/* ------------------- 13px ------------------- */
.smallest-text {
  font-size: 0.8125em;
}

/* User Settings */
.user-btn button {
  background-color: #f8f9fa;
}

.user-btn button:hover {
  background-color: #f8f9fa !important;
  color: var(--dark-color);
}

.user-btn .dropdown-toggle::after {
  content: none;
}

.user-btn .fa-ellipsis-h:before,
.fa-ellipsis:before {
  color: var(--dark-color);
}

.user-btn a {
  color: var(--dark-color);
}

.user-btn a:hover {
  color: var(--theme-color);
}

.user-btn .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: #f8f9fa;
  color: var(--dark-color);
}
.search-btn {
  color: var(--dark-color) !important;
  border-top-left-radius: 1px;
  border-top-left-radius: 1px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 200px;
  margin-left: -20px;
  font-weight: 500;
  text-align: center;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
}
#cityModalBtn {
  width: 100%;
}
.search-btn:hover {
  box-shadow: 3px 3px 6px -1px #a2a2a240;
  border: solid 1px var(--theme-color) !important;
}
.shine {
  position: absolute;
  top: 0px;
  left: 160px;
  height: 40px;
  width: 20px;
  background: rgba(167, 253, 254, 0.4);
  transition: all 0.3s linear;
  animation: glowing 1800ms infinite;
}
@keyframes glowing {
  0% {
    transform: skewX(20deg) translateX(30px);
  }
  50% {
    transform: skewX(20deg) translateX(80px);
  }
  100% {
    transform: skewX(20deg) translateX(135px);
  }
}

.location-btn {
  background-color: #2bc7c9;
  color: #fff;
  border-top-right-radius: 1px;
  border-top-right-radius: 1px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.user-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 0px !important;
  object-fit: cover;
}

.moon-img {
  float: right;
  height: 22px;
}

.user-setting a {
  color: var(--dark-color);
}

.user-setting a:hover {
  color: var(--theme-color);
}

/* KYC Settings */
.downloadfile-wrap {
  background-color: #f9fdfd;
  border: dashed 1px #dddddd;
  padding: 20px;
  text-align: center;
}

.dlImageContainer {
  padding: 5px;
  border: dashed 1px #dddddd;
}

.downloadfile-wrap h6 {
  color: var(--theme-color);
  font-weight: normal;
}

.downloadfile-wrap p {
  margin: 0;
}

.downloadfile-wrap p {
  margin: 0;
  color: var(--dark-color);
  font-size: 0.8375em;
  font-weight: 500;
}

p.support-file {
  color: #707070 !important;
  font-size: 0.8375em;
}

p.file-drag {
  font-weight: 600;
  margin: 8px 0;
}

.front-cam,
.back-cam {
  text-align: center;
  margin: 20px;
}

.front-cam img,
.back-cam img {
  width: 203px;
  height: 113px;
}

.front-cam button,
.back-cam button {
  background-color: transparent;
  border: none;
  display: block;
}

.front-cam button img,
.back-cam button img {
  width: 48px;
  height: 48px;
}

.front-cam i,
.back-cam i {
  color: var(--light-theme);
  background-color: #2bc7c9;
  padding: 13px;
  border-radius: 55px;
  line-height: 0.9em;
}

.down-processwrap.progress {
  margin-top: -3px;
  background: transparent;
  height: 3px;
}

.down-processwrap .progress-bar {
  background: transparent linear-gradient(90deg, #1c8688 0%, #a9e5e5 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  width: 50%;
}

.rent-tab-wrapper {
  position: relative;
}

.rent-tab-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.rent-days-wrap {
  position: absolute;
  right: 0;
  top: 5px;
}

.vehicle-wrapper {
  display: flex;
  grid-column-gap: 30px;
  justify-content: start;
}

.rent-tab-wrapper .vehicle-wrapper {
  display: flex;
  grid-column-gap: 30px;
  justify-content: center;
}
@media screen and (max-width: 990px) {
  .vehicle-wrapper {
    justify-content: start;
  }

  .rent-tab-wrapper .vehicle-wrapper {
    justify-content: start;
  }
}

ul#pills-tab {
  border-bottom: solid 1px #d6d6d6;
  display: flex;
  justify-content: space-between;
  width: 48%;
}

.mitc-check:checked {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.linear-wipe {
  background: linear-gradient(to right, #00fff9 20%, #adff00 40%, #00ff83 80%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: left 1s;
  animation: shine 1s linear infinite;
  font-size: 1em;
  font-weight: 800;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@media screen and (max-width: 991px) {
  .Renting-wrapper .card {
    min-width: 300px;
    margin: 8px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 9px 0px #7090b036;
  }

  .renting-wrap .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .renting-wrap .detect-btn {
    background: #d5e9e9;
  }
}

@media screen and (max-width: 768px) {
  ul#pills-tab {
    width: 60%;
  }

  .rent-tab-wrap {
    justify-content: center;
  }

  /* .vehicle-wrapper {
    justify-content: flex-start;
  } */

  .booking-tab-rides {
    margin-top: 20px;
  }

  .testimonial-img-container {
    height: 210px;
  }
}

@media screen and (max-width: 576px) {
  ul#pills-tab {
    width: 100%;
  }

  .rent-tab-wrap {
    margin-bottom: 20px;
  }

  .rent-days-wrap {
    position: initial;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .front-cam img,
  .back-cam img {
    width: 138px;
    height: 78px;
  }

  .front-cam button img,
  .back-cam button img {
    width: 38px;
    height: 38px;
  }

  .camera-wrap {
    flex-wrap: wrap;
  }

  .uoloaded,
  .after-upload-wrap {
    margin: 15px 0 0 0;
  }
}

.fileIcon > input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.no-vehicle-found {
  width: 100%;
  height: 232px;
  object-fit: contain;
}

/*Animations & Hover Effects*/
.fr-nav-link {
  background-image: linear-gradient(
    to right,
    #2bc7c9,
    #2bc7c9 50%,
    #0b132b 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.fr-nav-item .fr-nav-link:hover {
  background-position: 0;
}

.fr-nav-link::before {
  content: "";
  background: transparent;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.5s ease-in-out;
}

.fr-nav-link:hover::before {
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .fr-nav-link {
    background-image: linear-gradient(
      to right,
      #2bc7c9,
      #2bc7c9 50%,
      #fefefe 50%
    );
  }
}

input:focus {
  border: none;
  outline: 0;
  box-shadow: none;
}

.light-freedo-btn {
  padding: 12px 32px;
  background-color: #fafafa;
  font-size: 1.125em;
  border: none;
}

.rent-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.citylist-container div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-freedo-btn:hover {
  background-color: #f0fffc;
}

.freedo-outline-btn-small {
  border: solid 1px var(--theme-color);
  padding: 6px 15px;
  font-size: 0.9em !important;
  background-color: transparent;
  transition: all ease-in 0.3s;
}

.freedo-primary-button-small {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  padding: 6px 22px;
  font-size: 0.87em;
  border: none;
}

.freedo-primary-button {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  padding: 9px 28px;
  font-size: 1em;
  border: none;
  color: var(--dark-color);
  font-weight: 500;
  cursor: pointer;
}

.freedo-primary-button-lg {
  padding: 12px 65px;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  font-size: 1em;
  border: none;
}

.freedo-outline-btn {
  border: solid 1px var(--theme-color);
  background-color: #fff;
  color: var(--theme-color);
  font-size: 1em;
  padding: 9px 20px;
  transition: 0.3s;
}

.freedo-outline-btn-small:hover {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  color: var(--dark-color);
  border: none;
  border: solid 1px var(--theme-color);
  box-shadow: 3px 3px 26px -1px #a2a2a240;
}

@media screen and (min-width: 700px) {
  .freedo-outline-btn:hover {
    background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0%
      0% no-repeat padding-box;
    color: var(--dark-color);
    border: none;
    box-shadow: 3px 3px 6px -1px #a2a2a240;
  }
}

.freedo-primary-button:hover,
.freedo-primary-button-lg:hover,
.freedo-primary-button-small:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all ease 0.3s;
}

.freedo-btn-link {
  color: var(--theme-color);
}

.freedo-btn-link:hover {
  text-decoration: underline;
  color: var(--theme-color);
}

body {
  font-family: "Euclid Circular B";
}

a {
  text-decoration: none;
}

.resend-otp a {
  color: var(--theme-color);
  font-weight: 600;
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0px 4px;
}

.testimonial-logo {
  height: 20px;
  margin-top: -5px;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
  border: solid 1px #e6eef2;
}

.form-control::placeholder {
  color: #cdcdcd;
}

.container-fluid {
  padding: 0 30px;
}

.main-content-wrapper {
  margin: 0 30px;
}

header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
}

.navbar-expand-lg {
  box-shadow: 2px 2px 8px 3px #7090b02e;
}

.navbar-brand img {
  height: 100%;
  width: 100px;
  padding: 0px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--theme-color);
  font-weight: 600;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 6px 20px;
}

.nav-link {
  color: var(--dark-color);
}

.login-wrap a {
  color: var(--dark-color);
}

.text-reset {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 10px;
  height: 8px;
}

.slider-wrapper {
  background: url(../assets/images/slider-bg.png);
  background-size: cover;
  width: 100%;
  padding: 20px 0px;
  background-attachment: fixed;
  background-position: 55% center;
  background-repeat: no-repeat;
}

.slider-img-wrap {
  width: 250px;
  padding: 0 5px;
}

.slider-content a {
  margin-top: 23px;
  color: black;
  font-size: 14px;
  display: inline-block;
}

.slider-content a i {
  margin-left: 10px;
}

.slider-right-wrap {
  position: relative;
}

.slider-right-wrap .top-content {
  position: absolute;
  top: 25px;
  right: -52px;
  text-align: center;
  background: #fff;
  padding: 5px 0 5px 22px;
  display: inline-block;
  box-shadow: 2px 2px 5px 0px #7090b036;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.99em;
  font-weight: 500;
}

.slider-right-wrap .bottom-content {
  position: absolute;
  bottom: 25px;
  left: -7px;
  text-align: center;
  background: #fff;
  padding: 5px 0 5px 22px;
  display: inline-block;
  box-shadow: 2px 2px 5px 0px #7090b036;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.99em;
  font-weight: 500;
}

.slider-right-wrap .top-content .fa-solid.fa-circle-check,
.slider-right-wrap .bottom-content .fa-solid.fa-circle-check {
  font-size: 2.5em;
  margin: 0 4px;
  color: var(--dark-color);
}

.slider-content {
  align-items: center;
  letter-spacing: 1px;
}

.slider-content h5 {
  margin-bottom: 10px;
  font-weight: 600;
}

.slider-content h1 {
  font-size: 3.45em;
  font-weight: 600;
  margin-bottom: 10px;
}

.slider-content p {
  font-size: 16px;
  font-weight: 400;
}

.slider-img-wrap img {
  /* height: 162px; */
  margin: 0 0 10px 0;
  object-fit: cover;
  object-position: center 28%;
  width: 100%;
  padding: 0;
}

.slider-img-wrap img {
  margin: 5px 10px;
  /* height: 162px; */
  width: 100%;
  object-fit: cover;
  object-position: center 28%;
}

.slider-img-wrap img.slide-2 {
  height: 254px;
}

/* .slider-img-wrap img.slide-3 {
  height: 265px; 
} */

/* .slider-img-wrap img.new-slide-2 {
  height: unset;
} */

@media screen and (max-width: 1220px) {
.slider-img-wrap img.new-slide-2 {
    height: 265px;
  }
}


.location-wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 40px 0 0 0;
  padding: 0;
  position: relative;
}

/* .location-wrap button {
  position: absolute;
  z-index: 1;
  left: 0;
} */

.location-wrap .detect-btn {
  background: #d5e9e9;
  font-size: 14px;
}

.location-wrap .detect-btn:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all ease 0.3s;
}

.location-wrap input {
  position: absolute;
  z-index: 0;
  border: none;
  box-shadow: 2px 2px 14px 2px #7090b01c;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 85%;
  color: #747474;
  text-align: right;
  padding-right: 14%;
}

.location-wrap button img {
  width: 22px;
  height: 14px;
  object-fit: contain;
  margin: -3px 10px 0 0;
}

.slider-img-wrap video {
  height: 265px;
  margin: 0;
  object-fit: cover;
  width: 100%;
}

.slider-img-wrap video {
  margin: 5px 10px;
  object-fit: cover;
  height: 265px;
}

.carousel-indicators {
  bottom: -12px;
}

.carousel-indicators [data-bs-target] {
  background-color: #dfdfdf;
}

/* fixed social*/
.fixed-social-tray {
  animation: fadeInAnimation ease 2s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#fixed-social {
  position: fixed;
  top: 45%;
  right: 0;
  z-index: 99;
  transform: translateY(-33%);
}

#fixed-social a {
  color: #fff;
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  line-height: 40px;
  width: 40px;
  margin-bottom: 1px;
  z-index: 2;
  font-weight: 500;
  border-radius: 4px;
}

#fixed-social a:hover > span {
  visibility: visible;
  left: -121px;
  opacity: 4;
}

#fixed-social a span {
  line-height: 40px;
  left: 60px;
  position: absolute;
  text-align: center;
  width: 120px;
  visibility: hidden;
  transition-duration: 0.5s;
  z-index: 1;
  opacity: 0;
  border-radius: 4px;
}

@media (max-width: 900px) {
  #fixed-social a:hover > span {
    visibility: hidden;
    left: 0px;
    opacity: 0;
  }

  #fixed-social a span {
    left: 0px;
    visibility: hidden;
    opacity: 0;
  }
}

.fixed-facebook {
  background-color: #4267b2;
}

.fixed-facebook span {
  background-color: #4267b2;
}

.fixed-linkedin {
  background-color: #0077b5;
}

.fixed-linkedin span {
  background-color: #0077b5;
}

.fixed-instagram {
  --white: #fff;
  --blue: #3051f1;
  --purple: #c92bb7;
  --red: #f73344;
  --orange: #fa8e37;
  --yellow: #fcdf8f;
  --yellow_to: #fbd377;
}

.fixed-instagram {
  background: radial-gradient(
      circle farthest-corner at 28% 100%,
      var(--yellow) 0%,
      var(--yellow_to) 10%,
      var(--orange) 22%,
      var(--red) 35%,
      transparent 65%
    ),
    linear-gradient(145deg, var(--blue) 10%, var(--purple) 70%);
}

.fixed-instagram span {
  background: radial-gradient(
      circle farthest-corner at 28% 100%,
      var(--yellow) 0%,
      var(--yellow_to) 10%,
      var(--orange) 22%,
      var(--red) 35%,
      transparent 65%
    ),
    linear-gradient(145deg, var(--blue) 10%, var(--purple) 70%);
}
.fixed-whatsapp {
  background-color: #25d366;
}

.fixed-whatsapp span {
  background-color: #25d366;
}
/*end fixed social*/

.features-wrap {
  margin-top: var(--main-spacing-top);
}

.features-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
}

.locate-me-model-wrap .modal-content {
  background: #fff;
}

.locate-me-model-wrap .location-wrap .btn-close {
  position: absolute;
  z-index: 1;
  right: 25px !important;
  top: 25px;
}

.renting-wrap .btn-close {
  position: absolute;
  z-index: 1;
  right: 25px !important;
  top: 12px;
}

.locate-me-model-wr.renting-wrap .btn-close {
  position: absolute;
  z-index: 1;
  right: 25px !important;
  top: 25px;
}

.locate-me-model-wrap .modal-header {
  justify-content: start;
}

.locate-me-model-wrap .modal-title {
  padding-left: 45px;
}

.locate-me-model-wrap .modal-body {
  margin: 0;
  padding: 10px 30px 23px 30px;
}

.locate-me-model-wrap .form-control.btn-journey:focus {
  box-shadow: 2px 2px 14px 2px #7090b01c !important;
  border: none !important;
}

.coming-soon-city .modal-body {
  padding: 0 !important;
}

.coming-soon-city img {
  padding: 0 !important;
  object-fit: cover;
  border-radius: 8px;
  /* height: 100%; */
  object-position: right;
}

.detect-btn {
  position: relative !important;
  margin: 0 0 20px 0;
  background: #d5e9e9;
  padding: 10px 30px;
  font-size: 14px;
  margin-top: 10px;
  width: 40%;
}

.city-model-wrap {
  position: relative;
  display: inline-block;
  text-align: center;
}

.city-model-wrap img:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all ease 0.3s;
}

.city-model-wrap p {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 94%;
  margin: 0 4px;
  text-align: center;
  border-radius: 0 0 17px 17px;
  padding: 5px 0;
}

@media (max-width: 800px) {
  .journey-box .city-model-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 155px;
  }

  .journey-box .city-model-wrap p {
    width: 66%;
    margin-left: 20px;
    bottom: -1px;
  }

  .journey-box .city {
    height: unset;
    width: 200px;
  }
}

#journyModal .modal-footer .btn:disabled {
  cursor: no-drop;
}

.city-model-wrap p:hover {
  background-color: #2bc6c99d;
}

.mid-wrap p {
  margin: -6px 4px !important;
}

.features-content .card {
  padding: 10px 20px;
  box-shadow: 2px 2px 14px -1px #7090b036;
  border: none;
  flex-direction: revert;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
}

.features-content .card .card-left img {
  height: 40px;
  object-fit: contain;
  padding-right: 10px;
}

.features-content .card h6.card-right {
  margin-bottom: 0;
  font-size: 0.9rem;
}

.testimonial-img-container {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 0;
  display: -ms-flexbox;
  overflow: hidden;
  position: relative;
  padding-bottom: 0px;
  width: 100%;
  height: 280px;
}

/* Blog Page */
.blog-wrapper {
  margin-top: var(--main-spacing-top);
}

.blog-wrap h2 {
  text-align: center;
  margin: 30px 0;
}
.search-blog .input-group {
  box-shadow: 0px 8px 17px #dddddd4a;
  border-radius: 8px;
}

.search-options {
  position: relative;
  height: 100%;
}

.search-options ul {
  list-style-type: none;
  background: #fff;
  display: flex;
  box-shadow: 2px 3px 8px #ddd;
  padding: 20px 10px;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
}

.search-options ul li {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  padding: 5px 10px;
  margin: 5px 3px;
  border-radius: 5px;
  font-size: 13px;
}

.search-options ul li a {
  color: #fff;
}

.badge {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}

.blog-content {
  background: #fff;
  margin-top: -50px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 17px 22px #74747414;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.bg-freedo-theme {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  padding: 8px 10px;
  font-size: 0.79em;
}

.popular-blog-img {
  width: 16%;
  margin-right: 10px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}

.counter-wrapper {
  margin-top: var(--main-spacing-top);
}

.counter-row {
  text-align: center;
}

.counter-row .counter-number {
  font-size: 2.5em;
  color: #2bc7c9;
  text-align: center;
}

.faq-wrapper {
  background: url(../assets/images/faq-bg.png);
  padding: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.faq-wrap {
  background-color: rgba(255, 255, 255, 0.6);
  margin: 30px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px -3px #d7d3d4;
}

.faq-wrap .accordion-item {
  border: none;
  margin: 20px 20px 30px 20px;
  box-shadow: 0px 5px 20px -2px #7090b038;
  border-radius: 10px;
}

.faq-showmore {
  margin: 45px 0 0 0;
}

.faq-wrap .accordion-button.collapsed {
  border: none;
  background-color: var(--light-theme);
  border-radius: 10px;
  padding: 15px 25px;
  color: var(--dark-color);
  box-shadow: 0px 5px 10px -2px #7090b03d;
}

.faq-wrap .accordion-button:not(.collapsed) {
  background-color: var(--light-theme);
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
  box-shadow: none;
  color: var(--dark-color);
}

.faq-wrap .accordion-body {
  background: var(--light-theme);
  border-radius: 0 0 10px 10px;
  /* white-space: pre-line; */
  font-size: 14px;
  text-align: justify;
  padding: 1.5rem;
}

.faq-wrap .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  background: none;
  content: "-";
  background-color: var(--theme-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 22px;
  transform: none;
}

.faq-wrap .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  padding: 6px 0px 10px;
  background: none;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  background-color: var(--theme-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 22px;
}

footer {
  background: url(../assets/images/footer-bg.png);
  padding: 50px 0 0 0;
  background-size: cover;
  text-align: center;
  color: var(--white);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

footer img {
  height: 50px;
  margin-top: -20px;
}

.footer-content {
  text-align: left;
  margin-top: 16px;
}

footer h4 {
  color: var(--white);
  margin: 20px 0;
  text-decoration: underline;
  text-underline-offset: 5px;
}

footer p {
  color: var(--white);
  padding: 0px 120px;
  margin-bottom: 50px;
}

footer p a {
  color: var(--white);
  text-decoration: underline;
}

ul.footer-list {
  padding-left: 0;
}

ul.footer-list li {
  list-style-type: none;
  line-height: 30px;
}

ul.footer-list li a {
  color: var(--white);
}

ul.footer-list li a:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.footer-content h4 {
  text-decoration: none;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: inline;
}

.address-list li {
  margin-bottom: 10px;
  font-size: 14px;
}

.download-app-section {
  text-align: left;
  text-decoration: none;
}

.download-app-section h4 {
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 0;
}

.download-app-btn-wrap {
  margin-top: -12px;
}

.download-app-btn-wrap img {
  width: 160px;
  margin-right: 10px;
  margin-top: 5px;
}

footer hr {
  border-color: #7090b0;
  margin: 30px 20px;
  border-width: 2px;
}

ul.loacton-list {
  display: grid;
  padding: 0;
  list-style-type: none;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

ul.loacton-list li {
  margin-bottom: 20px;
  cursor: pointer;
}

ul.loacton-list li a {
  color: var(--white);
  padding: 10px;
  font-size: 0.8em;
}

ul.loacton-list li a:hover {
  text-decoration: underline;
}

ul.loacton-list li a i {
  color: var(--theme-color);
  padding: 5px;
}

.copyright-wrap {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  padding: 10px 0;
  text-align: center;
  color: var(--dark-color);
}

/*-----------Signin Page----------------------*/

.offcanvassigninWrapper {
  background: linear-gradient(
      180deg,
      rgba(242, 242, 242, 0.454) 41%,
      rgba(43, 198, 201, 0.331) 68%
    ),
    url("../assets/images/signin-bg.jpg") no-repeat center;
  background-size: cover;
  max-width: 100%;
  overflow-y: scroll;
}

.offcanvassigninWrapper .offcanvas-body {
  padding: 90px 40px;
}

.offcanvassigninWrapper .offcanvas-body h3 {
  text-align: center;
  position: relative;
  margin-bottom: 25px;
  /* margin-top: 48px; */
}

.offcanvassigninWrapper .offcanvas-body h3:after {
  width: 35px;
  height: 3px;
  background-color: var(--theme-color);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -15px;
}

.btn-close {
  z-index: 9;
}

.offcanvassigninWrapper .form-control {
  background: #f4f7f8;
  color: #242b35;
  border: solid 1px #e6eef2;
}

.offcanvassigninWrapper label {
  font-size: 0.8em;
}

.input-group-text {
  background: #f4f7f8;
  border-color: #e6eef2;
}

.input-group-text {
  background: #f4f7f8;
  border-color: #e6eef2;
}

.sign-up p {
  font-size: 14px;
  margin-top: 18px;
}

.sign-up p span {
  font-size: 14px;
  font-weight: 600;
}

/* About us page */
.mission-wrapper {
  margin: 50px 0 0 0;
}

.story-wrap,
.mission-wrap,
.vision-wrap {
  background-color: #f9f9f9;
  margin: 50px 0;
}

.story-wrap img,
.mission-wrap img,
.vision-wrap img {
  border-radius: 15px;
  padding: 0px !important;
}

.mission-wrapper .content-wrap {
  padding: 0 60px;
}

.mission-wrapper .content-wrap p {
  margin: 10px 0 40px 0;
}

.mission-wrapper .content-wrap h6 {
  margin-bottom: 60px;
}

.mission-wrapper .content-wrap a {
  color: var(--dark-color);
}

.mission-wrapper .content-wrap a i {
  padding-left: 4px;
}

.innerpage-main-head {
  align-items: center;
  letter-spacing: 1px;
}

.innerpage-main-head h1 {
  font-size: 4.0625em;
  font-weight: 600;
  margin-bottom: 10px;
}

.innerpage-main-head p {
  font-size: 16px;
  margin-top: 20px;
}

.inner-bottom-text {
  margin-top: 40px;
}

.innerpage-wrap {
  width: 185px;
}

.innerpage-slider-right-wrap {
  position: relative;
}

.innerpage-slider-right-wrap .slider-img-wrap {
  width: 250px;
  padding: 0 5px;
}

.innerpage-slider-right-wrap .slider-img-wrap img.slide-3 {
  height: 265px;
}

.innerpage-slider-right-wrap .slider-img-wrap img.slide-2 {
  height: 265px;
}

.innerpage-slider-right-wrap .slider-img-wrap img {
  margin: 10px 0 0 0;
  height: 190px;
  width: 100%;
  object-fit: cover;
}

span.innerpage-img-head {
  position: relative;
  display: block;
}

.innerpage-img-head h5 {
  position: absolute;
  bottom: 5px;
  left: 10px;
  background-color: #0b132b8c;
  color: #fff;
  text-align: center;
  right: -11px;
  margin: 0;
  padding: 10px;
  transition: 1s ease;
}

.innerpage-img-head h5 a {
  color: white;
  font-size: 16px;
  transition: font-size 0.5s;
}

.innerpage-img-head h5 a:hover {
  font-size: 18px;
  transform: scale(1.002);
}

.innerpage--wrapper {
  background: url(../assets/images/slider-bg.png);
  background-size: cover;
  width: 100%;
  padding: 30px 0;
}

/* Faq-page */
.faq-inner-wrapper {
  background: url(../assets/images/faq-inner-bg.png);
  background-size: cover;
  width: 100%;
  padding: 30px 0;
}

.main-faq-wrapper {
  margin: 50px 0 0 0;
}

.faq-inner-wrapper h3 {
  padding-left: 30px;
}

.faq-inner-wrapper .faq-wrap .accordion-button:not(.collapsed) {
  background-color: var(--light-theme);
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
  box-shadow: none;
  color: #2bc7c9;
  border-bottom: solid 1px #ececec;
}

.faq-banner-wrap {
  background: url(../assets/images/banner-default.png);
  background-size: cover;
  width: 100%;
  padding: 30px 0;
  height: 125px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.main-faq-wrapper form {
  margin: 0 auto;
  margin-top: -20px;
}

.main-faq-wrapper form .input-group-text {
  background: #fff;
  border-color: #e6eef2;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0.775rem;
  padding: 0 18px;
}

.main-faq-wrapper form .input-group {
  box-shadow: 2px 4px 20px 4px #d2f5f7;
  background: #fff;
  border-radius: 25px;
}

.main-faq-wrapper form .form-control {
  padding: 0.775rem 0.75rem;
  border: none;
  /* margin-left: 10px !important; */
  border-radius: 0.775rem;
}

/* contact-us-page */
.content-wrap h2 {
  position: relative;
}

.content-wrap h2::after {
  content: "";
  width: 44px;
  height: 4px;
  background-color: var(--theme-color);
  position: absolute;
  left: 0;
  bottom: -5px;
}

.contact-content {
  margin: 50px 0;
}

.office-content {
  margin: 40px 0 0 0;
}

.contact-content h2 {
  position: relative;
}

.contact-content h2::after {
  content: "";
  width: 45px;
  height: 2px;
  background-color: var(--theme-color);
  position: absolute;
  left: 0;
  bottom: -5px;
}

.contact-content .form-control::placeholder {
  font-size: 14px;
}

.contact-content .left label.form-label {
  font-size: 0.938;
}

.support-wrap {
  margin: 40px 0 0 0;
}

.support-wrap a {
  color: var(--dark-color);
  text-decoration: underline;
}

/* CheckoutPage */
.top-band {
  background-color: var(--dark-color);
  padding: 4px 0;
}

.top-slider .slick-slide.slick-active:hover {
  border: none;
  box-shadow: none;
}

.steps-inner-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.steps-body {
  display: flex;
  align-items: center;
  color: var(--list-color);
}

.steps-body.visited h6 {
  color: var(--theme-color);
  position: relative;
  background: var(--light-theme);
}

.steps-body.visited .number {
  background-color: var(--theme-color);
  color: var(--white);
}

.steps-body.visited .steps-border {
  border-bottom: solid 1px var(--theme-color);
}

.steps-body.active .number h6 {
  color: var(--dark-color);
  position: relative;
  background: var(--light-theme);
}

.steps-body.active .number {
  background-color: var(--dark-color);
  color: var(--white);
}

.steps-border {
  border-bottom: dashed 1px var(--dark-color);
  width: 135px;
  margin: 4px 0 0 10px;
}

.steps-body {
  display: flex;
  align-items: center;
  color: var(--list-color);
  background: var(--light-theme);
  padding: 10px;
  white-space: nowrap;
}

.steps-body.next h6 {
  color: #707070;
}

.steps-body .visited h6 {
  position: relative;
  margin: 0;
  color: var(--theme-color);
}

.steps-body .number {
  width: 20px;
  height: 20px;
  background-color: var(--list-color);
  color: var(--light-theme-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 10px;
}

.Info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.message {
  margin-right: 7px;
}

.timer-wrap span b {
  font-weight: 600;
}

.timer-wrap span {
  padding: 0 0px 0 6px;
}

.message {
  text-align: center;
  font-weight: 800;
}

.contact-wrap {
  position: absolute;
  right: 3%;
  top: 6px;
  background-color: var(--dark-color);
  z-index: 11;
}

.contact-wrap a i {
  color: var(--theme-color);
  padding: 0 5px 0 15px;
}

.contact-wrap a {
  color: var(--white);
}

.checkoutview-wrapper {
  z-index: 9999;
  position: relative;
}

.checkout-wrapper {
  padding: 20px 0;
}

.checkout-wrapper h2 {
  position: relative;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 15px;
}

.mode-of-delivery {
  margin-right: 5px;
}

.checkout-wrapper h2::after {
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 30%;
  height: 2px;
  content: "";
  background-color: var(--theme-color);
}

.coupon-details-wrapper {
  background-color: var(--light-theme);
  padding: 10px 15px;
  margin-bottom: 31px;
  box-shadow: 11px 11px 24px -5px #70707038;
  border-radius: 5px;
}

.coupon-details-wrapper h6 {
  font-weight: 600;
}

.coupon-details-wrapper .pay-now-term-and-condition {
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

.apply-head {
  font-weight: 500;
}

.apply-head img {
  margin-right: 6px;
  width: 22px;
}

.apply-coupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apply-coupon a {
  color: var(--theme-color);
}

.apply-sec button {
  font-size: 14px;
}

.checkout-coupon span + button {
  background-color: #f5feff;
  padding: 3px 8px;
  border: 1px solid #2bc7c9;
  border-radius: 5px;
}

/* .apply-coupon a:hover {
    background-color: var(--theme-color);
    color: white;
    transition: all ease .3s;
} */

.helmet-wrap {
  display: block;
  margin-top: 10px;
}

.helmet-wrap p {
  font-size: 12px;
  color: var(--list-color);
  margin-bottom: 10px;
}

.helmate-wrap label {
  white-space: nowrap;
  margin: 3px 0px 0 0;
  font-size: 16px;
}

.helmate-wrap #rentAdditionalHelmet {
  margin-top: 8px;
}

.form-check-input {
  background-color: #f4f7f8;
  width: 16px;
  height: 16px;
  border: none;
  border: none;
  border: solid 1px #e6eef2;
  margin-right: 6px;
}

.form-check-input:checked {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.form-check-input:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.value-button {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  padding: 11px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

form #increase {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.helmate-wrap form p {
  padding: 3px 0;
  font-size: 11px;
  margin: 0;
}

.table {
  margin: 0;
}

.billing-wrap .table-responsive .table td:nth-child(2) {
  font-weight: 500;
  text-align: right;
}

.billing-wrap .table-responsive .table tr.table-group-divider {
  border-top: solid 1px rgb(43, 42, 42);
  padding: 10px;
}

.total-amount {
  color: var(--theme-color);
}

.amount-head {
  font-weight: 500;
}

.change-address-wrapper .top-header-canvas {
  background-color: var(--theme-color);
  padding: 20px 20px 20px 20px;
  border-radius: 0 0 20px 20px;
  color: var(--light-theme) !important;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.change-address-wrapper .top-header-canvas .btn-back {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -4px;
  left: 15px;
  background: none;
  font-size: 20px;
  border: none;
}

.change-address-wrapper .top-header-canvas .btn-close {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: 16px;
  right: 15px;
  background: none;
  font-size: 23px;
}

.change-address-wrapper .offcanvas.offcanvas-end {
  overflow-y: scroll;
}

.change-address-wrapper h6 {
  margin-bottom: 0;
  padding-left: 10px;
}

.change-address-wrapper p {
  padding-left: 25px;
  margin: 0;
  font-size: 0.8em;
  letter-spacing: 0.11em;
}

.change-address-wrapper button.btn-home {
  background: var(--light-theme);
  border-radius: 29px;
  padding: 3px 10px;
  display: inline-block;
  margin: 8px 0 0 22px !important;
  cursor: auto;
}

.change-address-wrapper .use-address-icon {
  color: var(--theme-color);
  font-size: 20px;
}

.change-address-wrapper-inner {
  border-bottom: solid 1px #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.use-address {
  position: relative;
}

.use-address button {
  position: absolute;
  left: 39%;
}

.add-address-wrapper .top-header-canvas {
  background-color: var(--theme-color);
  padding: 50px 20px 20px 20px;
  border-radius: 0 0 20px 20px;
  color: white;
  position: relative;
  z-index: 9;
}

.add-address-wrapper .top-header-canvas .btn-close {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 15px;
  background: none;
  font-size: 23px;
}

.add-address-wrapper .offcanvas.offcanvas-end {
  width: 550px;
}

.add-address-wrapper h6 {
  margin-bottom: 0;
  padding-left: 10px;
}

.add-address-wrapper p {
  padding-left: 22px;
  margin: 0;
}

.add-address-wrapper button.btn-home {
  background: #d9d9d9;
  border-radius: 29px;
  padding: 3px 10px;
  display: inline-block;
  margin: 8px 0 0 22px !important;
}

.add-address-wrapper .use-address-icon {
  color: var(--theme-color);
  font-size: 20px;
}

.add-address-wrapper-inner {
  border-bottom: solid 1px #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.add-address-wrapper .offcanvas-body.text-start {
  margin-top: -24px;
  position: relative;
  z-index: 1;
}

/*left side */
.mode-delivery .form-check {
  color: #292825;
  align-items: center;
  float: right;
  margin-right: 16px;
  margin-bottom: 0;
}

.mode-delivery .form-check-input {
  margin-right: 5px;
}

.mode-delivery {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 8px;
  align-items: center;
  display: flex;
  padding: 6px 0px;
  white-space: nowrap;
}

.product-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px 0;
  align-items: center;
}

.product-details img.prod-image {
  margin-right: 4px;
}

.product-details img.class-edit {
  float: right;
}

.product-details img.bike-right {
  float: right;
  max-width: 65%;
}

.product-details span.product-detail {
  color: #021325;
  font-weight: 600;
}

.product-details span.hero {
  color: #ff3300;
  font-weight: 700;
  margin-top: 20px;
  display: block;
}

.product-details span.head {
  color: #0b132b;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.rent-det,
.add-det {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 5px;
}

.select-hub-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.select-hub-box p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
  color: rgba(33, 37, 41, 0.75);
}

.product-details a.rent-det {
  color: #0b132b;
  text-decoration: underline;
  font-weight: 500;
}

.product-details a.rent-det:hover {
  color: var(--theme-color);
}

.rented-helmet img {
  width: 50px;
  margin: auto;
}

.selectedHelmet {
  border: 1px solid var(--theme-color);
}

.selectedHelmet::after {
  align-items: center;
  background: var(--theme-color);
  border: 2px solid #fff;
  border-radius: 100%;
  color: #fff;
  content: "\f00c";
  display: flex;
  font: normal normal normal 16px/1 FontAwesome;
  font-size: 11px;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -9px;
  text-align: center;
  top: -9px;
  width: 24px;
}

/* ----- List-view css ------ */
.map-details {
  position: relative;
  padding-top: 1rem;
}

#list-view {
  display: none;
}

.list-view-wrap {
  position: relative;
  left: 0;
  display: flex;
  background: #242b35;
  color: #fff;
  border: none;
  width: 20% !important;
  bottom: 10%;
  justify-content: center;
  margin: 0 auto;
  right: 0;
  border-radius: 18px;
  padding: 5px 22px !important;
  z-index: 1;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.list-view-wrap img {
  margin-right: 1px;
}

.list-view-wrap:hover {
  -webkit-box-shadow: 0px 0px 19px 5px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 0px 19px 5px rgba(214, 214, 214, 1);
  box-shadow: 0px 0px 19px 5px rgba(214, 214, 214, 1);
}

.slick-prev {
  left: -15px;
  position: absolute;
  top: 40%;
}

.slick-next {
  right: 0;
  position: absolute;
  top: 40%;
}

/*--------- booking vehicle-model Css End------------- */
.renting-wrap .coming-new-city-content {
  background-color: transparent !important;
}

.renting-wrap .coming-soon-city .card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px 10px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%); */
  background-color: rgb(255 255 255 / 77%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  font-size: 0.9em;
  width: 35%;
}

.coming-new-city-content {
  background-color: transparent !important;
}

.coming-soon-city .modal-body {
  background-color: transparent;
}

.coming-soon-city .card-container {
  position: relative;
  z-index: 99;
}

.coming-soon-city p {
  font-size: 0.9em;
  color: var(--dark-color);
  font-weight: 500;
}

.background-overlay {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  background-attachment: fixed;
  filter: brightness(0.87);
  border-radius: 8px;
}

.coming-soon-city .card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px 10px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%); */
  background-color: rgb(255 255 255 / 77%);
  border-radius: 12px;
  border: none;
  filter: brightness();
  font-size: 0.9em;
  width: 35%;
}

.location-wrap .coming-soon-city input {
  border-radius: 50px;
  backdrop-filter: blur(22px) saturate(180%);
  -webkit-backdrop-filter: blur(22px) saturate(180%);
  background-color: rgba(5, 7, 11, 0.75);
}

.renting-wrap .coming-soon-city input {
  border-radius: 50px;
  backdrop-filter: blur(22px) saturate(180%);
  -webkit-backdrop-filter: blur(22px) saturate(180%);
  background-color: rgba(5, 7, 11, 0.75);
}

.location-wrap .coming-soon-city input::placeholder {
  color: #fff;
  font-weight: 400;
}

.renting-wrap .coming-soon-city input::placeholder {
  color: #fff;
  font-weight: 400;
}

.location-wrap h4.modal-title {
  font-weight: 500;
  position: relative;
}

.renting-wrap h4.modal-title {
  font-weight: 500;
  padding-left: 45px;
  position: relative;
}

.coming-soon-city input {
  position: initial;
  width: 100%;
  color: #fff;
  font-size: 0.99em;
}

.location-wrap .coming-soon-city input {
  color: #fff;
  padding-left: 5%;
  text-align: left;
}

.renting-wrap .coming-soon-city input {
  color: #fff;
}

.coming-soon-city .card h4 {
  color: var(--dark-color);
  font-weight: 600;
}

.locate-me-model-wrap .location-wrap .coming-soon-city .btn-close {
  position: absolute;
  left: auto;
  opacity: unset;
  color: #fff;
}

.freedo-city-SEO {
  text-align: left !important;
  font-size: 10px;
}

.freedo-city-SEO p {
  text-align: left !important;
  padding: 0px;
  margin-bottom: 10px;
}

.freedo-city-SEO ul {
  padding-left: 30px;
  margin-bottom: 10px;
}

.freedo-city-SEO-1 {
  padding-left: 15px;
  margin-top: 10px;
}

@media screen and (max-width: 575px) {
  .coming-soon-city .card {
    width: 100%;
    background-color: transparent;
  }

  .coming-soon-city .card h4 {
    color: #fff;
  }

  .coming-soon-city p {
    font-size: 0.78em !important;
    color: #fff;
  }

  .renting-wrap .coming-soon-city .card {
    width: 100%;
    background-color: transparent;
  }

  .renting-wrap .coming-soon-city .card h4 {
    color: #fff;
  }

  .renting-wrap .coming-soon-city p {
    font-size: 0.78em !important;
    color: #fff;
  }

  .location-wrap .coming-soon-city input {
    padding-left: 10px;
    font-size: 0.78em;
    color: #fff;
  }

  .renting-wrap .coming-soon-city input {
    padding-left: 10px;
    font-size: 0.78em;
    color: #fff;
  }

  .background-overlay {
    background-position: right;
  }
}

/* ----- List-view css End ------ */

.map-details iframe {
  width: 100%;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #dddddd !important;
  margin-top: 10px;
}

.delivery-details span.del-detail {
  color: #021325;
  font-weight: 600;
}

.delivery-details img.delv-image {
  margin-right: 4px;
}

.delivery-details {
  border-radius: 8px;
  border: 1px solid #dddddd !important;
  margin-top: 10px;
  padding: 10px 0;
}

.delivery-details button.Change-Address {
  border: 1px solid #2bc7c9;
  border-radius: 29px;
  font-size: var(--small-text);
  padding: 10px 16px;
  color: #2bc7c9;
  background: #fff;
  float: right;
}

.delivery-details button.Change-Address:hover {
  background-color: var(--theme-color);
  color: #fff;
  transition: all ease 0.3s;
}

.delivery-details span.head {
  display: block;
  color: #242b35;
  margin: 20px 0 3px 0;
  font-weight: 500;
}

.delivery-details span.text {
  display: block;
  font-size: 0.875rem;
}

.delivery-details .btn-home {
  background: var(--light-theme);
  border-radius: 29px;
  padding: 3px 10px;
  display: inline-block;
  margin: 8px 0 0 0;
  border: solid 1px var(--theme-color);
  color: var(--theme-color);
}

.delivery-details p.text {
  margin-bottom: 5px;
}

.time-slot-details {
  margin-top: 20px;
  margin-bottom: 20px;
}

.time-slot-details img.time-slot {
  margin-right: 4px;
  margin-top: -6px;
}

.time-slot-details span.time-slot {
  color: #021325;
  font-weight: 600;
}

.time-slot-details .time-slot {
  margin-top: 20px;
  padding-right: 0;
}

.time-slot-details .tab-content {
  padding: 0;
}

.time-slot-details .time-slot li.nav-item {
  width: 33.33%;
  text-align: center;
  cursor: pointer;
}

.form-check-input {
  cursor: pointer;
}

.time-slot-details .time-slot .nav-link {
  background-color: transparent;
  border-radius: 0;
  color: #000;
  padding: 5px;
}

ul.nav.nav-tabs.time-slot {
  background: #f0f2f2;
  border-radius: 5px;
}

.time-slot-details .time-slot .nav-link.active,
.time-slot-details .time-slot .nav-link:hover {
  background: #2bc7c9;
  color: #fff;
  border-bottom: solid 2px #07a1a2;
  text-decoration: none;
}

.time-slot-details .time-slot .nav-link.active .timeslot-icon svg,
.time-slot-details .time-slot .nav-link:hover .timeslot-icon svg {
  fill: #fff;
}

.timeslot-icon svg {
  color: #fff;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 6px;
}

.time-slot-details .timing-sets {
  border-radius: 5px;
  border: 1px solid #dddddd !important;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
  flex-wrap: wrap;
}

.time-slot-details .timing-sets {
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.time-slot-details .timing-sets::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.time-slot-details .timing-sets::-webkit-scrollbar-thumb {
  background: transparent;
}

.time-slot-details .timing-sets::-webkit-scrollbar-track {
  background: #eee;
}

.time-slot-details .timing-sets .form-check {
  margin-bottom: 0;
}

.time-slot-details .timing-sets label {
  line-height: 25px;
  margin-left: 0px;
  margin-right: 20px;
}

.coupon-details-wrapper .helmate-wrap nav {
  width: 24%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.value-button {
  width: 32% !important;
  height: 30px !important;
  border: 1px solid #ddd !important;
  justify-content: center;
  background: #fff !important;
  align-items: center;
  display: inline-flex !important;
}

.mode-delivery .form-check-input {
  width: 15px;
  height: 15px;
}

input#number {
  width: 32% !important;
  border: 1px solid #ddd !important;
  height: 30px !important;
  font-size: 12px;
}

span.mode-of-delivery {
  color: #021325;
  font-weight: 600;
  white-space: nowrap;
}

.main-content-wrapper-slider {
  margin-right: 50px;
}

.progressbar {
  margin-top: 10px;
}

.progressbar span {
  list-style: none;
  display: inline-block;
  width: auto;
  position: relative;
  text-align: center;
}

.progressbar span:before {
  content: "";
  width: 13px;
  height: 13px;
  line-height: 30px;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
  background-color: #2bc7c9;
}

.progressbar span.active::after {
  content: "";
  position: absolute;
  width: 46px;
  height: 1px;
  background-color: #bbb;
  top: 7px;
  left: 12px;
}

.ml-0 {
  margin-left: 0 !important;
}

.progressbar-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.progressbar-wrap .journey-modal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.progressbar {
  counter-reset: step;
  padding-left: 0;
  width: 70px;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
}

.progressbar span.active {
  color: #2bc7c9;
}

.progressbar span.active:before {
  border-color: #2bc7c9;
}

.date-progress {
  padding-left: 0;
  margin: 10px -37px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-progress span.next:before {
  background-color: #bbb;
}

.date-progress span.next {
  float: right;
}

.day-one {
  background: #2bc7c9;
  color: #fff !important;
  padding: 2px 6px;
  border-radius: 2px;
  white-space: nowrap;
}

.date-progress .location {
  color: #0b132b;
  float: left;
  margin: 0 8px 0 0;
}

.add-details-wrapper {
  background-color: var(--light-theme);

  padding: 10px 15px;
  margin-bottom: 31px;
  box-shadow: 11px 11px 24px -5px #70707038;
  border-radius: 5px;
}

.select-hub-wrapper {
  background-color: var(--light-theme);
  margin-top: 5px;
  padding: 10px 15px;
  box-shadow: 11px 11px 24px -5px #70707038;
  border-radius: 5px;
  height: 284px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.add-details-wrapper h6 {
  font-weight: 400;
  text-decoration: underline;
}

.add-new-address-wrap .map-details iframe {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: 1px solid #dddddd !important;
  margin-top: 0;
  padding: 0;
  height: 350px;
}

.gm-style .gm-style-iw-c {
  font-weight: 600;
  width: 120px;
  text-align: center;
  left: 0;
  /* -webkit-transform: translate3d(-50%, -100%, 0); */
  /* transform: translate3d(-50%, -100%, 0); */
  background-color: unset;
  border-radius: 8px;
  padding: 5px;
  /* -webkit-box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%); */
  box-shadow: none;
  cursor: pointer;
}

.gm-style .gm-style-iw-d {
  overflow: unset !important;
}

.mapWarehouseInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.15s;
}

.mapWarehouseInfoPricing {
  color: black;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 6px 13px;
  font-size: 14px;
  border-radius: 60px;
  border: 1px solid #ededed;
  background: linear-gradient(90deg, #fff 29.92%, #c6feff 100%), #fff;
}

.mapWarehouseInfoPricing.selected {
  background: #000;
  color: #ffffff;
}

.mapWarehouseName {
  background-color: var(--theme-color);
  display: flex;
  border-radius: 15px;
  padding: 3px 8px;
}

.mapWarehouseName p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95px;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 500;
}

.map {
  padding-left: 0px;
  padding-right: 0px;
}

.card-listing {
  overflow-y: auto;
  background-color: #fff;
  padding: 12px 16px 4px 16px;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 7px;
  backdrop-filter: blur(30px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
}

.card-scroll-listing {
  overflow-y: auto;
  height: 38vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-scroll-listing::-webkit-scrollbar {
  display: none;
}

.show-more-btn {
  font-size: 12px;
  text-decoration: underline;
  text-align: center;
  color: var(--theme-color);
}

.listing-heading {
  color: #021325;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
}

.card-warehouse-listing {
  padding: 11px 9px;
  border-radius: 10px;
  border: 1px solid #7090b028;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
}

.card-warehouse-listing.selected {
  border: 2px solid #2bc7c9;
}

.card-warehouse-heading {
  color: #021325;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}

.card-warehouse-location p {
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 195px;
  justify-content: center;
}

.card-warehouse-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.card-warehouse-infoBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-warehouse-infoBox p {
  color: #2bc7c9;
  font-size: 14px;
}

.add-new-address-wrap span.btn-home {
  background: var(--light-theme);
  border-radius: 29px;
  padding: 3px 10px;
  display: inline-block;
  margin: 0 15px 0 0 !important;
}

.details-wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.additional-helmets-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details-wrapper .vr {
  margin: 0 10px;
}

.value {
  margin-left: 15px;
  font-weight: 500;
}

.total-km img,
.excess-charge img,
.excess-hour-charge img {
  margin-right: 8px;
}

.total-km span,
.excess-charge span,
.excess-hour-charge span {
  font-size: 12px;
  font-weight: 500;
}

.total-km,
.excess-charge,
.excess-hour-charge {
  display: flex;
  align-items: center;
}

.add-details-wrapper h6 {
  font-weight: 400;
  text-decoration: underline;
}

.add-new-address-wrap .map-details iframe {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: 1px solid #dddddd !important;
  margin-top: 0;
  padding: 0;
  height: 250px;
}

.add-new-address-wrap span.btn-home {
  background: var(--light-theme);
  border-radius: 29px;
  padding: 3px 10px;
  display: inline-block;
  margin: 0 15px 10px 0 !important;
  cursor: pointer;
}

.add-new-address-wrap span.active {
  background: var(--theme-color) !important;
  color: white !important;
}

.details-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-wrapper .vr {
  margin: 0 10px;
}

.value {
  margin-left: 15px;
  font-weight: 500;
}

.total-km img,
.excess-charge img,
.excess-hour-charge img {
  margin-right: 5px;
  width: 26px;
  margin-top: -5px;
}

/*------------------- Booking-journey -------------------*/
.modal-content {
  background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
}

.location-wrap .modal-header {
  border-bottom: none;
  /* justify-content: center; */
  margin: 0;
  padding-bottom: 0;
}
.renting-wrap .modal-header {
  border-bottom: none;
  /* justify-content: center; */
  margin: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  padding-bottom: 5px;
  background: transparent linear-gradient(90deg, #56f7dc4d 0%, #ececec00 0%) 0%
    0% no-repeat padding-box;
}

.location-wrap .modal-dialog {
  transform: none;
  max-width: 95%;
}

.location-wrap .coming-soon-city-modal input {
  background: transparent;
  border: none;
  height: 50px;
  color: white !important;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 40px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding-left: 10px !important;
}

.location-wrap .coming-soon-city-modal {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.location-wrap .coming-soon-city-modal input::placeholder {
  color: black;
}

.location-wrap h4.modal-title {
  font-weight: 500;
  position: relative;
}

.location-wrap h4.modal-title::after {
  position: absolute;
  left: -3px;
  bottom: -3px;
  width: 30%;
  height: 3px;
  content: "";
  background: transparent linear-gradient(0deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
}

.location-wrap .modal-footer {
  border: none;
  justify-content: center;
}

.journey-box {
  border-radius: 3px;
  position: relative;
  margin-top: -50px;
  z-index: 0;
  margin-bottom: 6px;
  padding: 10px;
}

.journey-box .smallest-text {
  font-size: 0.8125em;
}

.city-wrap,
.date-wrap,
.reset-wrap {
  width: 29%;
  position: relative;
  z-index: 1;
}

.date-wrap {
  z-index: 9;
}

.city-wrap::after,
.date-wrap::after,
.reset-wrap::after {
  position: absolute;
  content: "";
  border: solid 1px var(--theme-color);
  width: 100%;
  height: 80%;
  bottom: 0;
  left: 0;
  background: #ffffff61;
  z-index: -1;
  margin-bottom: 18px;
  border-radius: 5px;
}

.dashed::after {
  border: dashed 1px var(--theme-color);
}

.city-wrap .top-img,
.date-wrap .top-img,
.reset-wrap .top-img {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  width: 100px;
}

.mid-wrap {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px auto;
  font-size: 16px;
  font-weight: 500;
}

.mid-wrap.datepicker {
  width: 100%;
}

.mid-wrap.datepicker input {
  border: solid 1px #868e96;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 70%;
  color: #868e96;
}

.progressbar {
  padding-left: 0;
  width: 60px;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  padding-top: 1px;
  font-size: 16px;
}

.progressbar span.active {
  color: #2bc7c9;
}

.progressbar span {
  list-style: none;
  display: inline-block;
  width: auto;
  position: relative;
  text-align: center;
}

.date-progress span.next {
  float: right;
}

.progressbar span.active:before {
  border-color: #2bc7c9;
}

.progressbar span:before {
  content: "";
  width: 8px;
  height: 8px;
  line-height: 30px;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
  background-color: #2bc7c9;
}

.progressbar span.next::before {
  background-color: #bbb;
}

.mt-70 {
  margin-top: 70px;
}

.progressbar span.active::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 1px;
  top: 3px;
  left: 8px;
  border: dashed 0.2px #bbb;
}

@media screen and (max-width: 576px) {
  .date-filter {
    display: flex;
    flex-direction: column;
  }

  .date-filter .progressbar {
    display: none;
  }

  .end-date {
    display: none;
  }

  .end-date-mobile {
    margin-top: 30px;
    display: inline-flex !important;
    color: #777;
    z-index: -100;
  }

  .start-end-date-text {
    display: inline-flex !important;
    position: absolute;
    top: -13px;
    color: var(--theme-color);
    left: 15px;
    border-radius: 100%;
  }
}

.start-end-date-text {
  display: none;
}

.end-date-mobile {
  display: none;
}

.end-date {
  color: #777;
  font-size: 14px;
  width: 35%;
}

.modal-body {
  margin: 0;
  padding: 10px 30px;
}

.Journey-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.Journey-wrap h6 {
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.journey-box h6::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 13%;
  height: 3px;
  content: "";
  background: transparent linear-gradient(0deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
}

.Journey-wrap p {
  font-size: 16px;
  margin: 4px 4px !important;
}

.location-display {
  width: 40%;
}

.time-box {
  background-color: #fff;
  border-radius: 5px;
  padding: 6px 16px 6px 5px;
  width: 30%;
  text-align: left;
  position: relative;
  height: auto;
  margin: 4px 4px;
  cursor: pointer;
  border: 1px solid #f3f3f3;
}

.time-box:hover,
.time-box:focus {
  box-shadow: 2px 2px 5px -1px rgb(195 190 190);
  border: none;
  transition: none;
}

.favourite::before {
  content: "";
  position: absolute;
  background-image: url(../assets/images/fav.png);
  right: 0;
  top: 0;
  width: 49px;
  height: 13px;
  z-index: 1;
  background-size: cover;
}

.time-box::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/Exclusion-1.png);
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  width: 12px;
  top: 0;
  background-size: cover;
}

.disable-img {
  opacity: 0.4;
}

.disable-package.dashed::after {
  border: none !important;
}

.disable-package h6,
.package-txt {
  opacity: 0.4;
}

.disable-package .mid-wrap.time-wrap {
  visibility: hidden;
}

.disable-package ~ button {
  visibility: hidden;
}

.mid-wrap.time-wrap {
  height: 140px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0 10px;
  overflow-y: auto;
  scrollbar-width: none;
}

.mid-wrap.time-wrap::-webkit-scrollbar {
  display: none;
}

.time-box h5 {
  font-size: 14px;
  margin: 0px;
  font-weight: 500;
  text-align: center;
}

.time-box p {
  font-size: 8px;
  padding: 0;
  margin: 0;
}

.step-process {
  background-color: #fff;
  border: solid 1px var(--theme-color);
  position: absolute;
  top: 50px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #fff;
  font-size: 16px;
  left: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dotted-circle {
  border: dashed 1px var(--theme-color);
}

button.close-model {
  border: none;
  position: absolute;
  right: 20px;
  top: 15px;
  background: transparent;
  font-size: 22px;
}

/* ------------------------Rent Page------------------------ */
.Renting-wrapper {
  background-color: var(--light-theme-grey);
  margin-top: var(--main-spacing-top);
  padding: 40px 0;
}

.rent-wrap h2 {
  text-align: center;
  margin-bottom: 30px;
}

.rent-wrap .modal-dialog {
  max-width: 95%;
}

.rent-wrap .col-lg-9 {
  width: auto;
  margin: 0 auto;
}

.tab-width {
  position: relative;
  left: 29%;
}

.tab-width .nav-pills {
  width: 50%;
}

.gm-style-mtc-bbw {
  margin: 10px;
  z-index: 0;
  position: absolute;
  cursor: pointer;
  left: 0px;
  top: 36px !important;
}

ul#pills-tab {
  border-bottom: solid 1px #d6d6d6;
  display: flex;
  justify-content: space-between;
}

.Renting-wrapper ul#pills-tab li {
  position: relative;
}

.Renting-wrapper .nav-pills .nav-link {
  margin: 0px 20px;
}

.Renting-wrapper .nav-pills .nav-link.active,
.Renting-wrapper .nav-pills .show > .nav-link {
  background: transparent;
  color: var(--dark-color);
  font-weight: 600;
}

.Renting-wrapper .nav-pills .nav-link.active::before,
.Renting-wrapper .nav-pills .show > .nav-link {
  background: none;
  color: var(--dark);
  border-bottom: solid 5px #2bc7c9;
  border-radius: 0;
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -3px;
  border-radius: 10px;
}

/* .rent-wrap .tab-content {
   margin: 30px 0 0 0;
} */

.Renting-wrapper .card {
  padding: 10px 15px;
  box-shadow: 2px 2px 14px -1px #7090b036;
  border: none;
  border-radius: 40px;
  position: relative;
  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Renting-wrapper .card img {
  width: 240px;
  margin-top: -30px;
  height: 150px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.deposite-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 15px 0 0 0;
}

.deposite-wrap span {
  display: flex;
  align-items: center;
  font-size: var(--small-text);
  position: absolute;
  font-weight: 500;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 400%;
  animation: move 5s ease infinite;
  background-image: linear-gradient(
    68.7deg,
    rgb(43, 199, 201) 13.2%,
    rgb(43, 154, 201) 29.8%,
    rgb(109, 127, 229) 48.9%,
    rgb(15, 58, 249) 68.2%,
    rgb(0, 140, 255) 86.4%
  );
}

@keyframes move {
  0%,
  100% {
    background-position: 200% 0%;
  }

  50% {
    background-position: 0% 200%;
  }
}

.deposite-wrap span:nth-child(1) {
  left: 10px;
}

.deposite-wrap span:nth-child(2) {
  display: flex;
  align-items: center;
  font-size: var(--small-text);
  position: absolute;
  right: 10px;
}

.deposite-wrap span i {
  padding: 0 5px;
  color: var(--theme-color);
  font-size: 0.9em;
}

.vehicle-wrapper .card .footer-btn {
  width: auto;
  margin: 35px auto 14px;
}

.pop-tag-wrap {
  height: 25px;
}

.populer-tag {
  height: 25px;
  width: 110px;
  z-index: 999;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0px;
  line-height: 1.8;
  position: relative;
  padding-left: 10px;
  left: -15px;
}

.populer-tag::after {
  position: absolute;
  background-image: url(../assets/images/populer-strip.png);
  background-size: cover;
  height: 25px;
  content: "";
  width: 110px;
  left: 0;
  z-index: -1;
}

.populer-tag .blog-wrap .card {
  border: none;
  background: #f4f1f1;
  border-radius: 15px;
}

.blog-wrap .card {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: none;
}

.testimonial a {
  font-size: 1em;
  text-align: left;
  color: var(--dark-color);
}

h6.bike-brand {
  color: var(--red);
  margin: 3px 0;
  font-size: 0.89em;
}

.name-price-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-price-wrap h6 {
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  font-size: 0.9em;
}

.name-price-wrap h6::before {
  position: absolute;
  content: "";
  background-color: var(--theme-color);
  width: 39px;
  height: 3px;
  border-radius: 10px;
  left: 0;
  bottom: 3px;
}

.name-price-wrap p {
  color: var(--theme-color);
  font-size: 0.98em;
  font-size: 14px;
  font-weight: 600;
  margin-top: -10px;
}

p.perday-rent {
  font-weight: 600;
  margin-top: 8px;
  font-size: 0.89em;
}

p.perday-rent i {
  color: var(--list-color);
  font-size: 0.8125em;
  font-weight: 400;
}

.rent-wrap .form-select {
  border: none;
  border-bottom: solid 1px #d6d6d6;
  border-radius: inherit;
  background-color: transparent;
  font-size: 0.9375em;
}

.blog-wrap .card .card-img-top {
  object-fit: cover;
  border-radius: 20px;
  transition: 1s ease;
  border: none;
  width: 100%;
  height: auto;
  padding: 0;
}

.Renting-wrapper button.freedo-outline-btn {
  margin: 0 auto;
  width: auto;
  margin-top: 45px;
}

.renting-wrapper {
  margin: 25px 0;
}

.filter-btnclose {
  right: 15px;
  top: 15px;
  position: absolute;
  font-size: 12px;
  opacity: 1;
  border: solid 1px #000;
  padding: 5px;
  border-radius: 100%;
  background-color: #f5feff;
}

.mobileFiltersMain {
  overflow-y: scroll;
}

.filter-head-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 15px;
  position: relative;
}

.filter-head-wrap h4 {
  position: relative;
  width: 100%;
}

.filter-head-wrap::after {
  border-bottom: dashed 1px #b7b7b7;
  position: absolute;
  width: 100%;
  content: "";
  bottom: -8px;
  left: 0;
}

.filter-head-wrap i {
  color: var(--theme-color);
}

.filter-list-wrap {
  background-color: var(--light-theme);
  padding: 20px 15px;
}

.range-slider .range-slider__thumb {
  background: #2bc7c9 !important;
}

.range-slider .range-slider__range {
  background: #76d3d5 !important;
}

.range-slider {
  margin-top: 10px;
}

.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0 4px 0;
}

.filter-list-wrap .form-check {
  margin: 10px 0;
}

.detect-filter {
  /* margin-right: 20px; */
  width: 23%;
}

.detect-filter .form-control {
  padding: 10px 15px 10px 35px;
  position: relative;
}

.detect-filter .loaction-detect {
  position: absolute;
  top: -17px;
  width: 25px;
  height: 25px;
  background: #fff;
  box-shadow: 3px 3px 15px 4px #b3b3b361;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  left: 11px;
  z-index: 99;
}

.detect-filter .start-end-date {
  position: absolute;
  top: -15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  left: 13px;
  z-index: 99;
}

.filter-row .form-control {
  border-right: 0;
  border-radius: 1.375rem;
}

.filter-row.form img {
  width: 22px;
}

.filter-row .input-group-text {
  border-radius: 1.375rem;
  background-color: transparent;
}

.date-filter {
  display: flex;
  align-items: center;
  width: 43%;
}

.date-filter .form-control {
  padding: 10px 15px;
  border: 1px solid #eee;
}

.day-select-filter .form-select {
  border: solid 1px #d6d6d6;
  border-radius: 30px;
  background-color: transparent;
  font-size: 0.9em;
  padding: 10px 15px 10px 35px;
  position: relative;
}

.day-select-filter {
  position: relative;
  width: 26%;
}

.day-select-filter .clock-Day {
  position: absolute;
  top: -17px;
  width: 25px;
  height: 25px;
  background: #fff;
  box-shadow: 3px 3px 15px 4px #b3b3b361;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  left: 25px;
}

.renting-wrap .accordion-item {
  border: none;
  box-shadow: none;
}

.rent-search-filter a {
  color: var(--dark-color);
  width: 35px;
  height: 35px;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  font-size: 16px;
}

.filter-row input::placeholder {
  color: #000;
}

.display-result-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -31px;
}

.display-result-filter p {
  margin: 0;
  color: var(--list-color);
  font-size: 0.8em;
}

.display-result-filter .form-select {
  width: 17%;
  border: solid 1px #d6d6d6;
  border-radius: 30px;
  background-color: transparent;
  padding: 0.3rem 0.4rem 0.3rem 0.59rem;
  color: var(--list-color);
  font-size: 0.8em;
}

/* .rent-fleet .vehicle-wrapper {
  justify-content: flex-start;
} */

.faq-showmore {
  margin: 45px 0 0 0;
}

.renting-wrap .accordion-button.collapsed {
  border: none;
  background-color: var(--light-theme);
  border-radius: 10px;
  padding: 15px 0;
  color: var(--dark-color);
  box-shadow: none;
  position: relative;
}

.renting-wrap .accordion-button:not(.collapsed) {
  background-color: var(--light-theme);
  border-radius: 10px 10px 0 0;
  padding: 15px 0;
  box-shadow: none;
  color: var(--dark-color);
}

.renting-wrap .accordion-header {
  position: relative;
}

.renting-wrap .accordion-header::after {
  position: absolute;
  background-color: var(--theme-color);
  width: 20px;
  height: 2px;
  content: "";
  left: 0;
  bottom: 9px;
  z-index: 9;
}

.renting-wrap .accordion-body {
  padding: 10px 0;
  background: var(--light-theme);
  border-radius: 0 0 10px 10px;
  position: relative;
}

.renting-wrap .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  background: none;
  content: "\f068";
  background-color: var(--theme-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 14px;
  transform: none;
  font-family: "FontAwesome";
}

.renting-wrap .accordion-button::after {
  width: 20px;
  height: 20px;
  margin-left: auto;
  content: "\2b";
  background: none;
  background-repeat: no-repeat;
  background-size: 0;
  transition: transform 0.2s ease-in-out;
  background-color: var(--theme-color);
  border-radius: 100%;
  color: var(--white);
  font-size: 22px;
  line-height: 18px;
  text-align: center;
}

/* .specific-list li{
  display: flex;
  flex-direction: row;

}  */

.vihicle-type-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vihicle-type-box .form-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all-vehicle-type-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.vihicle-type-box .form-check-input .form-check-label {
  margin-left: -10px;
}

.vehicle-specs .carousel-inner img {
  padding: 20px;
}

.vehicle-specs .carousel-inner {
  display: flex;
  height: 308px;
  align-items: center;
}

.rent-card-btn {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

/* ------------------------Rent Page End ------------------------ */

/* Support */
.support-box {
  top: 2rem;
  position: relative;
  bottom: 0;
  text-align: center;
  display: block;
}

.b-btn {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.b-btn.paypal i {
  color: blue;
}

.b-btn:hover {
  text-decoration: none;
  font-weight: bold;
}

.b-btn i {
  font-size: 20px;
  color: yellow;
  margin-top: 2rem;
}

/* price-range-slider End */
.rent-filter-result {
  margin: 0;
  background: transparent;
  padding: 0;
}

.rent-filter-result .vehicle-wrapper {
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
}

.rent-filter-result .vehicle-wrapper .card {
  width: 30%;
}

.breadcrumb-wrap a,
.breadcrumb-wrap li {
  font-size: 1rem;
}

.breadcrumb-wrap a,
.breadcrumb-wrap li {
  font-size: 0.75rem;
}

.booknow-model .modal.show .modal-dialog {
  max-width: 70%;
}

.modal-dialog {
  max-width: var(--bs-modal-width);
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 576px) {
  .modal-dialog {
    padding-bottom: 35px;
  }
}

.booknow-model img {
  margin-top: 0 !important;
}

.specific-wrap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.booknow-model h6.bike-brand {
  color: var(--red);
  margin: 20px 0 7px 0;
}

.specific-wrap ul li span {
  width: 35%;
  white-space: nowrap;
  display: inline-block;
  line-height: 35px;
  font-weight: 500;
}

.specific-wrap ul li span:nth-child(2) {
  font-weight: 600;
}

.edit-del-add {
  margin-bottom: 30px;
  display: flex;
  justify-content: end;
}

.edit-del-add a {
  margin-right: 10px;
  cursor: pointer;
}

.location-wrap .detect-btn {
  width: 30%;
  margin: 5px 0 5px 0;
}

/*------------------- REsponsive -------------------*/
@media screen and (max-width: 1280px) {
  .time-box {
    padding: 0px 10px 10px 5px;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 30%;
  }

  .date-filter .progressbar {
    width: 85px;
  }

  .date-filter .progressbar span.active::after {
    width: 74px;
  }

  .detect-filter {
    width: 30%;
  }

  .date-filter {
    width: 40%;
  }

  .date-filter .custom-calendar-img {
    cursor: pointer;
  }

  .day-select-filter .form-select {
    padding: 0.6rem 1.25rem 0.6rem 0.9rem;
  }

  .detect-filter .form-control {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1200px) {
  /* Home */
  .slider-content h1 {
    font-size: 3.125em;
  }

  .slider-content p {
    font-size: 1.125em;
  }

  .slider-img-wrap {
    width: 210px;
  }

  .slider-img-wrap video,
  .slider-img-wrap img.slide-3 {
    height: 190px;
  }

  .slider-img-wrap img {
    height: 150px;
  }

  .slider-right-wrap .top-content,
  .slider-right-wrap .bottom-content {
    font-size: 1em;
  }

  .features-content {
    overflow-x: auto;
  }

  .features-content .card {
    width: 220px;
    margin: 0 10px 23px 5px;
  }

  .time-box {
    width: 142px;
  }

  .mid-wrap.time-wrap {
    height: 145px;
    overflow-y: auto;
  }

  .time-box {
    padding: 6px 16px 6px 5px;
    width: 102px;
    margin: 7px;
  }

  .deposite-wrap {
    flex-wrap: wrap;
    margin: 15px 0 0 0;
  }

  .deposite-wrap span {
    margin-bottom: 10px;
  }

  p.price {
    font-size: 0.995em;
  }

  .deposite-wrap span i {
    padding: 0 8px;
  }

  /* ------Rent ------*/
  .filter-offcanvasbtn {
    display: none;
  }

  .filter-row {
    flex-wrap: wrap;
  }

  .day-select-filter {
    width: 41%;
    margin: 20px 0;
  }

  .rent-search-filter {
    margin: 10px 0;
  }

  .date-filter {
    width: 55%;
    margin-bottom: 20px;
  }

  .detect-filter {
    width: 40%;
    margin-bottom: 20px;
  }

  .vihicle-type-box {
    justify-content: start;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .filter-list-wrap .form-check {
    margin: 10px 14px 0 0;
  }

  /* List */
  .list-view-wrap {
    width: 25% !important;
    padding: 8px 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .contact-wrap {
    text-align: center;
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    color: var(--white);
  }

  .offcanvas.offcanvas-end .navbar-nav {
    margin: 30px 0 0 0 !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 15px 25px;
  }

  .login-wrap {
    margin-left: 25px;
    margin-top: 15px;
  }

  .main-content-wrapper {
    margin: 0 20px;
  }

  .container-fluid {
    padding: 0 20px;
  }

  .Info-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Slider */
  .slider-img-wrap {
    width: 185px;
  }

  .slider-content h1 {
    font-size: 2.5em;
  }

  .slider-wrapper .col-12 {
    padding: 0;
  }

  .slider-content p {
    font-size: 0.875em;
  }

  .light-freedo-btn {
    padding: 10px 10px;
  }

  .light-freedo-btn {
    padding: 10px 10px;
    background-color: #fafafa;
    font-size: 1em;
    border: none;
  }

  .location-wrap input {
    text-align: right;
    padding-right: 6%;
  }

  .slider-right-wrap .top-content {
    top: 10px;
    right: 0;
    font-size: 0.9375em;
  }

  .slider-right-wrap .bottom-content {
    bottom: 20px;
    left: 0;
    font-size: 1em;
  }

  .slider-right-wrap .top-content .fa-solid.fa-circle-check,
  .slider-right-wrap .bottom-content .fa-solid.fa-circle-check {
    font-size: 28px;
    margin: 0 10px;
    color: var(--dark-color);
  }

  .Renting-wrapper .nav-pills .nav-link {
    color: var(--dark-color);
  }

  .vehicle-wrapper {
    overflow-x: auto;
    grid-gap: 35px;
  }

  .Renting-wrapper .card {
    width: 300px;
    margin-bottom: 20px;
  }

  .Renting-wrapper .card img {
    margin: -30px auto;
    display: block;
  }

  .product-details img.bike-right {
    max-width: 100%;
  }

  .details-wrapper .vr {
    margin: 0 22px;
  }

  /*-------------------- booking journey --------------------*/
  .journey-btn {
    left: 19%;
    right: 20%;
  }

  .time-box {
    width: 100%;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 32%;
    padding-bottom: 10px;
  }

  .mid-wrap {
    height: 126px;
    width: 126px;
  }

  .mid-wrap img {
    max-width: 100%;
    height: 100%;
  }

  .time-box {
    margin: 4px 7px;
  }

  .time-box {
    height: 40px;
    padding-left: 15px;
  }

  .mid-wrap.time-wrap {
    height: 126px;
  }

  .Journey-wrap p {
    margin-bottom: 1em;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 28px;
  }

  .step-process {
    top: 40px;
  }

  .modal-dialog-centered {
    margin: 30px 20px;
  }

  .freedo-primary-button,
  .freedo-outline-btn {
    font-size: 0.875em;
    padding: 8px 20px;
  }

  .freedo-primary-button-lg {
    padding: 10px 45px;
  }

  .progressbar-wrap {
    font-size: 0.8375em;
  }

  .progressbar {
    width: 60px;
  }

  .progressbar span.active::after {
    width: 50px;
  }

  .mid-wrap.datepicker {
    width: 200px;
  }

  /* .blog-wrap .card .card-img-top {
    height: 250px;
  } */

  .Renting-wrapper .nav-pills .nav-link {
    margin: 0px 0;
  }

  ul.loacton-list {
    white-space: nowrap;
    overflow-x: auto;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 10px 0 0;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 185px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img {
    height: 150px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img.slide-3,
  .innerpage-slider-right-wrap .slider-img-wrap img.slide-2 {
    height: 190px;
  }

  .innerpage-img-head h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  .inner-bottom-text {
    margin-right: 20px;
  }

  .display-result-filter .form-select {
    width: 20%;
    font-size: 0.9375em;
    font-weight: 300;
  }

  .filter-row .form-control {
    font-size: 0.9375em;
    font-weight: 300;
  }

  .rent-filter-result .vehicle-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 45%;
  }

  .faq-banner-wrap {
    height: 100px;
  }

  footer p {
    padding: 0px 0;
  }

  .change-address-wrapper .offcanvas-body {
    background: #fff;
  }
}

@media screen and (max-width: 850px) {
  :root {
    --main-text: 14px;
    --main-heading: 20px;
  }

  /* .apply-coupon a {
    border: solid 1px var(--theme-color);
    color: var(--theme-color);
    padding: 6px 15px;
    border-radius: 31px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  } */

  .apply-coupon {
    flex-wrap: wrap;
  }

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .helmate-wrap form p {
    margin: 0;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
    margin: 10px 0;
  }

  .value-button {
    width: 100% !important;
  }

  input#number {
    width: 100% !important;
  }

  .time-slot-details .timing-sets {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  /*-------------------- booking journey --------------------*/

  .journey-btn {
    left: 9%;
    right: 9%;
  }

  .mid-wrap {
    margin: 0 auto;
    font-weight: 500;
  }

  .mid-wrap img {
    height: 125px;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 0px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 165px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 0px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 10px;
  }

  .mission-wrapper .content-wrap p {
    margin: 10px 0 15px 0;
  }

  .mission-wrapper .content-wrap h6 {
    margin-bottom: 30px;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }

  /* 
  .day-select-filter {
    width: 35%;
  } */

  .steps-inner-wrap {
    justify-content: start;
    overflow-x: auto;
  }

  /* ---------------Rent-page--------------- */
  .vehicle-wrapper .card a {
    margin-top: 20px;
  }

  .name-price-wrap {
    flex-wrap: wrap;
  }

  .deposite-wrap {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --main-spacing-top: 30px;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  /* ------------------- 18px ------------------- */
  h4 {
    font-size: 1.125em;
  }

  /* ------------------- 15px ------------------- */
  h5 {
    font-size: 0.9375em;
  }

  /* ------------------- 15px ------------------- */
  h6 {
    font-size: 0.9375em;
  }

  p {
    font-size: 0.9375em;
  }

  /* ------------------- 14px ------------------- */
  .small-text {
    font-size: 0.875em;
  }

  .carousel-indicators {
    bottom: -30px;
  }

  .main-content-wrapper .row.xs-reverse {
    flex-direction: column-reverse;
  }

  .product-details img.bike-right {
    float: left;
  }

  .delivery-details button.Change-Address {
    float: left;
    margin-top: 14px;
  }

  .coupon-details-wrapper {
    margin-top: 20px;
  }

  .mid-wrap {
    height: 130px;
  }

  .mid-wrap.time-wrap {
    height: 130px;
  }

  .mid-wrap img {
    height: 130px !important;
  }

  .step-process {
    top: 65px;
  }

  .modal-dialog-centered {
    margin: 30px 15px;
  }

  .date-progress {
    justify-content: start;
  }

  .progressbar span.active::after {
    width: 35px;
  }

  .progressbar {
    width: 44px;
  }

  .progressbar-wrap {
    font-size: 0.8125em;
  }

  .blog-wrap .row {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .blog-wrap .card {
    margin-bottom: 20px;
  }

  /* .blog-wrap .card .card-img-top {
    height: 167px;
    width: auto !important;
    object-fit: cover;
    border-radius: 14px;
  } */

  .counter-row .counter-number {
    font-size: 1.875em;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
  }

  .download-app-section h4 {
    margin-top: 20px;
  }

  .download-app-btn-wrap img {
    margin-bottom: 20px;
  }

  .innerpage-slider-right-wrap {
    display: none;
  }

  .innerpage-main-head {
    padding: 20px 0;
    text-align: center;
  }

  .innerpage-main-head h1 {
    font-size: 3.0625em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .innerpage-main-head p {
    font-size: 16px;
    margin-top: 15px;
  }

  .innerpage--wrapper {
    padding: 20px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
  }

  .steps-border {
    width: 100px;
  }

  .helmate-wrap form p br {
    display: none;
  }

  /* .apply-coupon a {
    width: 30%;
    margin: 0;
  } */

  .coupon-details-wrapper .helmate-wrap nav {
    width: 50%;
  }

  .renting-filter-wrapper .filter-offcanvasbtn {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .renting-filter-wrapper .responsive-sm {
    display: block;
  }

  .renting-filter-wrapper .responsive-xl {
    display: none;
  }

  /* Rent */
  .filter-row {
    margin: 28px 0 0 0;
    flex-wrap: wrap;
  }

  .detect-filter {
    width: 35%;
  }

  .date-filter {
    width: 60%;
  }

  .day-select-filter {
    margin: 20px 0;
  }

  .rent-search-filter {
    margin: 20px 0;
  }

  .display-result-filter .form-select {
    width: 20%;
  }

  .day-select-filter .form-select {
    margin-left: 0px;
  }

  .renting-filter-wrapper .offcanvas-body {
    background: var(--light-theme);
  }

  .rent-filter-result .vehicle-wrapper {
    margin-top: 25px;
  }

  /* Rent-end */

  .main-faq-wrapper {
    margin: 30px 0 0 0;
  }

  .contact-content {
    margin: 30px 0;
  }

  .office-content .row {
    flex-direction: column-reverse;
  }

  .support-wrap {
    margin: 20px 0 0 0;
  }

  .socail-support {
    margin: 0 0 10px 0;
  }

  .card-listing {
    width: 100%;
    background-color: #fff;
    margin-left: 0px;
  }

  .card-scroll-listing {
    padding-bottom: 10px;
    height: unset;
  }

  .map {
    padding-top: 10px;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

/* Single-blog-page */

.single-blog-main {
  padding: 450px 0 0 0;
  background-size: cover;
  color: var(--white);
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  height: 350px;
  background-size: 100% auto;
}

.blog-card-img {
  border-radius: 20px;
}

.single-blog-main-img {
  width: 100%;
}

.single-blog-main-img img {
  width: 100%;
}

.single-blog-content-main {
  width: 90%;
  background: #fff;
  margin-left: 0;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 17px 22px #74747414;
  margin: -50px auto 20px;
}

p.single-blog-detail {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 20px;
}

.delete-account-otp-container .otpContainer {
  justify-content: left !important;
  padding: 0% !important;
  margin: 0% !important;
}

@media screen and (max-width: 992px) {
  /*------------------ Settings Page ------------------*/
  .location-wrap .detect-btn {
    width: 30%;
    margin: 0 0 5px 0;
  }

  .navbar-collapse {
    background-color: var(--dark-color);
  }

  .blog-main-img {
    width: 50%;
  }

  .blog-content-main {
    width: 57%;
    margin-left: -81px;
  }

  .day-select-filter .form-select {
    margin-left: 0px;
  }

  .user-btn button {
    background-color: transparent;
  }

  .user-btn button:hover {
    background-color: #f8f9fa !important;
    color: var(--dark-color);
  }

  .user-btn .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: transparent !important;
    color: var(--light-theme);
  }

  .contact-wrap {
    display: none;
  }
}

@media screen and (max-width: 765px) {
  /*-------------------- booking journey --------------------*/

  /* slider */

  .infowindow {
    background-color: #1c2541;
    color: #fff;
    left: 10px;
    text-align: center;
    padding: 0;
    width: 70px;
    max-width: 70px;
    height: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 15px;
  }

  .slider-right-wrap {
    position: relative;
    display: none;
  }

  .slider-wrapper {
    padding: 20px 0 50px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
  }

  button.proceed {
    width: 85%;
  }

  .slider-content p br,
  .slider-content h1 br {
    display: none;
  }

  .slider-content {
    text-align: center;
    justify-content: center;
  }

  .carousel-inner {
    overflow: visible;
  }

  .location-wrap {
    margin: 0 auto;
    width: 68%;
    margin-top: 40px;
  }

  .location-wrap input {
    padding-left: 135px;
    width: 100%;
  }

  .carousel-indicators {
    bottom: -63px;
  }

  /* Booking */
  .Journey-wrap {
    overflow-x: auto;
    overflow-y: clip;
  }

  @media screen and (max-width: 414px) {
    .Journey-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      align-items: flex-end;
      text-align: center;
    }

    .Journey-wrap {
      overflow-x: unset;
      overflow-y: unset;
    }

    .city-wrap,
    .date-wrap,
    .reset-wrap {
      margin: 0px !important;
    }

    .city-model-wrap .mid-wrap p {
      display: inline-block;
      width: 43%;
    }
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 100%;
    margin: 10px;
    padding-bottom: 20px;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 200px;
  }

  .mid-wrap img {
    max-width: 100%;
  }

  .modal-body {
    padding: 0 15px;
  }

  /* 
  .apply-coupon a {
    width: 30%;
  } */

  /* List */
  .list-view-wrap {
    width: 30% !important;
  }

  /* Blog */
  .blog-wrap .row {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .blog-wrap .card {
    margin-bottom: 20px;
  }

  /* .blog-wrap .card .card-img-top {
    height: 167px;
    width: auto !important;
    object-fit: cover;
    border-radius: 14px;
  } */

  .blog-main-img {
    width: 100%;
  }

  .blog-content-main {
    width: 100%;
    background: #fff;
    margin: -100px 20px 0 20px;
  }
}

@media screen and (max-width: 575px) {
  .single-blog-main {
    background-position: center;
  }
  .steps-border {
    width: 20px;
  }

  .mode-delivery .form-check {
    float: left;
    margin-top: 10px;
  }

  .product-details span.hero {
    margin-top: 12px;
  }

  .delivery-details .text-end {
    text-align: left !important;
    margin: 10px 0 0 0;
  }

  .time-slot-details .time-slot .nav-link.active,
  .time-slot-details .time-slot .nav-link:hover {
    font-size: 13px;
  }

  .time-slot-details .time-slot .nav-link {
    font-size: 13px;
  }

  .form-check-input {
    width: 15px;
    height: 15px;
  }

  .coupon-details-wrapper {
    padding: 15px;
  }

  /* .apply-coupon a {
    padding: 7px 10px;
  } */

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .slider-content h1 {
    font-size: 1.563em;
  }

  .location-wrap button {
    position: absolute;
    z-index: 1;
    font-size: 14px;
  }

  .location-wrap {
    margin: 0 auto;
    width: 100%;
    margin-top: 40px;
  }

  .location-wrap input {
    font-size: 0.875em;
    margin-left: 0px;
  }

  .counter-row .counter-number {
    font-size: 1.5625em;
  }

  .faq-wrap {
    margin: 30px 0;
    padding: 11px;
  }

  .faq-wrap .accordion-item {
    margin: 20px 20px 0 20px;
  }

  .faq-wrapper {
    padding: 0;
  }

  footer p {
    padding: 0px 0;
    margin-bottom: 20px;
  }

  .footer-content {
    text-align: center;
  }

  .footer-content br {
    display: none;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 8px;
  }

  .download-app-btn-wrap img {
    width: 160px;
    margin-right: 10px;
    margin-bottom: 14px;
  }

  .copyright-wrap {
    padding: 10px 0;
  }

  footer {
    padding: 30px 0 0 0;
  }

  .offcanvassigninWrapper .offcanvas-body {
    padding: 15px;
  }

  .resend-otp a {
    color: var(--theme-color);
    font-weight: 600;
    text-decoration: underline;
  }

  .mission-wrapper .content-wrap {
    padding: 20px;
  }

  .mission-wrapper {
    margin: 30px 0 0 0;
    text-align: center;
  }

  .story-wrap img,
  .mission-wrap img,
  .vision-wrap img {
    max-width: 100%;
    height: 300px;
    margin: 0 auto;
    text-align: center;
  }

  .story-wrap,
  .mission-wrap,
  .vision-wrap {
    background-color: #f9f9f9;
    margin: 30px 0;
    padding: 30px;
  }

  .mission-wrap .row {
    flex-direction: column-reverse;
  }

  .check-out-step-wrap {
    display: none;
  }

  .breadcrumb-wrap a,
  .breadcrumb-wrap li {
    font-size: 0.875rem;
  }

  button.pay-now {
    width: 100%;
  }

  /* Rent */
  .day-select-filter {
    width: 60%;
  }

  .display-result-filter .form-select {
    width: 35%;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }

  .day-select-filter {
    width: 80%;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 100%;
    margin-bottom: 10px;
  }

  .offcanvas {
    width: 100% !important;
  }

  .filter-head-wrap {
    margin-top: 35px;
  }

  /* Rent-end */
  .faq-banner-wrap {
    padding: 30px 0;
    height: 77px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 395px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 100%;
  }

  .coupon-right {
    padding: 0;
  }

  .change-address-wrapper-inner {
    position: relative;
  }

  .edit-del-add {
    position: absolute;
    top: 0;
    right: 0;
  }

  .change-address-wrapper-inner button {
    margin-left: 22px;
  }

  .success-book-text {
    font-size: small;
  }

  .testimonial-img-container {
    height: 210px;
  }
}

.offcanvas-backdrop ~ .offcanvas-backdrop {
  opacity: 0;
}

@media screen and (max-width: 460px) {
  .use-button-wrap {
    width: 100%;
    white-space: nowrap;
  }

  .address-details-wrap {
    width: 100%;
  }

  .change-address-wrapper-inner .freedo-outline-btn.rounded-pill {
    margin-left: 22px;
  }

  /* List */
  .list-view-wrap {
    width: 40% !important;
  }

  /* Blog page */
  .blog-content-main {
    margin: -100px 5px 0 5px;
    padding: 15px;
  }

  .blog-content {
    padding: 15px;
    margin: -50px 5px 0 5px;
  }
}

@media screen and (max-width: 414px) {
  .location-wrap .detect-btn {
    width: 100%;
  }

  .journey-btn {
    bottom: -2%;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 215px;
  }

  button.close-model {
    font-size: 18px;
  }

  .fee-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 14px;
  }

  .zero {
    margin-right: 10px;
  }

  .fee-wrapper .zero img {
    width: 14px;
    margin-right: 5px;
  }

  .fee-wrapper .unlimited img {
    width: 20px;
    margin-right: 2px;
  }

  .product-details img.bike-right {
    display: block;
    float: none;
    margin: 0 auto;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
  }

  /* Rent */
  .date-progress {
    flex-wrap: wrap;
  }

  .day-one {
    margin: 7px 0 0 0;
  }

  .delete-account-button-container button {
    margin-top: 10px;
  }

  .ul.specific-list li span {
    width: 94px;
  }

  ul.specific-list li span:nth-child(even) {
    width: 180px !important;
  }

  .location-display {
    width: 50%;
  }
}

@media screen and (max-width: 360px) {
  button.close-model {
    right: -6px;
    top: -12px;
    font-size: 14px;
    background: #fff;
    width: 25px;
    height: 25px;
    border: solid 1px #ccc;
    border-radius: 100%;
  }

  h4 {
    font-size: 0.9375em;
  }

  .contact-wrap a {
    display: block;
  }

  .contact-wrap a {
    display: block;
  }

  .display-result-filter {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

.font-italic {
  font-style: italic;
}

.text-justify {
  text-align: justify;
}

.error-message {
  color: red;
  font-size: 12px;
}

.office-address-wrap p {
  margin-bottom: 1rem;
  margin-top: 1rem;
  line-height: 20px;
}

/* Rent */
.rent-wrap .progressbar-wrap {
  margin-left: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #777;
  width: 100%;
}

.slider {
  width: 100%;
  margin: 20px auto;
}

.slick-slide {
  margin: 0px 10px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #242b35;
}

.slick-active {
  opacity: 1;
}

.slick-current {
  opacity: 1;
}

/* 
.warhouse-price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.price-type-low {
    font-size: 13px;
    display: flex;
    align-items: center;
    position: relative;
    color: #242b35;
}

.pricetag {
    background: #1C2541;
    color: #fff;
    padding: 5px 20px;
    margin-bottom: 0;
    border-radius: 18px;
    font-size: 14px;
} */
.card-3 .price-type-low {
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-low::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #82cd47;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-high {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-high::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fc2828;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-medium {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.card-3 .price-type-medium::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff6d28;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .card-title {
  font-size: 16px;
  color: #1c2541;
  text-align: left;
}

/* 
.slick-slide.slick-active:hover {
  border: solid 3px #1de5f0;
  box-shadow: 3px 2px 19px 0 #1de5f01c;
} */

.card-3 p {
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #1c2541;
  text-align: left;
}

.package {
  color: #14b8bb !important;
  font-size: 13px !important;
  padding: 3px 0 0 0;
}

.warehouse-img {
  border-radius: 10px;
}

.card-3 {
  border: none;
  transition: all ease-in-out 0.3s;
  opacity: 1;
  box-shadow: 3px 2px 19px 0 #00000026;
  margin: 20px;
  border-radius: 10px;
  text-align: center;
}

.card-3 .warehouse-img {
  border-radius: 10px;
  margin: 10px;
  object-position: center;
  width: 131px !important;
  height: 97px;
  margin: 0 auto;
  margin-top: -20px;
  box-shadow: 4px 3px 10px 3px #d9f3fb;
}

.card-3 .warhouse-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px 0;
}

.card-3 .pricetag {
  /* background: #1C2541; */
  color: #1c2541;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
}

.list-slider .slick-prev::before,
.list-slider .slick-next::before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-slider .slick-prev::before {
  background-image: url(../assets/images/arrow-left.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.list-slider .slick-next {
  right: 0;
}

.list-slider .slick-prev {
  left: -15px;
}

.list-slider .slick-next::before {
  background-image: url(../assets/images/arrow-right.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.fee-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 14px;
}

.zero {
  margin-right: 10px;
}

.fee-wrapper .zero img {
  width: 22px;
  margin-right: 5px;
}

.fee-wrapper .unlimited img {
  width: 28px;
  margin-right: 2px;
  margin-top: -2px;
}

.billing-wrap .table-responsive {
  border-radius: 7px;
  padding: 5px 10px;
}

.billing-wrap .table-responsive .table th {
  padding-bottom: 8px;
  font-size: 0.9375em;
}

.billing-wrap .table-responsive .table td {
  font-size: var(--small-text);
}

button.pay-now {
  margin-top: -47px;
  width: 100%;
  margin-bottom: 10px;
}

.billing-wrap .table-responsive .table td.active {
  color: #021325;
  font-weight: 500;
}

@media screen and (max-width: 340px) {
  .date-filter {
    flex-wrap: wrap;
    margin-right: 20px;
  }

  .date-filter .progressbar {
    width: 100px;
  }

  .date-filter .progressbar span.active::after {
    width: 88px;
  }

  .rent-search-filter a {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    font-size: 14px;
  }

  .display-result-filter .form-select {
    padding: 0.375rem 1.395rem 0.375rem 0.795rem;
  }
}

.float-right {
  float: right;
}

.applicable-info,
.applicable-info-deliveryTime {
  margin-bottom: 0;
  font-size: 10px;
  color: #2bc7c9;
  color: var(--theme-color);
  font-weight: 600;
}

.applicable-info {
  text-align: right;
  padding: 3px 30px;
}

.applicable-info .add-address-home-delivery {
  color: #2bc7c9;
  cursor: "pointer";
}

.applicable-info .address-not-deliverable-text {
  color: #fa8e37;
  text-wrap: wrap;
}

.custom-calendar-img::-webkit-calendar-picker-indicator {
  background-image: url("../assets/images/calendar-grey.png");
}

.disc-price {
  color: #21dec6;
}

/*---------------- Blog-page-------------- */
.blog-main-img {
  width: 40%;
}

.blog-main-img img {
  width: 100%;
}

.blog-sub-content {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.blog-sub-content img {
  overflow: hidden;
  width: 300px;
  height: 200px;
  border-radius: 10px;
}

.blog-content-main {
  width: 60%;
  background: #fff;
  margin-left: -60px;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 17px 22px #74747414;
}

.blog-time {
  font-size: 12px;
}

.blog-time span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.blog-content {
  background: #fff;
  margin-top: -50px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 17px 22px #74747414;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  min-height: 290px;
}

.popular-content {
  width: 82%;
}

.popular-content p {
  font-size: 12px;
}

.search-blog .input-group {
  box-shadow: 0px 8px 17px #dddddd4a;
  border-radius: 8px;
}

.search-blog .input-group-text {
  background: #f4f4f4;
  border-color: transparent;
  padding: 15px 25px;
}

.search-blog .form-control {
  border: none;
  padding: 15px 15px;
  font-size: 15px;
}

.card-details {
  margin-top: 12%;
  display: inline-grid;
}

.pac-container {
  z-index: 2000;
}

.blog-content-snippet {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* Single-blog-responsive */
@media screen and (max-width: 991px) {
  .blog-main h5 {
    margin: 10px 0 0 0;
    font-size: 1em;
  }
  ul.specific-list li span:nth-child(even) {
    width: 96px;
    display: inline-block;
    padding: 5px 0;
  }

  .blog-main p {
    font-size: 0.875em;
    line-height: 25px;
  }

  .side-head {
    margin-top: 15px;
  }

  .popular-blog {
    display: flex;
    flex-wrap: wrap;
  }

  .popular-blog h5 {
    width: 100%;
  }

  .blog-box {
    width: 48%;
  }

  .single-blog-content-main {
    width: 100%;
    background: #fff;
    margin-left: 0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 17px 22px #74747414;
    margin: -30px auto 0px;
  }
}

@media screen and (max-width: 767px) {
  .single-blog-main-img {
    width: 100%;
  }

  .single-blog-main-img img {
    width: 100%;
  }

  p.single-blog-detail {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0px;
  }

  .single-blog-content-main {
    padding: 20px 15px;
    margin: 10px auto;
    box-shadow: 0 6px 12px #74747414;
  }

  .blog-box {
    margin: 10px 0 !important;
  }

  .bike-sp-wrap {
    margin-top: 65px !important;
  }
}

@media screen and (max-width: 576px) {
  .blog-box {
    width: 100%;
  }

  .adbannerimg {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    object-fit: cover;
  }
}

/* Payment Wrap */
.payment-wrap video {
  height: 180px;
}

.payment-text,
.payment-list {
  margin-top: -20px;
}

.payment-wrap h5 {
  color: var(--theme-color);
  margin-top: 0px;
}

.payment-list li p {
  font-size: 1em;
}

.payment-wrap .card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Privacy & Terms */
.privacy-banner {
  width: 100%;
  height: 300px;
  background: fixed no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
  width: 100%;
}

.banner-title {
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto;
  left: 50%;
  color: #fff;
  font-size: 2em;
}

.freedo-policy .card {
  margin-top: -80px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.freedo-policy h6 {
  text-decoration: dashed;
  border-bottom: 2px dashed #2bc7c9;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.freedo-policy p {
  line-height: 1.5;
  letter-spacing: 0.021em;
  color: var(--list-color);
  margin-bottom: 20px;
  font-size: 0.86em;
}

.cp {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.invisible {
  visibility: hidden;
}

.list-disc li {
  list-style-type: disc;
}

.sub-list li p {
  margin-bottom: 10px;
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 320px;
  width: 450px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.footer-img img {
  width: 65%;
}

.footer-img .card-body {
  text-align: center;
}

@media screen and (max-width: 567px) {
  .banner-title {
    font-size: 1.3em;
    width: 100%;
  }

  .footer-img {
    height: auto;
    width: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .date-filter .progressbar {
    width: 83px;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }
}

.input-group .react-datepicker-wrapper {
  width: 100%;
}

@media only screen and (min-width: 320px) {
  .date-filter .progressbar {
    width: 87px;
  }

  .date-filter .progressbar span.active::after {
    width: 76px;
  }

  /* .detect-filter,
  .date-filter {
    width: 100%;
  } */
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.footer-img img {
  width: 65%;
}

.footer-img .card-body {
  text-align: center;
}

.gm-ui-hover-effect {
  display: none !important;
}

.city-model-wrap .city-new {
  width: 67px;
  height: 19px;
  position: absolute;
  top: 0px;
  left: -1px;
  object-fit: unset;
}

.city-new-text {
  position: absolute;
  left: 20px;
  top: 18px;
  bottom: 0;
  color: white;
}

/* booking detail starts here */

/* booking detail ends here */

/* modify booking starts */

#modify-modal .modal-header .btn-close {
  margin: 0;
}
.modify-booking-details .date-container .calender-btn {
  color: var(--theme-color);
  font-weight: 600;
  margin-right: 5px;
}
.modify-booking-details .date-container .modify-label-items {
  margin-bottom: 10px;
}
.modify-booking-details .date-container .modify-label-items p {
  font-size: 16px;
}
.modify-booking-details .date-container .modify-label-items span {
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}
.modify-booking-details .date-container .modify-label-items span i {
  color: var(--theme-color);
}
.modify-booking-details .date-container .modify-label-items span i {
  color: var(--theme-color);
}
.modify-booking-details .react-datepicker {
  border: 1px solid #cccccc;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.modify-booking-details .react-datepicker-wrapper {
  margin: 10px 0;
}
.modify-booking-details .date-container .react-datepicker {
  display: flex;
  flex-direction: column;
}
.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__header {
  background-color: var(--theme-color);
  border-bottom: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__current-month {
  font-size: 1rem;
}
.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__header
  div {
  color: white;
  font-weight: 600;
}

.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__children-container {
  margin: auto;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #cccccc;
}
.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__children-container
  div {
  text-align: center;
}

.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__children-container
  button:disabled {
  opacity: 1 !important;
  cursor: auto;
}
.modify-booking-details .react-datepicker-popper {
  padding-top: 0px !important;
}
.modify-booking-details .react-datepicker__triangle {
  display: none;
}

.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__children-container
  button {
  padding: 6px 15px !important;
  margin: 0 5px !important;
  font-size: 0.9em;
}
.modify-booking-details .color-primary {
  color: var(--theme-color);
}
.modify-booking-details
  .date-container
  .react-datepicker
  .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: var(--theme-color) !important;
  color: #fff !important;
  border-radius: 50% !important;
}
.modify-booking-details .react-datepicker__day:hover {
  border-radius: 50% !important;
}
.modify-booking-details .react-datepicker__navigation {
  top: 0px !important;
  color: white;
}
.modify-booking-details .react-datepicker__navigation:hover {
  color: white;
}
.modify-booking-details .react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: #f0f0f0;
}
.modify-booking-details .react-datepicker__navigation-icon--previous::before {
  border-color: white;
}
.modify-booking-details .react-datepicker__navigation-icon--next::before {
  border-color: white;
}
.modify-booking-details .react-datepicker__navigation:hover *::before {
  border-color: white;
}
.modify-booking-details .date-container .react-datepicker__input-container {
  display: none;
}
.modify-booking-details .modification-table {
  border: 0.4px solid #e2e2e2;
  border-radius: 13px;
}
.modify-booking-details .fw-500 {
  font-weight: 500;
}
.modify-booking-details button:disabled {
  cursor: auto;
  opacity: 0.5;
}
.modify-booking-details i.icons {
  font-size: 20px;
}
.modify-booking-details i.fa-arrow-left {
  font-size: 18px;
}

.modify-booking-details .modal-title {
  font-size: 20px;
  position: relative;
}
.modify-booking-details .modal-sub-title {
  font-size: 16px;
  display: inline-block;
}
.modify-booking-details .modify-confirm-button {
  background-color: transparent;
}
.theme-color {
  color: var(--theme-color);
}

.modify-booking-details .theme-border-bottom::after {
  /* h5.modal-title::after { */
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 30%;
  height: 2px;
  content: "";
  background-color: var(--theme-color);
}
.modify-booking-details .date-container .modify-label-items p > :first-child {
  font-weight: normal;
  width: 160px;
  display: inline-block;
  padding-left: 0;
}
.modify-booking-details .modification-table h6 {
  font-size: 18px;
}
.modify-booking-details .date-container table th {
  background-color: #bfeeef;
  width: 33.33%;
  font-weight: 16px;
}
.modify-booking-details .date-container table tr {
  text-align: center;
}
.modify-booking-details .date-container table td {
  font-weight: 500;
  font-size: 14px;
}
.modify-booking-details .date-container table tbody tr:hover {
  background-color: var(--light-theme);
}
/* .modify-booking-details .date-container table tbody tr:hover {
  opacity: 0.2;
} */
@media screen and (min-width: 320px) and (max-width: 992px) {
  .modal {
    overflow-y: hidden;
  }
  .modify-booking-details .modal-title {
    font-size: 18px;
  }
  .modify-booking-details .modal-sub-title {
    font-size: 16px;
  }
  .modify-booking-details .date-container .modify-label-items span {
    font-size: 14px;
  }
  .modify-booking-details .date-container .modify-label-items p > :first-child {
    font-weight: normal;
    width: 140px;
    display: inline-block;
    padding-left: 0;
  }
  .modify-booking-details .date-container table th {
    font-size: 14px;
  }
  .modify-booking-details .date-container table td {
    font-size: 14px;
  }
}

/* modify booking ends */

/* success modal */
.successModalContainer .modal-body p {
  font-weight: 500;
}
.successModalContainer a {
  text-decoration: underline;
}
/* success modal ends */
