.rideStartDate{
    width : 60%;
    border: 1px solid #ECECEC;
    border-radius: 30px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
}

.planChargesList{
    display: flex;
    justify-content: space-between !important;
    padding: 0px 16px !important
}

.carouselStyle{
    border: 1px solid #ECECEC;
    border-radius: 5px;
    padding: 40px 20px;
}

.headingValueStyle{
    display:flex;
    border-bottom: 1px solid #ECECEC;
     padding: 8px
}

.planTabs{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50% 
}

.topCarousel{
    display:none
}

.subscriptionOfferCards {
    height: 104px;
    gap: 24px;
    background: #fbfbfb;
    border-radius: 8px;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border: 1px solid #f1f1f1;
    width: 100%
  }

  .roundCutLeft {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 50%;
    top: 34px;
    left: -18px;
    border: 1px solid #f1f1f1;
    border-left-color: transparent;
    border-top-color: transparent;
    transform: rotate(-45deg);
    transition: box-shadow 0.3s ease;
  }
  .roundCutLeft:hover {
    box-shadow: inset 3px 3px 26px -1px #a2a2a240;
    transition: all 0.3s ease;
  }
  
  .roundCutRight {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 50%;
    top: 34px;
    right: -18px;
    border: 1px solid #f1f1f1;
    border-left-color: transparent;
    border-top-color: transparent;
    transform: rotate(-225deg);
  }

@media screen and (max-width: 900px) {
    .topCarousel{
        display:inline
    }
    .carousel{
        display: none;
    }
  }