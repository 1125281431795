.feedback-container {
    padding: 5px;
    min-width: 320px;
    width: 100%
}

.feedback-page-container {
    /* padding: 30px 20px; */
    border-radius: 20px;
    box-shadow: 2px 2px 8px 3px #7090b02e;
    width: 40%;
    min-width: 360px;
}

.feedback-container .tag-container {
    display: flex;
    /* border-radius: 20px; */
    /* box-shadow: 0 4px 6px #a9e5e580; */
    /* border: solid 1px #a9e5e580; */
    padding-top: 20px;
    flex-wrap: wrap;
}

.feedback-container .tag {
    border-radius: 20px;
    box-shadow: 0 4px 6px #a9e5e580;
    background-color: #a9e5e580;
    border: solid 1px #a9e5e580;
    height: 35px;
    padding: 7px;
    color: black;
    margin-right: 10px;
    font-size: small;
    margin-bottom: 20px;
    cursor: pointer;
    min-width: 125px;
    text-align: center;
}

.feedback-container .tag-selected {
    /* background-color:  var(--theme-color); */
    color: black;
    border-radius: 20px;
    box-shadow: 0 4px 6px #a9e5e580;
    border: solid 1px #a9e5e580;
    height: 35px;
    padding: 7px;
    margin-right: 10px;
    font-size: small;
    margin-bottom: 20px;
    cursor: pointer;
    min-width: 125px;
    text-align: center;
    /* background: linear-gradient(#2BC7C9, #03FACF); */
    background: transparent linear-gradient(90deg, #2EC6C8 0%, #05F7CF 100%) 0% 0% no-repeat padding-box;
}

.feedback-center-line {
    background: gray;
    width: 1px;
    height: 90vh;
    opacity: 0.3;
}

.feedback-side-img {
    width: 70%;
}

.feedback-page-container a {
    text-decoration: underline;
    color: var(--theme-color);
}

.feedback-button {
    position: absolute;
    z-index: 9;
    padding-top: 20px;
    padding-left: 10px;
}

.feedback-modal-title {
    font-size: larger;
}

.feedback-card-header {
    display: flex;
    background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box;
    padding: 0px !important;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 100px;
    align-items: center;
    color: var(----heading-color);
}

.gap {
    height: 50px;
}

.feedback-modal .modal-dialog-scrollable .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0;
    border-radius: 20px;
}

.freedo-primary-button:disabled {
    background: lightgray;
    padding: 9px 28px;
    font-size: 1em;
    border: none;
    color: var(--list-color);
    font-weight: 500;
    opacity: 0.5;
    cursor: auto;
}