/* adding fonts */
@font-face {
  font-family: EuclidCircularB-Bold;
  src: url("../assets/fonts/EuclidCircularB-Bold.ttf");
}
@font-face {
  font-family: EuclidCircularB-BoldItalic;
  src: url("../assets/fonts/EuclidCircularB-BoldItalic.ttf");
}
@font-face {
  font-family: EuclidCircularB-Italic;
  src: url("../assets/fonts/EuclidCircularB-Italic.ttf");
}
@font-face {
  font-family: EuclidCircularB-Light;
  src: url("../assets/fonts/EuclidCircularB-Italic.ttf");
}
@font-face {
  font-family: EuclidCircularB-Regular;
  src: url("../assets/fonts/EuclidCircularB-Regular.ttf");
}
@font-face {
  font-family: EuclidCircularB-Medium;
  src: url("../assets/fonts/EuclidCircularB-Medium.ttf");
}
@font-face {
  font-family: EuclidCircularB-SemiBold;
  src: url("../assets/fonts/EuclidCircularB-SemiBold.ttf");
}
@font-face {
  font-family: EuclidCircularB-SemiBoldItalic;
  src: url("../assets/fonts/EuclidCircularB-SemiBoldItalic.ttf");
}

/* global stylesheet */

:root {
  --primaryColor: #2bc7c9;
  --hColor: #0b132b;
  --pcolor: #1c2541;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: var(--primaryColor);
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 #eeeeee;
  border-radius: 1px;
}
::-webkit-scrollbar-thumb {
  background: #dddddd;
}
::-webkit-scrollbar-thumb:hover {
  background: #dddddd;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  font-family: EuclidCircularB-Regular;
  font-size: 16px;
  overflow-x: hidden;
  position: relative;
  background-color: var(--white);
  color: var(--pcolor);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  color: var(--hColor);
}

p {
  margin: 0;
  padding: 0;
  line-height: normal;
  color: var(--pcolor);
}

.dropShadow {
  box-shadow: 0px 3px 36px 20px rgba(46, 74, 117, 0.08);
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  outline: none;
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  border: 1px solid var(--theme-color);
}

/* button css */

.freedo-btn-hover {
  /* width: 156px; */
  font-size: 16px;
  font-weight: 600;
  color: var(--hColor);
  cursor: pointer;
  /* margin: 10px; */
  /* height: 52px; */
  text-align: center;
  border: none;
  background-size: 300% 100%;
  /* border-radius: 50px; */
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.freedo-btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: var(--hColor);
}

.freedo-btn-hover.FreedocolorOne {
  background-image: linear-gradient(
    90deg,
    rgba(84, 247, 220, 1) 14%,
    rgba(17, 226, 246, 1) 73%
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);

  /* background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
}

.form-check-input:checked {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.btn-primary {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  margin-bottom: 3px;
}
.stickyBox {
  position: sticky;
}
.fixedBox {
  position: fixed;
}
/* button small */

.mainContainer {
  margin-left: 3%;
  margin-right: 3%;
}

.freedo-btn-hover-small {
  font-size: 12px;
  font-family: EuclidCircularB-Medium;
  color: var(--hColor);
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.freedo-btn-hover-small:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: var(--hColor);
}

.freedo-btn-hover-small:focus {
  outline: none;
}

.freedo-btn-hover-small.FreedocolorOne {
  background-image: linear-gradient(
    90deg,
    rgba(84, 247, 220, 1) 14%,
    rgba(17, 226, 246, 1) 73%
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
.error-field-container {
  margin-bottom: 10px;
}
.error-field {
  color: red;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  top: -2px;
  left: 4%;
}
.error-textArea {
  color: red;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  top: -2px;
}
.required-mark {
  color: red;
  font-size: 18px;
}
.theme-color {
  color: "#2ABDBD";
}
.social-links img {
  margin: 0px 3px;
  height: 20px;
}

/*--------- booking vehicle-model Css start------------- */
.bike-sp-wrap h6 {
  margin: 10px 0 5px 0;
}

.bike-sp-wrap{
  margin-top: 70px;
}

h5.specific {
  font-size: 14px;
  position: relative;
  padding-bottom: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5.specific::after {
  position: absolute;
  left: 0;
  width: 33px;
  height: 2px;
  background-color: var(--theme-color);
  content: "";
  bottom: 0;
}

ul.specific-list {
  list-style-type: none;
  padding: 0;
}

ul.specific-list li {
  font-size: 16px;
}

ul.specific-list li span {
  width: 109px;
  display: inline-block;
  padding: 5px 0;
}

ul.specific-list li span:nth-child(even) {
  width: 210px;
  display: inline-block;
  padding: 5px 0;
}

.vehicle-model .carousel-indicators {
  bottom: -74px;
}

.Renting-wrapper .vehicle-wrapper .vehicle-model .carousel-item img {
  margin-bottom: 20px !important;
  height: 200px !important;
  object-fit: contain !important;
  margin-top: 0 !important;
}

.vehicle-model .carousel-indicators .slider-1,
.vehicle-model .carousel-indicators .slider-2,
.vehicle-model .carousel-indicators .slider-3,
.vehicle-model .carousel-indicators .slider-4 {
  border-radius: 5px;
  height: 50px;
  width: 50px;
  background-size: cover;
  margin-top: 10px;
}

.vehicle-model .carousel-control-next-icon,
.vehicle-model .carousel-control-prev-icon {
  display: none;
}

.vehicle-model .carousel-indicators .active {
  border: solid 1px var(--theme-color);
}

.vehicle-model .carousel-indicators [data-bs-target] {
  border-top: solid 1px var(--light-theme);
  border: solid 1px var(--theme-color);
}

.vehicle-model .carousel-indicators .slider-2 {
  background-image: url(../assets/images/bike-1.png);
}

.vehicle-model .carousel-indicators .slider-3 {
  background-image: url(../assets/images/bike-2.png);
}

.name-price-wrap h6 {
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
}
