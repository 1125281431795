.modal-content {
  background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
}

.modal-header {
  border-bottom: none;
  margin: 0;
  padding-bottom: 0;
  margin-bottom: 10px;
  justify-content: center;
}

.custom-modal h5::after {
  position: absolute;
  left: 17px;
  width: 6%;
  top: 40px;
  height: 2px;
  content: "";
  background-color: var(--theme-color);
}

#journyModal .modal-dialog {
  transform: none;
  max-width: 95%;
}

.detect-btn:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all ease 0.3s;
}

.detect-btn-img {
  width: 22px;
  height: 14px;
  object-fit: contain;
  margin: -3px 10px 0 0;
}

h4.modal-title {
  font-weight: 500;
  position: relative;
}

h4.modal-title::after {
  position: absolute;
  left: 22px;
  bottom: 1px;
  width: 13%;
  height: 3px;
  content: "";
  background: transparent linear-gradient(0deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.mid-wrap .verified-use {
  position: absolute;
  top: -4px;
  height: 15px !important;
  left: -6px;
}

.city-model-wrap img {
  height: 130px;
  margin-top: 20px;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
  width: 140px;
}

.city-model-wrap p {
  cursor: pointer;
}

.locate-me-model-wrap .col-md-1 {
  width: 12.5% !important;
}

.signInToggle {
  text-align: center;
  font-size: 12px;
  display: block;
}

.signInToggle .link {
  color: var(--dark-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
}

.hrRow {
  display: flex;
  position: inherit;
  color: #929292;
}

.hrRow hr {
  margin: 10px;
  width: 45%;
}

.privacyPolicy {
  font-size: 12px;
  padding: 0px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.citylist-container {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.citylist-container .col-md-2 {
  padding: 0px;
  margin: 5px 0px !important;
}

.disableHover {
  cursor: no-drop !important;
  border: none !important;
  transition: none !important;
}
.disableHover:hover {
  box-shadow: none !important;
  border: none !important;
  transition: none !important;
}

.active-city {
  background-color: #2bc6c99d !important;
}

/* For React DatePicker Start */
.date-wrap .top-img {
  z-index: 0;
}
.react-datepicker__header {
  background: #fff;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  color: var(--theme-color);
}
.react-datepicker__close-icon {
  color: var(--theme-color);
}
.react-datepicker__day--selected {
  background-color: var(--theme-color);
}
.react-datepicker__close-icon::after {
  background-color: var(--theme-color);
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  color: var(--theme-color);
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
}
.react-datepicker__today-button:hover{
  color: var(--theme-color);
}
.react-datepicker__month-container{
  position: sticky;
  top: auto;
}

.react-datepicker-popper{
  inset: 10px auto 0px 0px !important;
}

/* React DatePicker End */
