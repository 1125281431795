.freedo-city-wrapper {
  background-image: url(../../../assets/images/bg-banner.png);
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-right {
  position: relative;
}

.banner-right img {
  width: 440px;
}

.banner-right .left-content {
  position: absolute;
  top: 28%;
  left: -19%;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 28px;
  padding: 7px 20px;
  font-size: 20px;
  max-width: 38%;
  font-weight: 600;
  box-shadow: 0 15px 19px #bffff9;
  border: solid 10px #ecf9f9;
}

.banner-right .right-content {
  position: absolute;
  top: 20%;
  right: -15px;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 28px;
  padding: 7px 20px;
  font-size: 18px;
  max-width: 43%;
  font-weight: 600;
  box-shadow: 0 15px 19px #bffff9;
  border: solid 10px #ecf9f9;
}

.freedo-city-wrapper h5 {
  margin-bottom: 10px;
}

.freedo-city-wrapper p {
  font-size: 1.25em;
  font-weight: 300;
  margin-bottom: 26px;
}

.freedo-city-wrapper h1 {
  font-size: 3.125rem;
  font-weight: 600;
  margin-bottom: 10px;
  width: 80%;
  padding-right: 10%;
}


.content-wrap {
  position: relative;
  z-index: 1;
}

h2.rental-head {
  position: relative;
  text-align: center;
  margin: 0 0 30px 0;
  padding: 0 0 10px 0;
}

h2.rental-head::after {
  position: absolute;
  content: "";
  width: 116px;
  height: 2px;
  background-color: var(--theme-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

h2.lefthead {
  position: relative;
  margin: 10px 0 20px 0;
  padding: 0 0 10px 0;
}

h2.lefthead::after {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background-color: var(--theme-color);
  left: 0;
  bottom: 0;
}

.hassel-wrap {
  position: relative;
  height: 680px;
  display: flex;
  align-items: center;
  margin: 50px 0 0 0;
}

.hassel-wrap::after {
  position: absolute;
  content: "";
  background-image: url(../../../assets/images/hassel-free.png);
  right: 0;
  height: 680px;
  background-size: cover;
  top: 0;
  z-index: 0;
  width: 100%;
}

.hassel-wrap p {
  color: #707070;
  line-height: 26px;
  margin-bottom: 16px;
}

.hassel-wrap .content-wrap .icons-wrap {
  margin: 0 50px 0 0;
  width: 150px;
}

@media screen and (max-width: 447px){
.hassel-wrap .content-wrap .icons-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-icon{
  justify-content: center;
  align-items: center;
}
}
.hassel-wrap .content-wrap .icons-wrap h3 {
  font-size: 16px;
}

.content-icon {
  margin: 26px 0;
}
.img-container{
    position: relative;
}
.img-bg{
    position: absolute;
    background: rgb(20, 226, 243);
    background: linear-gradient(
      0deg,
      rgba(20, 226, 243, 1) 0%,
      rgba(74, 243, 224, 1) 100%
    );
    height: 400px;
    width: 25%;
    right: 0;
    top: 0;
    z-index: 0;
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.content-wrap img {
  margin-right: 6px;
  background: #adf3f3;
  padding: 14px;
  border-radius: 15px;
  height: 90px;
  margin-bottom: 1px;
}

.renting-fleet {
  background-color: #f5fbfa;
  padding: 50px 0;
  margin: 50px 0 0 0;
}

.rent-card-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rent-card {
  position: relative;
}

.rent-card img {
  z-index: 1;
  position: relative;
  filter: grayscale(1);
  transform: scale(0.8);
  height: 100%;
}

.rent-card.active {
  position: relative;
}

.rent-card.active img {
  z-index: 1;
  filter: grayscale(0);
  transform: scale(0.9);
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
  height: 250px;
}

/* .rent-card.active::after {
  position: absolute;
  content: "";
  background: rgb(20, 226, 243);
  background: linear-gradient(
    0deg,
    rgba(20, 226, 243, 1) 0%,
    rgba(74, 243, 224, 1) 100%
  );
  height: 100%;
  width: 30%;
  right: 0;
  top: 0;
  z-index: 0;
  border-radius: 30px;
  left: 50%;
  transform: translateX(-50%);
} */

.rent-card .brand-name {
  position: absolute;
  background-color: var(--dark-color);
  color: #fff;
  top: 0;
  right: 37%;
  z-index: 1;
  border-radius: 0px 30px 0 30px;
  padding: 20px;
}

.rent-content {
  text-align: center;
  margin-top: 20px;
}

.rent-content h5 {
  padding-bottom: 10px;
}

.city-blog-wrap .card-body {
  padding: 16px 0;
}

.city-blog-wrap .card-body p {
    line-height: 26px;
    margin-bottom: 22px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    min-height: 100px;
}

.city-blog-wrap .card-body .freedo-primary-button {
  padding: 10px 16px;
  font-weight: 500;
}

.city-blog-wrap .card-img-top {
  position: relative;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.city-blog-wrap .card-img-top:after {
  position: absolute;
  content: "";
  /* background: url(../../../assets//images/city/blog-shdow.png); */
  height: 9px;
  width: 100%;
  left: 0;
  right: 0;
}

.featured-icon-wrap {
  width: 30%;
  white-space: nowrap;
  margin: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px #ddd;
  border-radius: 18px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}

.featured-icon-wrap img {
  height: 42px;
  margin-right: 12px;
}

.about-rental-wrap {
  margin: 50px 0;
}

.abt-content p {
  line-height: 26px;
  margin-bottom: 16px;
}

.about-2-head {
  text-align: center;
  padding: 28px 0;
}

.fetured-row {
  margin-bottom: 20px;
}

.abt-detail-content h2 {
  font-size: 20px;
  padding: 12px 0 5px 0;
}

.services-wrap {
  margin: 50px 0 0 0;
}

.services-icon-row {
  position: relative;
}

.services-icon-row::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  background: url(../../../assets/images/service-center.png);
  width: 320px;
  height: 320px;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  bottom: 0;
  align-items: center;
  align-content: center;
}

.services-icons {
  background: #ebfffd;
  padding: 10px;
  border-radius: 10px;
  margin: 24px 0px;
  display: flex;
  align-items: center;
  text-align: left;
}

.services-icons img {
  margin-right: 10px;
  background: #adf3f3;
  width: 62px;
  padding: 10px;
  border-radius: 10px;
  height: 65px;
  object-fit: contain;
}

.head-pop {
  font-size: 20px;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 18px;
  padding-left: 0;
}

.head-pop::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: var(--theme-color);
}

.close-btn-pop {
  position: absolute;
  right: 15px;
  top: 15px;
}

.rental-model {
  position: relative;
  border-radius: 20px;
}

.close-btn-pop button {
  filter: brightness(0) invert(1);
  z-index: 1;
  position: relative;
  opacity: 1;
  padding: 0;
  z-index: 1;
}

.vehicle-brand-name {
  position: absolute;
  background-color: #000;
  right: 0;
  top: 0;
  border-radius: 0 20px 0 20px;
  padding: 20px;
  content: "";
  z-index: 0;
  width: auto;
  color: #fff;
  padding: 36px 44px 36px 24px;
  font-size: 18px;
}

.rental-model .form-control,
.rental-model .form-select {
  border: solid 1px #e6eef2;
  padding: 10px;
  border-radius: 10px;
  border-left: none;
  position: relative;
}

.rental-model .input-group-text {
  background-color: white;
  border-color: #e6eef2;
  border-radius: 10px 0 0 10px;
  position: relative;
}

.rental-model .input-group-text::after {
  position: absolute;
  right: 0;
  content: "";
  height: 22px;
  width: 1px;
  background-color: #e6eef2;
}

.rental-popup-wrap .modal-lg,
.rental-popup-wrap .modal-xl {
  --bs-modal-width: 90%;
  margin: 0 auto;
}

.city-blog-wrap h5.card-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

/* Responsive */
@media screen and (max-width: 1199px) {
  .banner-right .left-content,
  .banner-right .right-content {
    font-size: 18px;
    max-width: 48%;
  }

  .freedo-city-wrapper h1 {
    font-size: 2.375rem;
  }

  .hassel-wrap::after,
  .hassel-wrap {
    height: 560px;
  }

  .featured-icon-wrap {
    width: 40%;
  }
}

@media screen and (max-width: 1107px) {
  .banner-right .left-content,
  .banner-right .right-content {
    font-size: 1rem;
    max-width: 48%;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0 7px 13px #bffff9;
  }

  .banner-right .left-content {
    top: 0;
    left: -41px;
  }

  .banner-right .right-content {
    top: 80%;
    right: 0;
  }

  .services-icon-row::after {
    width: 280px;
    height: 280px;
    top: 10%;
  }
}

@media screen and (max-width: 991px) {
  .services-icon-row::after {
    width: 250px;
    height: 250px;
    top: 15%;
  }

  .services-icons img {
    width: 55px;
    height: 60px;
  }

  .hassel-wrap::after {
    height: 504px;
  }

  .content-wrap img {
    height: 70px;
  }

  .hassel-wrap .content-wrap .icons-wrap h3 {
    font-size: 14px;
  }

  .hassel-wrap {
    align-items: start;
    height: auto;
  }

  .rental-popup-wrap .modal-lg,
  .rental-popup-wrap .modal-xl {
    --bs-modal-width: 98%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 850px) {
  .hassel-wrap::after {
    height: 416px;
  }
  .img-bg {
    height: 400px;
    width: 35%;
}
.rent-card .brand-name{
    right: 32%;
}
}

@media screen and (max-width: 767px) {
  .freedo-city-wrapper h1 {
    font-size: 1.875rem;
    width: 100%
  }

  .freedo-city-wrapper p {
    font-size: 1rem;
  }

  .banner-right .left-content,
  .banner-right .right-content {
    max-width: 100%;
  }

  .freedo-city-wrapper p br {
    display: none;
  }

  .services-icon-row::after {
    display: none;
  }

  .services-icons {
    margin: 10px;
  }

  .services-icons {
    padding: 4px;
    margin: 10px;
  }

  .hassel-wrap::after {
    height: 400px;
  }

  .hassel-wrap .content-wrap .icons-wrap {
    margin: 0 26px 0 0;
    width:auto
  }

  .hassel-wrap .content-wrap .icons-wrap h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .services-icons {
    font-size: 14px;
  }

  .faq-wrapper .faq-wrap .accordion {
    padding: 20px;
  }

  .faq-wrapper {
    background-size: cover;
  }

  .faq-wrap {
    padding: 0;
  }

  h2.rental-head {
    margin: 0 0 15px 0;
    padding: 0 0 10px 0;
  }

  .abt-content .abt-dist {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }

  .featured-icon-wrap {
    width: 46%;
  }

  .rental-model .renting-modal {
    flex-direction: column-reverse;
  }

  .rental-model .modal-body {
    padding: 20px 40px;
  }

  .faq-showmore {
    margin: 0px 0 0 0;
  }
  .rent-card .brand-name{
    right: 0%;
  }
  .rent-card.active img{
    height: 100%;
  }
}

@media screen and (max-width: 680px) {
  .freedo-city-wrapper h1 {
    font-size: 1.625rem;
  }

  .banner-right .left-content,
  .banner-right .right-content {
    padding: 10px;
    font-size: 14px;
  }

  .hassel-wrap .content-wrap .icons-wrap {
    margin: 0 20px 0 0;
  }

  .hassel-wrap::after {
    height: 300px;
  }
}

@media screen and (max-width: 575px) {
  .freedo-city-wrapper .row {
    flex-direction: column-reverse;
  }

  .banner-right {
    position: relative;
    text-align: center;
  }

  .banner-right .left-content {
    top: 0;
    left: 0;
  }

  .freedo-city-wrapper {
    text-align: center;
  }

  .freedo-city-wrapper h5 {
    margin-top: 24px;
  }

  .banner-right img {
    width: 350px;
  }

  .services-wrap,
  .hassel-wrap,
  .renting-fleet,
  .faq-wrapper {
    margin: 30px 0 0 0;
  }

  .renting-fleet {
    padding: 30px 0;
  }

  .hassel-wrap::after {
    display: none;
  }

  .featured-icon-wrap {
    width: 100%;
  }

  .rental-model h4 {
    font-size: 18px;
  }

  .city-blog-wrap .blog-card-box {
    width: 90%;
    max-width: 90%;
  }

  .content-icon {
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }



  .freedo-city-wrapper h1,
  h2.rental-head,
  h2.lefthead,
  .about-2-head,
  .abt-detail-content h2 {
    font-size: 1.25rem;
  }

  h2.rental-head::after{
    display: none;
  }
  h2.lefthead::after{
    display:none
  }

}

@media screen and (max-width: 414px) {
  .banner-right .left-content,
  .banner-right .right-content {
    box-shadow: 0 3px 9px #bffff9;
  }

  .banner-right .left-content {
    top: -32px;
    left: -9px;
  }
  .img-bg {
    position: absolute;
    background: transparent;
    height: 400px;
    width: 35%;
    right: 0;
    top: 0;
    z-index: 0;
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
}
}

.faqHeader {
  display:flex;
  justify-content:center;
   align-items:center;
   flex-direction:column
}
