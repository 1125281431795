.subscriptionOfferCards {
  height: 104px;
  gap: 24px;
  background: #fbfbfb;
  border-radius: 8px;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  border: 1px solid #f1f1f1;
  width: 100%;
}

.subscriptionOfferCards:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all 0.3s ease;
}

.customerReviewCard {
  width: 265px;
  /* box-shadow: 0px 0px 8px 0px #00000014; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  padding: 20px;
}

.customerReviewDesc {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.emojiBox {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eff0f0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: -13%;
}

.YORHeadingContent {
  display: flex;
  width: 100%;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

.customerReviewBox {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.yorCarouselBox {
  width: 603px;
  padding: 40px 20px;
  gap: 24px;
  border-radius: 4px 56px 56px 56px;
  border: 3px 0px 0px 0px;
  background: linear-gradient(
    102.05deg,
    rgba(159, 245, 246, 0.24) 4.68%,
    rgba(255, 255, 255, 0.24) 93.22%
  );
  border: 3px solid;
  border-color: rgba(255, 255, 255, 0.24);
  /* border-image-source: linear-gradient(
    257.74deg,
    #390a1d 0%,
    rgba(236, 19, 19, 0.24) 98.24%
  ); */
  backdrop-filter: blur(8px);
  box-shadow: 8px 8px 80px 0px #00000029;
}

.yorCarouselBoxTitleContentVector {
  width: 110px;
  height: 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid #305d61;
}

.yorCarouselIndicatorsContainer {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 4% !important;
  border-radius: 20px;
  background-color: #0000008f;
  border: 1px solid #ffffff;
}

.rentingFleetContainer {
  top: -67.85px;
  left: -0.06px;
  border: 1px 0px 0px 0px;
  border: 1px solid #ededed;
  background: #ffffff;
}

.rentingFleetcard {
  width: 300px;
  height: 390px;
  min-width: 300px;
  border-radius: 8px !important;
  background: #ffffff;
  /* border: 1px solid #ffffff; */
  box-shadow: 0px 0px 16px 1px #00000014 !important;
}

.rentingFleetcardOverlay {
  background: #ffffff;
  padding: 3px 6px;
  gap: 8px;
  border-radius: 0px 0px 8px 0px;
  border: 1px 0px 0px 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  color: #305d61;
  max-width: 210px;
}

.rentingCardFeature {
  width: 100px;
  height: 32px;
  padding: 8px 4px;
  gap: 1px;
  border-radius: 22px;
  background: #ffffff;
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileFiltersFleet {
  display: none;
}

.mobileViewCrousel {
  display: none;
}

.poweredbyFreedo {
  width: 335px;
  height: 19px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.yorCrouselOne {
  height: 455px;
  width: 100%;
  border-radius: 0px;
  background-repeat: no-repeat;
  box-shadow: none;
  padding: 60px;
}

.mobileViewDateCityPicker {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.mobileFiltersModal {
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 10px;
  width: 100%;
}

.youCityButton {
  width: none !important;
  border: none;
  background: transparent;
  padding: 8px 13px;
  color: #01353d;
}

.youCityButton:hover {
  background-color: #2abdbd;
  padding: 8px 13px;
  border-radius: 21px;
  color: white;
  transition: all 0.2s ease-in;
}

.cityCard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-repeat: no-repeat;
  height: 140px;
  width: 130px;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.cityCard:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
  transition: all 0.3s ease;
}

.cityCardUpcoming {
  width: 90px;
  position: absolute;
  left: -5px;
  height: 20px;
  top: 0px;
}

.cityCardNew {
  width: 76px;
  position: absolute;
  left: -8px;
  top: 0px;
  height: 20px;
}

.cityNameCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectcityUnderline {
  background: linear-gradient(0deg, #59f7da, #18e3f2);
  border-radius: 5px;
  height: 3px;
  width: 40%;
}

.mobilefilterHeading {
  height: 100%;
  width: 50%;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.mobileSortHeading {
  width: 50%;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.mobileSortContainer {
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 100%;
  height: 260px;
  background-color: #ffffff;
  gap: 2px;
  max-width: 480px;
}

.mobileSortChipContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.filtersSectionscontainer {
  display: flex;
  padding: 20px;
  gap: 8px;
  border-top: 1px solid #ededed;
}

.rentingFleetFiltersBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.rentingFleetBoxContent {
  width: 100%;
  padding: 25px 32px;
  gap: 20px;
  background-repeat: no-repeat;
  background-position: right top;
}

.optReasonCard {
  width: 242px;
  height: 113px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 26px -1px #ffffff !important;
  padding: 10px;
  background-size: cover !important;
  border-radius: 17px !important;
  background-position: cover !important;
}

.optReasonCard:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240 !important;
  transition: all 0.3s ease;
}

.getStartedYorBox {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px 44px 16px 16px;
  border-radius: 4px;
  width: 100%;
  background-color: white;
  transition: "background-color 0.3s ease";
}

.getStartedYorBox:hover {
  background-color: #325f63;
}

.alignJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqsBox {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commaIconStyle {
  width: 44px;
  height: 44px;
  position: absolute;
  top: -21px;
  left: -18px;
}

.customerReviewCardBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customerReviewCardBottomBox {
  width: 180px;
  border-radius: 0px 0px 6px 6px;
  height: 11px;
  background-color: #e8e8e8;
  margin-top: 1px;
}

.selectCityUnderline {
  width: 40%;
  background: transparent linear-gradient(0deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  height: 3px;
  border-radius: 5px;
}

.roundCutLeft {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  top: 34px;
  left: -18px;
  border: 1px solid #f1f1f1;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
  transition: box-shadow 0.3s ease;
}
.roundCutLeft:hover {
  box-shadow: inset 3px 3px 26px -1px #a2a2a240;
  transition: all 0.3s ease;
}

.roundCutRight {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  top: 34px;
  right: -18px;
  border: 1px solid #f1f1f1;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-225deg);
}

.customerTestimonials {
  display: flex;
  position: relative;
  background-color: #f9f9f9;
  padding: 15px 0px;
}

.customerTestimonialsTab {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  padding: 15px 0px;
}

.customerTestimonialsMobile {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  padding: 15px 0px;
}

.rentingFleetFiltersBoxcrossIcon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

@media screen and (max-width: 1275px) {
  .customerTestimonials {
    display: none;
  }

  .customerTestimonialsTab {
    display: flex;
  }
}

@media screen and (max-width: 675px) {
  .customerTestimonials {
    display: none;
  }

  .customerTestimonialsTab {
    display: none;
  }

  .customerTestimonialsMobile {
    display: flex;
  }
}

@media screen and (max-width: 900px) {
  .mobileFiltersFleet {
    width: 100%;
    display: flex;
    height: 44px;
    border: 1px 0px 0px 0px;
    background: #ffffff;
    border-top: 1px solid #ececec;
    position: fixed;
    bottom: 0;
    justify-content: space-around;
    align-items: center;
  }

  .mobileViewCrousel {
    display: flex;
    padding: 24px 24px 40px 24px;
    gap: 16px;
    border: 3px 0px 0px 0px;
    background: -webkit-linear-gradient(
      102.05deg,
      rgba(159, 245, 246, 0.24) 4.68%,
      rgba(255, 255, 255, 0.24) 93.22%
    );
    border: 3px solid;
    border-image-source: -webkit-linear-gradient(
      257.74deg,
      #b4d5e5 0%,
      rgba(255, 255, 255, 0.24) 98.24%
    );
    box-shadow: 8px 8px 80px 0px #00000029;
    backdrop-filter: blur(8px);
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  .rentingFleetcard {
    width: 200px;
  }

  .rentingFleetContainer {
    width: 200px;
  }
}

@media screen and (max-width: 900px) and (min-width: 480px) {
  .mobileSortContainer {
    right: 0px;
    border-top-left-radius: 12px;
  }
}

@media screen and (max-width: 330px) {
  .mobileSortChipContainer {
    justify-content: center;
  }
}
