.responseForm_Wrapper {
  background: linear-gradient(102.05deg,
      rgba(159, 245, 246, 0.24) 4.68%,
      rgba(255, 255, 255, 0.24) 93.22%);
  /* padding: 24px 0px 0px 0px; */
  padding: 24px;
  gap: 17px;
  border-radius: 0px 24px 24px 24px;
  border: 7px solid;
  /* border-image: linear-gradient(
      257.74deg,
      #b4d5e5 0%,
      rgba(255, 255, 255, 0.24) 98.24%
    ); */
  border-color: #fff;
  box-shadow: 8px 8px 80px 0px #00000029;
  backdrop-filter: blur(8px);
}

.responseform_innerWrapper {
  padding: 8px 11px 8px 16px;
  gap: 16px;
  border-radius: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px #10182829;
}
