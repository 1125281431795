.otpContainer {
    margin: 5% auto;
    display: flex;
    padding: 10px;
    justify-content: center;
}

.otpInput {
    width: 2.5rem;
    height: 2.5rem;
    margin: 2px 4px;
    font-size: 0.9rem;
    border-radius: 4px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

/* skelaton css start  */
.skelaton-ride {
    padding: 0px 10px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 10px;
    width: 100%;
    margin: 0px 10px;
}

.imgsection {
    display: flex;
    justify-content: center;
}

.skelaton-ride .complete-ride {
    box-shadow: none;
}

.booking-detail-skelaton {
    margin-right: 45px;
}

.booking-detail-skelaton .mr-5 {
    margin-right: 60px;
}

.booking-detail-skelaton .end-ride {
    display: flex;
}

.booking-detail-skelaton .end-ride span {
    padding: 0px 10px;
}

.booking-detail-skelaton .ml-2 {
    margin-left: 20px;
}


.show-more {
    font-size: 14px;
    cursor: pointer;
    color: var(--theme-color);
}

/* skelaton css end */