:root {
  --dark-color: #0b132b;
  --theme-color: #2bc7c9;
  --light-theme: #f5feff;
  --light-theme-grey: #fbfbfb;
  --heading-color: #021325;
  --sub-text-color: #242b35;
  --list-color: #727272;
  --red: #ff3300;
  --white: #fff;
  --small-text: 12px;
  --main-spacing-top: 40px;
  --success: #51b74e;
}
/* booking-details css start */

.Booking-details-wrapper {
  margin: 20px 0;
}

.Booking-details-wrapper h2 {
  position: relative;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 20px;
}

.booking-status-wrapper {
  border: solid 1px #dddddd;
  padding: 8px 20px;
  border-radius: 10px;
}

.booking-status-wrapper a.freedo-outline-btn-small {
  font-size: 15px;
}

.booking-status-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
}

.booking-status-btn {
  margin: 0.7rem 0.5rem 0 0.5rem;
}

.booking-status-btn .modify-booking-btn {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
  color: var(--dark-color);
  border: none;
  padding: 6px 15px;
}

.booking-status-btn .modify-booking-btn:hover {
  box-shadow: 3px 3px 26px -1px #a2a2a240;
}

.cancle-ride-btn-modify a {
  padding-right: 15px;
  text-decoration: underline;
  color: var(--red);
}
.need-help-btn-container{
justify-content: end;
position: absolute;
right: 2%;
top: 8%;
}
.need-help-btn a {
  text-decoration: underline;
  color: var(--theme-color);
}
.booking-status-policy a {
  font-size: 0.8em;
}

.booking-status-policy a:hover {
  text-decoration: underline;
}

.booking-bike-wrap {
  display: flex;
  align-items: center;
}

.booking-bike-model {
  width: 30%;
}

.booking-bike-model img {
  margin-top: -25px;
}

.bike-model-details {
  margin-left: 30px;
  font-size: 15px;
}

.bike-model-details h6 {
  margin: 0;
  font-size: 15px;
}

.bike-model-details .bike-name {
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0 0 0;
}

.plan-days {
  font-weight: 500;
}

.booking-status-bottom {
  border: solid 1px #dddddd;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  min-height: 300px;
  position: relative;
}

.booking-id-first {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.Upcoming-ride-btn {
  background: var(--light-theme);
  position: absolute;
  right: 4px;
  top: 5px;
  padding: 5px 10px;
  border-radius: 38px;
  font-size: 12px;
  color: white;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px;
}

.booking-id-wrap h6 {
  font-size: 14px;
  color: var(--list-color);
}

.booking-id-wrap h4 {
  font-size: 15px;
  color: var(--theme-color);
}
.booking-id-wrap span {
  color: var(--white);
}

.booking-id-mid {
  margin: 5px 0 0 0;
  font-size: 12px;
  color: var(--list-color);
}

.booking-id-mid h4 {
  font-size: 12px;
  color: var(--dark-color);
  padding: 4px 0px 16px 0;
}

.booking-id-last a {
  margin: 0 10px;
  font-size: 15px;
}

.booking-detailsfaq {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.booking-detailsfaq .accordion-item {
  border: none;
  margin: 0px;
  box-shadow: none;
  border-radius: 0;
  padding: 5px 5px 0 5px;
}

.booking-detailsfaq .accordion-header::after {
  position: absolute;
  background-color: transparent;
  width: 20px;
  height: 2px;
  content: "";
  left: 0;
  bottom: 9px;
  z-index: 9;
}

.booking-detailsfaq h2 {
  position: relative;
  display: block;
  font-weight: 400;
  margin-bottom: 0;
  background: transparent;
  font-weight: 500;
}

.booking-detailsfaq .accordion-body {
  background: transparent;
  border-radius: 0;
  padding: 15px 15px 0 15px;
  font-size: 15px;
}

.booking-detailsfaq .accordion-button.collapsed {
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 15px;
  color: var(--dark-color);
  box-shadow: none;
  position: relative;
  border-bottom: solid 1px #ddd;
  font-weight: 500;
  font-size: 14px;
}

.booking-detailsfaq .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-radius: 0;
  padding: 15px;
  box-shadow: none;
  color: var(--dark-color);
  border-bottom: solid 1px #ddd;
  font-weight: 500;
  font-size: 14px;
}

.booking-detailsfaq .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.address-icon {
  color: var(--theme-color);
  margin: 0 8px 0 0;
}

.booking-detailsfaq .accordion-body .space-top {
  margin-top: 20px;
}

/* Extend-model */
.extend-ride-wrapper .modal-body {
  padding: 16px 16px 30px 16px;
}

.extend-ride-wrapper .modal-header h5 {
  margin: 0;
}

.extent-ride-body-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
  padding-bottom: 8px;
}
@media screen and (max-width: 1200px) {
  .extent-ride-body-wrap {
    flex-direction: column;
  }
}
.extend-ride-form .form-select {
  font-size: 14px;
  font-weight: 400;
}

.extent-ride-details p {
  font-size: 16px;
}

.extent-ride-details span {
  font-weight: 500;
  padding-left: 5px;
  font-size: 16px;
}

.extent-ride-details span.idbook {
  color: var(--theme-color);
}

.extent-ride-details span.status-ongoing {
  color: #67e2ac;
}

.odometer-warning {
  position: relative;
  color: #fc2828;
  margin-bottom: 20px;
}

.odometer-warning span {
  position: absolute;
  font-size: 0.76em;
}

.confirm-package {
  opacity: 0.4;
}

.confirm-package.active {
  opacity: 1;
}

.modal-header .btn-close {
  font-size: 12px;
}

/* ENd-ride popup */
.mode-wrap {
  display: flex;
  align-items: center;
}

.mode-wrap h6 {
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 0;
}

.mode-wrap .form-check {
  margin: 0 20px;
}

.end-ride-slot .time-slot .nav-link {
  margin: 0;
}

.time-note {
  font-size: 12px;
  font-style: italic;
  text-align: left;
  padding: 5px 0;
  margin: 0;
}

.saved-address {
  margin: 10px 0;
  font-size: 15px;
  display: flex;
  align-items: start;
}

.saved-address h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 15px;
}

.address-box-wrap {
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.time-slot-details ul.nav.nav-tabs.time-slot {
  margin: 10px 0;
  border-bottom: none;
}

.end-ride-slot .timing-sets {
  justify-content: normal;
  margin: 0 !important;
}

.address-row {
  overflow-x: auto;
  margin-top: -20px;
}

.address-row i {
  margin-top: -23px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  top: -12px;
  box-shadow: 1px 1px 8px 0px #2bc7c94f;
}

.address-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px 0 0 20px !important;
}

.address-top h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.title-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 10px 0 !important;
}

.address-top h6 {
  margin-bottom: 0;
}

a.address-box {
  width: 30%;
  border-radius: 5px;
  border: solid 1px #d1f3f3;
  padding: 10px;
  margin: 0 15px 20px 0;
  background: #fff;
  color: var(--dark-color);
}

a.address-box.selected {
  border: solid 1px var(--theme-color);
}

.address-box p {
  margin: 5px 0 0 20px;
  font-size: 14px;
  line-height: 22px;
  width: 75%;
}

/* Booking-status */
.booking-status-wrapper .modal-header {
  border-bottom: none;
}

.booking-status-wrapper h5 {
  position: relative;
}

.booking-status-wrapper h5::after {
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 30%;
  height: 2px;
  content: "";
  background-color: var(--theme-color);
}

.booking-status-box {
  width: 30%;
  text-align: center;
  z-index: 1;
  position: relative;
}

.booking-status-box img {
  margin: 0 0 20px 0;
}

.booking-status-box p {
  font-size: 14px;
  margin: 0;
}

.modal-body .booking-status-steps {
  display: flex;
  justify-content: space-between;
  white-space: normal;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 20px;
}

.booking-status-image-wrap.active {
  position: relative;
}

.booking-status-image-wrap.active::after {
  position: absolute;
  content: "";
  width: 75%;
  background-color: var(--theme-color);
  height: 1px;
  left: 73%;
  top: 65%;
  z-index: -1;
  border: none;
}

.booking-status-image-wrap {
  position: relative;
}

.booking-status-image-wrap::after {
  position: absolute;
  content: "";
  width: 75%;
  height: 1px;
  left: 76%;
  top: 55%;
  z-index: -1;
  border: none;
  border: dashed 1px #000;
}

.tick-yes {
  position: absolute;
  right: 25%;
  top: 13%;
  height: 25px;
}

.booking-status-image-wrap.no-bdr::after {
  border: none;
  content: "";
  position: absolute;
  display: none;
}

.toggled-on .toggle-content {
  display: block;
  margin-top: 0;
  padding: 15px;
  border-radius: 10px;
}

.refund-staus-details {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
}

.refund-staus-details h6 {
  margin: 0;
}

.refund-staus-details p {
  color: #95989a;
  padding: 8px 0 0 0;
  font-size: 17px;
}

.refund-staus-details img {
  height: auto !important;
  margin-right: 10px !important;
}

.refund-staus-icon {
  position: relative;
}

.refund-staus-icon.active::after {
  position: absolute;
  content: "";
  height: 55%;
  width: 2px;
  background-color: var(--theme-color);
  left: 40%;
  top: 68%;
  border: none;
}

.refund-staus-icon::after {
  position: absolute;
  content: "";
  height: 55%;
  width: 2px;
  border: dashed 1px #000;
  left: 40%;
  top: 68%;
}

.refund-staus-icon.nobdr::after {
  border: none;
}

.toggled-on .fa-angle-down {
  display: none;
}

.toggle-title {
  position: relative;
  cursor: pointer;
}

.toggle-title a {
  text-decoration: underline;
  font-size: 16px;
}

.toggle-title i {
  position: absolute;
  right: 24px;
  font-size: 20px;
  color: var(--theme-color);
  top: 4px;
}

.toggled-off .toggle-content {
  display: none;
}

.toggled-off .fa-angle-up {
  display: none;
}

.end-bookingstatus-bottom {
  margin: 10px 0;
  width: 100%;
}

.end-bookingstatus-bottom i {
  margin-right: 5px;
}

.Vehicle-Price-Details .table-responsive .table td {
  font-size: var(--small-text);
}

.Vehicle-Price-Details .table-responsive .table td:nth-child(2) {
  font-weight: 500;
  text-align: right;
}

.Vehicle-Price-Details .table-responsive .table td:nth-child(2) a .fas {
  font-size: 18px;
}

.Vehicle-Price-Details .amount-head {
  font-weight: 500;
  padding: 10px 0 0 0;
}

.Vehicle-Price-Details .total-amount {
  color: var(--theme-color);
  padding: 10px 0 0 0;
}

.delivery-address-details-wrap {
  display: flex;
  align-items: start;
}

.delivery-address-details-wrap #map-view {
  width: 80%;
  border-radius: 15px;
  height: 130px;
}

.delivery-address-details-wrap .details {
  margin-left: 20px;
  font-weight: 600;
}

.delivery-address-details-wrap .details p {
  font-weight: 400;
  margin: 10px 0;
  font-size: 14px;
}

.extend-Price-Details .table-responsive .table td {
  font-size: var(--small-text);
}

.extend-Price-Details .table-responsive .table td:nth-child(2) {
  font-weight: 500;
  float: right;
}

.extened-coupon-btn {
  background: transparent;
  border: none;
  color: var(--theme-color);
}

.paid-extra thead th p {
  font-weight: 600;
  font-size: 12px;
}
.tr-bottom-border {
  border-bottom: solid 1px #ddd;
}
#coupon-selected .offcanvas-header {
  background-color: var(--theme-color);
  color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 0 20px 5px 20px;
  display: block;
}

#coupon-selected .offcanvas-header .offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
  font-size: 1rem;
  padding: 10px 0px;
  color: white;
}

#coupon-selected .offcanvas-header .btn-close i {
  font-size: 20px;
  color: #fff;
}

#coupon-selected .offcanvas-header .btn-close {
  background: none;
  opacity: 1;
  float: right;
}

.searchbox-wrap {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.searchbox-wrap input {
  flex: 1;
  padding: 10px 15px;
  font-size: 1.1em;
  background-color: #f4f7f8;
  box-shadow: none;
  border: solid 1px #e6eef2;
  border-right: none;
  border-radius: 10px 0 0 10px;
}

.searchbox-wrap button {
  padding-right: 15px;
  background-color: #f4f7f8;
  cursor: pointer;
  cursor: hand;
  border-radius: 0 10px 10px 0px;
  border: solid 1px #e6eef2;
  border-left: none;
}

.searchbox-wrap button span {
  margin-left: 50px;
  font-weight: 300;
  color: var(--theme-color);
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
}

.coupon-box-wrap {
  border: solid 1px #efefef;
  padding: 10px 15px;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
}

.cpn-selected {
  border: 1px dashed #2bc7c9;
  padding: 4px 10px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  background-color: aliceblue;
}

.coupon-box-wrap.selected-cpn {
  border: solid 1px var(--theme-color);
}

.coupon-content {
  margin-left: 25px;
}

.coupon-content .coupn-name {
  background: var(--light-theme);
  display: inline-block;
  padding: 5px 10px;
  border: dashed 1px var(--theme-color);
  letter-spacing: 2px;
  border-radius: 5px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.coupon-icon-sec {
  width: 19%;
}

.coupon-box-wrap .left {
  display: flex;
  width: 56%;
}

.coupon-content .coupn-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.coupon-content p {
  font-weight: normal;
  font-size: 14px;
  color: #949392;
  margin: 0;
}

.apply-sec a {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  /* padding-bottom: 20px; */
  display: block;
}

.save-code {
  color: var(--success);
  font-size: 0.99em;
}

.applycoupon-btn {
  width: 100%;
  margin: 10px 0 0 0;
}

.total-amount-wrap {
  border-top: solid 1px #ddd;
  margin: 10px 0 0 0;
}

.read-more-cpn .card-body {
  font-size: 12px;
  margin-top: -18px;
  border-color: #e6eef2;
  padding: 5px 5px;
}

/*------------------- REsponsive -------------------*/
@media screen and (max-width: 1280px) {
  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 30%;
  }

  .day-select-filter .form-select {
    padding: 0.675rem 3.25rem 0.675rem 0.975rem;
  }

  .detect-filter .form-control {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1200px) {
  /* Home */
  .slider-content h1 {
    font-size: 3.125em;
  }

  .slider-content p {
    font-size: 1.125em;
  }

  .slider-img-wrap {
    width: 210px;
  }

  .slider-img-wrap video,
  .slider-img-wrap img.slide-3 {
    height: 190px;
  }

  .slider-img-wrap img {
    height: 150px;
  }

  .slider-right-wrap .top-content,
  .slider-right-wrap .bottom-content {
    font-size: 1em;
  }

  .features-content {
    overflow-x: auto;
  }

  .features-content .card {
    width: 220px;
    margin: 0 10px 23px 5px;
  }

  .time-box {
    width: 142px;
  }

  .mid-wrap.time-wrap {
    height: 145px;
    overflow-y: auto;
  }

  .time-box {
    padding: 6px 16px 6px 5px;
    width: 102px;
    margin: 7px;
  }

  .deposite-wrap {
    flex-wrap: wrap;
    margin: 15px 0 0 0;
  }

  .deposite-wrap span {
    margin-bottom: 10px;
  }

  p.price {
    font-size: 0.995em;
  }

  .deposite-wrap span i {
    padding: 0 8px;
  }

  /* ------Rent ------*/
  .filter-offcanvasbtn {
    display: none;
  }

  .filter-row {
    flex-wrap: wrap;
  }

  .rent-search-filter {
    margin: 10px 0;
  }

  .date-filter {
    width: 55%;
    margin-bottom: 20px;
  }

  .detect-filter {
    width: 40%;
    margin-bottom: 20px;
  }

  .vihicle-type-box {
    justify-content: start;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .filter-list-wrap .form-check {
    margin: 10px 14px 0 0;
  }

  /* List */
  .list-view-wrap {
    width: 25% !important;
    padding: 8px 10px !important;
  }

  /* Booking-details */
  .extent-ride-details {
    font-size: 14px;
    margin-right: 45px;
  }

  .extend-ride-form h6 {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .contact-wrap {
    text-align: center;
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    color: var(--white);
  }

  .offcanvas.offcanvas-end .navbar-nav {
    margin: 30px 0 0 0 !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 15px 25px;
  }

  .login-wrap {
    margin-left: 25px;
    margin-top: 15px;
  }

  .main-content-wrapper {
    margin: 0 20px;
  }

  .container-fluid {
    padding: 0 20px;
  }

  .Info-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Slider */
  .slider-img-wrap {
    width: 185px;
  }

  .slider-content h1 {
    font-size: 2.5em;
  }

  .slider-wrapper .col-12 {
    padding: 0;
  }

  .slider-content p {
    font-size: 0.875em;
  }

  .light-freedo-btn {
    padding: 10px 10px;
  }

  .light-freedo-btn {
    padding: 10px 10px;
    background-color: #fafafa;
    font-size: 1em;
    border: none;
  }

  .location-wrap input {
    padding-left: 105px;
  }

  .slider-right-wrap .top-content {
    top: 10px;
    right: 0;
    font-size: 0.9375em;
  }

  .slider-right-wrap .bottom-content {
    bottom: 20px;
    left: 0;
    font-size: 1em;
  }

  .slider-right-wrap .top-content .fa-solid.fa-circle-check,
  .slider-right-wrap .bottom-content .fa-solid.fa-circle-check {
    font-size: 28px;
    margin: 0 10px;
    color: var(--dark-color);
  }

  .Renting-wrapper .nav-pills .nav-link {
    color: var(--dark-color);
  }

  .Renting-wrapper .card {
    width: 300px;
    margin-bottom: 20px;
  }

  .product-details img.bike-right {
    max-width: 100%;
  }

  .details-wrapper .vr {
    margin: 0 22px;
  }

  /*-------------------- booking journey --------------------*/
  .journey-btn {
    left: 19%;
    right: 20%;
  }

  .time-box {
    width: 100%;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 32%;
    padding-bottom: 10px;
  }

  .mid-wrap {
    height: 126px;
    width: 126px;
  }

  .mid-wrap img {
    max-width: 100%;
    height: 100%;
  }

  .time-box {
    margin: 4px 7px;
  }

  .time-box {
    height: 40px;
    padding-left: 15px;
  }

  .mid-wrap.time-wrap {
    height: 126px;
  }

  .Journey-wrap p {
    margin-bottom: 1em;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 28px;
  }

  .step-process {
    top: 40px;
  }

  .modal-dialog-centered {
    margin: 30px 20px;
  }

  .freedo-primary-button,
  .freedo-outline-btn {
    font-size: 0.875em;
    padding: 8px 20px;
  }

  .freedo-primary-button-lg {
    padding: 10px 45px;
  }

  .progressbar-wrap {
    font-size: 0.8375em;
  }

  .progressbar {
    width: 60px;
  }

  .progressbar span.active::after {
    width: 50px;
  }

  .mid-wrap.datepicker {
    width: 200px;
  }

  /*  .blog-wrap .card .card-img-top {
    height: 250px;
  } */

  .Renting-wrapper .nav-pills .nav-link {
    margin: 0px 0;
  }

  ul.loacton-list {
    white-space: nowrap;
    overflow-x: auto;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 10px 0 0;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 185px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img {
    height: 150px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img.slide-3,
  .innerpage-slider-right-wrap .slider-img-wrap img.slide-2 {
    height: 190px;
  }

  .innerpage-img-head h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  .inner-bottom-text {
    margin-right: 20px;
  }

  .filter-row .form-control {
    font-size: 0.9375em;
    font-weight: 300;
  }

  .rent-filter-result .vehicle-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 45%;
  }

  .faq-banner-wrap {
    height: 100px;
  }

  footer p {
    padding: 0px 0;
  }

  .change-address-wrapper .offcanvas-body {
    background: #fff;
  }

  /* Booking-details */
  #Booking-Status .modal-dialog {
    max-width: 92%;
    margin-right: auto;
    margin-left: auto;
  }

  .extend-ride-wrapper .modal-dialog {
    max-width: 92%;
  }

  #EndrideModal .modal-dialog {
    max-width: 92%;
  }

  .booking-status-box img {
    margin: 0 0 20px 0;
  }

  .toggle-title a {
    text-decoration: underline;
    font-size: 15px;
  }

  .toggle-title i {
    right: 0;
    font-size: 15px;
    top: 7px;
  }

  .refund-staus-details h6 {
    font-size: 14px;
  }

  .refund-staus-details p {
    color: #95989a;
    padding: 3px 0 0 0;
    font-size: 15px;
  }

  .booking-status-box {
    width: 40%;
  }

  a.address-box {
    width: 40%;
    border-radius: 5px;
    border: solid 1px #d1f3f3;
    padding: 10px;
    margin: 0 15px 10px 0;
    background: #fff;
    color: var(--dark-color);
  }

  .address-box p {
    margin: 8px 0 0 20px;
    font-size: 13px;
  }

  #coupon-selected .offcanvas-body {
    background-color: #fff;
  }

  .delivery-address-details-wrap #map-view {
    width: 25%;
  }
}

@media screen and (max-width: 850px) {
  :root {
    --main-text: 14px;
    --main-heading: 20px;
  }
  /* 
  .apply-coupon a {
    border: solid 1px var(--theme-color);
    color: var(--theme-color);
    padding: 6px 15px;
    border-radius: 31px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  } */

  .apply-coupon {
    flex-wrap: wrap;
  }

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .helmate-wrap form p {
    margin: 0;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
    margin: 10px 0;
  }

  .value-button {
    width: 100% !important;
  }

  input#number {
    width: 100% !important;
  }

  .time-slot-details .timing-sets {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  /*-------------------- booking journey --------------------*/

  .journey-btn {
    left: 9%;
    right: 9%;
  }

  .mid-wrap {
    margin: 0 auto;
    font-weight: 500;
  }

  .mid-wrap img {
    height: 125px;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 0px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 165px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 0px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 10px;
  }

  .mission-wrapper .content-wrap p {
    margin: 10px 0 15px 0;
  }

  .mission-wrapper .content-wrap h6 {
    margin-bottom: 30px;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }

  .day-select-filter {
    width: 80%;
  }

  .steps-inner-wrap {
    justify-content: start;
    overflow-x: auto;
  }

  /* ---------------Rent-page--------------- */
  .vehicle-wrapper .card a {
    margin-top: 20px;
  }

  .name-price-wrap {
    flex-wrap: wrap;
  }

  .deposite-wrap {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --main-spacing-top: 30px;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  /* ------------------- 18px ------------------- */
  h4 {
    font-size: 1.125em;
  }

  /* ------------------- 15px ------------------- */
  h5 {
    font-size: 0.9375em;
  }

  /* ------------------- 15px ------------------- */
  h6 {
    font-size: 0.9375em;
  }

  p {
    font-size: 0.9375em;
  }

  /* ------------------- 14px ------------------- */
  .small-text {
    font-size: 0.875em;
  }

  .main-content-wrapper .row.xs-reverse {
    flex-direction: column-reverse;
  }

  .product-details img.bike-right {
    float: left;
  }

  .delivery-details button.Change-Address {
    float: left;
    margin-top: 14px;
  }

  .coupon-details-wrapper {
    margin-top: 20px;
  }

  .mid-wrap {
    height: 130px;
  }

  .mid-wrap.time-wrap {
    height: 130px;
  }

  .mid-wrap img {
    height: 130px !important;
  }

  .step-process {
    top: 65px;
  }

  .modal-dialog-centered {
    margin: 30px 15px;
  }

  .date-progress {
    justify-content: start;
  }

  .progressbar span.active::after {
    width: 35px;
  }

  .progressbar {
    width: 44px;
  }

  .progressbar-wrap {
    font-size: 0.8125em;
  }

  .blog-wrap .row {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .blog-wrap .card {
    margin-bottom: 20px;
  }

  /* .blog-wrap .card .card-img-top {
    height: 210px;
    width: auto !important;
    object-fit: cover;
    border-radius: 14px;
  } */

  .counter-row .counter-number {
    font-size: 1.875em;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
  }

  .download-app-section h4 {
    margin-top: 20px;
  }

  .download-app-btn-wrap img {
    margin-bottom: 20px;
  }

  .innerpage-slider-right-wrap {
    display: none;
  }

  .innerpage-main-head {
    padding: 20px 0;
    text-align: center;
  }

  .innerpage-main-head h1 {
    font-size: 3.0625em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .innerpage-main-head p {
    font-size: 16px;
    margin-top: 15px;
  }

  .innerpage--wrapper {
    padding: 20px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
  }

  .steps-border {
    width: 100px;
  }

  .helmate-wrap form p br {
    display: none;
  }

  .apply-coupon a {
    width: 30%;
    margin: 0;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 50%;
  }

  .renting-filter-wrapper .filter-offcanvasbtn {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .renting-filter-wrapper .responsive-sm {
    display: block;
  }

  .renting-filter-wrapper .responsive-xl {
    display: block;
  }

  /* Rent */
  .filter-row {
    margin: 28px 0 0 0;
    flex-wrap: wrap;
  }

  .detect-filter {
    width: 35%;
  }

  .date-filter {
    width: 60%;
  }

  .day-select-filter {
    margin: 20px 0;
  }

  .rent-search-filter {
    margin: 20px 0;
  }

  .renting-filter-wrapper .offcanvas-body {
    background: var(--light-theme);
  }

  .rent-filter-result .vehicle-wrapper {
    margin-top: 25px;
  }

  .freedo-primary-button.rounded-pill.text-dark.filter-offcanvasbtn {
    display: block;
  }

  .booknow-model .carousel-indicators {
    bottom: 28px;
    margin-bottom: 0;
  }

  .booknow-model h6.bike-brand {
    margin: 60px 0 7px 0;
  }

  .booknow-model .modal.show .modal-dialog {
    max-width: 90%;
  }

  /* Rent-end */

  .main-faq-wrapper {
    margin: 30px 0 0 0;
  }

  .contact-content {
    margin: 30px 0;
  }

  .office-content .row {
    flex-direction: column-reverse;
  }

  .support-wrap {
    margin: 20px 0 0 0;
  }

  .socail-support {
    margin: 0 0 10px 0;
  }

  /* Booking-details */
  .booking-status-box {
    width: 42%;
  }

  .booking-status-wrapper h5 {
    font-size: 18px;
  }

  .extend-ride-form button {
    margin-top: 10px;
  }

  #EndrideModal .modal-body {
    padding: 10px;
  }

  a.address-box {
    width: 60%;
  }
  .need-help-btn-container{
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 3.3%;
    }
}

@media screen and (max-width: 765px) {
  /*-------------------- booking journey --------------------*/

  /* slider */

  .slider-right-wrap {
    position: relative;
    display: none;
  }

  .slider-wrapper {
    padding: 20px 0 50px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
  }

  .slider-content p br,
  .slider-content h1 br {
    display: none;
  }

  .slider-content {
    text-align: center;
    justify-content: center;
  }

  .carousel-inner {
    overflow: visible;
  }

  .location-wrap {
    margin: 0 auto;
    width: 68%;
    margin-top: 40px;
  }

  .location-wrap input {
    padding-left: 135px;
    width: 100%;
  }

  .carousel-indicators {
    bottom: -63px;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 700px;
    margin: 10px;
    padding-bottom: 20px;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 200px;
  }

  .mid-wrap img {
    max-width: 100%;
  }

  .modal-body {
    padding: 0 15px;
  }

  .apply-coupon a {
    width: 30%;
  }

  /* List */
  .list-view-wrap {
    width: 30% !important;
  }
}

@media screen and (max-width: 575px) {
  .steps-border {
    width: 20px;
  }

  .mode-delivery .form-check {
    float: left;
    margin-top: 10px;
  }

  .product-details span.hero {
    margin-top: 12px;
  }

  .delivery-details .text-end {
    text-align: left !important;
    margin: 10px 0 0 0;
  }

  .time-slot-details .time-slot .nav-link.active,
  .time-slot-details .time-slot .nav-link:hover {
    font-size: 13px;
  }

  .time-slot-details .time-slot .nav-link {
    font-size: 13px;
  }

  .form-check-input {
    width: 15px;
    height: 15px;
  }

  .coupon-details-wrapper {
    padding: 15px;
  }

  /* .apply-coupon a {
    padding: 7px 10px;
  } */

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .slider-content h1 {
    font-size: 1.563em;
  }

  .location-wrap input {
    font-size: 0.875em;
  }

  .location-wrap button {
    position: absolute;
    z-index: 1;
    font-size: 14px;
  }

  .location-wrap {
    margin: 0 auto;
    width: 100%;
    margin-top: 40px;
  }

  .location-wrap input {
    padding-left: 125px;
  }

  .counter-row .counter-number {
    font-size: 1.5625em;
  }

  .faq-wrap {
    margin: 30px 0;
    padding: 11px;
  }

  .faq-wrap .accordion-item {
    margin: 20px 20px 0 20px;
  }

  .faq-wrapper {
    padding: 0;
  }

  footer p {
    padding: 0px 0;
    margin-bottom: 20px;
  }

  .footer-content {
    text-align: center;
  }

  .footer-content br {
    display: none;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 8px;
  }

  .download-app-btn-wrap img {
    width: 160px;
    margin-right: 10px;
    margin-bottom: 14px;
  }

  .copyright-wrap {
    padding: 10px 0;
  }

  footer {
    padding: 30px 0 0 0;
  }

  .signin-wrapper .offcanvas.offcanvas-end {
    width: 100%;
    margin: 0;
  }

  .signin-wrapper .offcanvas-body {
    padding: 15px;
  }

  .mission-wrapper .content-wrap {
    padding: 20px;
  }

  .mission-wrapper {
    margin: 30px 0 0 0;
    text-align: center;
  }

  .story-wrap img,
  .mission-wrap img,
  .vision-wrap img {
    max-width: 100%;
    height: 300px;
    margin: 0 auto;
    text-align: center;
  }

  .story-wrap,
  .mission-wrap,
  .vision-wrap {
    background-color: #f9f9f9;
    margin: 30px 0;
    padding: 30px;
  }

  .mission-wrap .row {
    flex-direction: column-reverse;
  }

  .check-out-step-wrap {
    display: none;
  }

  .breadcrumb-wrap a,
  .breadcrumb-wrap li {
    font-size: 0.875rem;
  }

  button.pay-now {
    width: 100%;
  }

  /* Rent */
  .day-select-filter {
    width: 60%;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }

  .day-select-filter {
    width: 80%;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 100%;
    margin-bottom: 10px;
  }

  .offcanvas {
    width: 100% !important;
  }

  .filter-head-wrap {
    margin-top: 35px;
  }

  /* Rent-end */
  .faq-banner-wrap {
    padding: 30px 0;
    height: 77px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 395px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 100%;
  }

  .coupon-right {
    padding: 0;
  }

  .change-address-wrapper-inner {
    position: relative;
  }

  .edit-del-add {
    position: absolute;
    top: 0;
    right: 0;
  }

  .change-address-wrapper-inner button {
    margin-left: 22px;
  }

  /* -----------Booking-details-------------- */

  .booking-bike-wrap {
    flex-wrap: wrap;
  }

  .bike-model-details .bike-name {
    font-size: 16px;
    margin: 3px 0 0 0;
  }

  .bike-model-details h6 {
    margin: 0;
    font-size: 14px;
  }

  .bike-model-details {
    font-size: 13px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .booking-bike-model {
    width: 42%;
  }

  .booking-status-wrapper {
    padding: 20px;
  }

  .Upcoming-ride-btn {
    font-size: 12px;
    right: 1%;
    top: 5px;
  }

  .booking-id-wrap h6 {
    font-size: 13px;
  }

  .booking-id-wrap h4 {
    font-size: 15px;
  }

  .booking-id-mid {
    font-size: 13px;
  }

  .booking-id-mid h4 {
    font-size: 14px;
    padding: 6px 0 5px 0;
  }

  .booking-detailsfaq .accordion-body {
    font-size: 14px;
  }

  .booking-detailsfaq .accordion-button.collapsed {
    font-size: 15px;
  }

  .booking-status-box {
    width: 70%;
  }

  .booking-status-wrapper h5 {
    font-size: 18px;
  }

  .booking-status-wrapper .modal-header {
    margin: 0;
    padding: 0;
  }

  .mode-wrap {
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .mode-wrap h6 {
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 0;
    width: 100%;
  }

  .mode-wrap .form-check {
    margin: 9px 20px 0 0;
  }

  .address-box {
    width: 62%;
  }

  .saved-address {
    flex-wrap: wrap;
  }

  .saved-address h2 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .booking-id-last a {
    margin: 0 5px;
  }

  #EndrideModal .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0;
  }

  .time-slot-details .timing-sets {
    margin: 0;
  }

  /* .modal-header .btn-close {
        margin: 0;
        padding: 0;
    } */

  .saved-address h2 {
    font-size: 14px;
  }

  .mode-wrap h6 {
    font-size: 14px;
  }

  .delivery-address-details-wrap {
    flex-wrap: wrap;
  }

  .delivery-address-details-wrap #map-view {
    width: 100%;
  }

  .delivery-address-details-wrap .details {
    margin-left: 0;
    margin-top: 20px;
  }

  .booking-status-box::after {
    position: absolute;
    content: "";
    width: 1px;
    background-color: var(--theme-color);
    height: 33%;

    z-index: -1;
    border: none;
  }

  .modal-body .booking-status-steps {
    flex-wrap: wrap;
    justify-content: center;
  }

  .booking-status-image-wrap.active::after {
    content: none !important;
  }
  .booking-status-box.noBdr::after{
    content: none;
  }
}

@media screen and (max-width: 460px) {
  .use-button-wrap {
    width: 100%;
    white-space: nowrap;
  }

  .address-details-wrap {
    width: 100%;
  }

  .change-address-wrapper-inner .freedo-outline-btn.rounded-pill {
    margin-left: 22px;
  }

  /* List */
  .list-view-wrap {
    width: 40% !important;
  }
}

@media screen and (max-width: 480px) {
  /* Booking-details */
  .booking-status-top {
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
  }

  .booking-bike-wrap {
    margin-bottom: 15px;
  }

  .booking-status-wrapper a.freedo-outline-btn-small {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .journey-btn {
    bottom: -2%;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 215px;
  }

  button.close-model {
    font-size: 18px;
  }

  .product-details img.bike-right {
    display: block;
    float: none;
    margin: 0 auto;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
  }

  /* Rent */
  .date-progress {
    flex-wrap: wrap;
  }

  .day-one {
    margin: 7px 0 0 0;
  }

  /* -----------Booking-details-------------- */
  .booking-id-first {
    flex-wrap: wrap;
  }

  .booking-status-box {
    width: 95%;
  }

  .extent-ride-details {
    margin-right: 30px;
  }

  .booking-status-wrapper {
    padding: 10px;
  }

  .bike-model-details {
    margin-left: 5px;
  }

  .Upcoming-ride-btn {
    /* margin: 10px 0; */
  }

  .extened-coupon-btn {
    white-space: nowrap;
  }

  .coupon-box-wrap {
    flex-wrap: wrap;
  }

  .coupon-content {
    margin-left: 0;
  }

  .coupon-box-wrap .left {
    width: 100%;
  }

  .apply-sec {
    margin-left: 18%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px #efefef;
    margin: 10px 0 0 0;
  }

  .apply-sec a {
    margin: 10px 0 0 0;
  }

  .save-code {
    margin: 10px 0 0 0;
  }

  a.address-box {
    width: 81%;
  }
}

@media screen and (max-width: 360px) {
  button.close-model {
    right: -6px;
    top: -12px;
    font-size: 14px;
    background: #fff;
    width: 25px;
    height: 25px;
    border: solid 1px #ccc;
    border-radius: 100%;
  }

  h4 {
    font-size: 0.9375em;
  }

  .contact-wrap a {
    display: block;
  }

  /* Rent */
  .progressbar-wrap {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .booknow-model .carousel-item {
    min-height: 200px;
  }

  .specific-wrap ul li span {
    width: 49%;
    white-space: break-spaces;
    display: inline-block;
    line-height: 35px;
    font-weight: 500;
    vertical-align: top;
  }
}

.slider {
  width: 100%;
  margin: 20px auto;
}

.slick-slide {
  margin: 0px 10px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #242b35;
}

.slick-active {
  opacity: 1;
}

.slick-current {
  opacity: 1;
}

/* 
.warhouse-price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.price-type-low {
    font-size: 13px;
    display: flex;
    align-items: center;
    position: relative;
    color: #242b35;
}

.pricetag {
    background: #1C2541;
    color: #fff;
    padding: 5px 20px;
    margin-bottom: 0;
    border-radius: 18px;
    font-size: 14px;
} */
.card-3 .price-type-low {
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-low::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #82cd47;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-high {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-high::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fc2828;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-medium {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.card-3 .price-type-medium::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff6d28;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .card-title {
  font-size: 16px;
  color: #1c2541;
  text-align: left;
}

.card-3 p {
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #1c2541;
  text-align: left;
}

.package {
  color: #14b8bb !important;
  font-size: 13px !important;
  padding: 3px 0 0 0;
}

.warehouse-img {
  border-radius: 10px;
}

.card-3 {
  border: none;
  transition: all ease-in-out 0.3s;
  opacity: 1;
  box-shadow: 3px 2px 19px 0 #00000026;
  margin: 20px;
  border-radius: 10px;
  text-align: center;
}

.card-3 .warehouse-img {
  border-radius: 10px;
  margin: 10px;
  object-position: center;
  width: 131px !important;
  height: 97px;
  margin: 0 auto;
  margin-top: -20px;
  box-shadow: 4px 3px 10px 3px #d9f3fb;
}

.card-3 .warhouse-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px 0;
}

.card-3 .pricetag {
  /* background: #1C2541; */
  color: #1c2541;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
}

.list-slider .slick-prev::before,
.list-slider .slick-next::before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-slider .slick-prev::before {
  background-image: url(../assets/images/arrow-left.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.list-slider .slick-next {
  right: 0;
}

.list-slider .slick-prev {
  left: -15px;
}

.list-slider .slick-next::before {
  background-image: url(../assets/images/arrow-right.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.fee-wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 14px;
}

.zero {
  margin-right: 10px;
}

.billing-wrap .table-responsive {
  border-radius: 7px;
  padding: 5px 10px;
}

.billing-wrap .table-responsive .table th {
  padding-bottom: 8px;
  font-size: 0.9375em;
}

.billing-wrap .table-responsive .table td {
  font-size: var(--small-text);
}

button.pay-now {
  margin-top: -47px;
  width: 100%;
  margin-bottom: 10px;
}

.billing-wrap .table-responsive .table td.active {
  color: #021325;
  font-weight: 500;
}

@media screen and (max-width: 340px) {
  .date-filter {
    flex-wrap: wrap;
    margin-right: 20px;
  }

  .date-filter .progressbar {
    width: 100px;
  }

  .date-filter .progressbar span.active::after {
    width: 88px;
  }

  .rent-search-filter a {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    font-size: 14px;
  }

  /* booking-details */
  .time-slot-details ul.nav.nav-tabs.time-slot {
    margin: 10px 0;
  }

  .searchbox-wrap button {
    padding-right: 7px;
  }

  .searchbox-wrap input {
    padding: 10px 7px;
    font-size: 14px;
  }

  .searchbox-wrap button span {
    margin-left: 0px;
    font-size: 13px;
  }
}

.danger-btn {
  padding: 5px 20px;
}

/* booking-details css end */
