.react-datepicker__input-container {
  display: flex;
  flex-direction: row-reverse;
}
div.rent-wrap .react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.8rem;
}
.react-datepicker__header {
  background: #fff;
}
.react-datepicker__input-container input {
  cursor: default;
}
.mid-wrap.datepicker input {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  caret-color: transparent;
  cursor: default;
}
.react-datepicker__input-container{
  caret-color: transparent;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  color: var(--theme-color);
}
.react-datepicker__close-icon {
  color: var(--theme-color);
  padding: 0 10px 0 0;
}
.react-datepicker__today-button {
  background: #fff;
  /* color: var(--theme-color); */
}
.react-datepicker__day--selected {
  background-color: var(--theme-color);
}
.react-datepicker__close-icon::after {
  background-color: var(--theme-color);
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  color: var(--theme-color);
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0%
    no-repeat padding-box;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow{
  top: 3px !important;
}
.react-datepicker__navigation {
  top: 8px;
}
.react-datepicker-popper {
  z-index: 1000;
}