/* -------------------- Setting Page -------------------- */
.Setting-wrapper {
  margin: 20px 0;
  margin: 20px 0;
}

.show {
  display: block !important;
}

.setting-banner {
  background: transparent linear-gradient(90deg, #59f7da00 0%, #f5feff 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 5px;
  background: transparent linear-gradient(90deg, #59f7da00 0%, #f5feff 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 5px;
}

.setting-tab-wrap .tab-content {
  width: 100%;
  padding: 10px 0 0 20px;
}

.setting-tab-wrap .ride {
  padding: 0 0 0 0 !important;
}

.custom-setting .setting-pane {
  padding: 10px 0px 10px 15px;
}

.setting-v-pill.nav-pills .nav-link {
  border-radius: 3px;
  padding: 5px 25px;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box !important;
  color: var(--dark-color);
  margin: 8px 0;
  padding-right: 30px;
  white-space: nowrap;
}

.setting-v-pill.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-radius: 3px;
  padding: 5px 25px;
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box !important;
  color: var(--dark-color);
  margin: 8px 0;
  padding-right: 30px;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
}

.mitc-check:checked {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.setting-v-pill.nav-pills .nav-link.active::after {
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 25px solid #1ce4f1;
  content: "";
  position: absolute;
  right: -14px;
  transition: all ease 0.6s;
}

.setting-tab-wrap .form-control {
  border: solid 1px #e0e0e0;
  font-size: 14px;
  color: #868e96;
  margin-bottom: 10px;
}

.setting-tab-wrap .form-control::-webkit-input-placeholder {
  color: #868e96;
}

.setting-tab-wrap label {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-color);
}

.booking-h-tab ul#pills-tab {
  border-bottom: none;
  display: flex;
  justify-content: start;
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-bottom: 10px;
}

.booking-h-tab .nav-pills .nav-link.active,
.booking-h-tab .nav-pills .show>.nav-link {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box !important;
  color: var(--text-dark) !important;
  padding: 8px 15px;
  font-size: 0.835rem;
  font-weight: 500;
}

.booking-h-tab .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  padding: 8px 15px !important;
  font-size: 0.835rem !important;
  margin-right: 20px;
  border: solid 1px var(--theme-color) !important;
  color: var(--theme-color);
}

.no-booking-img {
  height: 180px;
}

.no-booking-found {
  position: relative;
}

.no-booking-found::after {
  position: absolute;
  left: 12px;
  bottom: -7px;
  width: 6%;
  height: 3px;
  content: "";
  background: transparent linear-gradient(0deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
}

.gnhead {
  margin: 5px 0;
}

.gender-set-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.gender-set-wrap .form-check {
  margin-right: 43px;
  margin-bottom: 15px;
}

@media screen and (max-width: 400px) {
  .gender-set-wrap .form-check {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}

.gender-set-wrap .form-check label {
  font-weight: 400;
}

.update-profile-wrap {
  text-align: center;
}

.update-profile-wrap button {
  width: 70%;
}

.update-profile-wrap p {
  margin: 10px 0 0 0;
}

.emergency-wrap {
  background-color: #fff;
  padding: 18px;
  box-shadow: 3px 3px 21px 0px #7090b04f;
  border-radius: 10px;
}

.emergency-wrap h6 {
  text-align: center;
}

.emergency-wrap h6 img {
  margin: 0 5px 0 0;
}

.verify-em p {
  font-size: 0.85em;
  margin-top: 10px;
}

.otp-input input {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  border: 1px solid #989898;
  margin: 0 5px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}

.otp-input input:focus {
  border: 1px solid #2bc7c9 !important;
  transition: all (0.4s);
}

.flip-card {
  height: 310px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.front {
  transform: rotateY(0);
}

.back {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: transparent;
  color: inherit;
}

.flip-card-back {
  background-color: transparent;
  transform: rotateY(180deg);
}

.setting-tab-wrap .form-select {
  border: solid 1px #e0e0e0;
  font-size: 14px;
  color: #868e96;
  margin-bottom: 10px;
}

.set-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.set-top .left {
  display: flex;
  align-items: center;
}

.set-top .right {
  margin-right: 8%;
}

.user-pic {
  position: relative;
}

.user-pic .user-image {
  height: 100px;
  padding: 0px !important;
  width: 100px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  object-fit: cover;
}

.modal-body .selected-image {
  height: 60vh;
  padding: 0px !important;
  width: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000a5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.camera-image {
  width: 30px !important;
  height: auto !important;
  border-radius: 0% !important;
}

.user-pic:hover .overlay {
  opacity: 1;
}

.user-pic-signup:hover .overlay {
  opacity: 1;
}

.CircularProgressbar-path {
  stroke: #1ed2df !important;
}

.overlay .text {
  color: white;
  font-size: 12px;
  text-align: center;
  width: 90%;
  margin: 5px 0 0 0;
  line-height: 18px;
}

.user-signup .user-image {
  height: 100px;
  padding: 0px !important;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-signup {
  display: flex;
  justify-content: center;
}

.user-pic-signup {
  width: 100px;
  position: relative;
}

img.verified-use {
  position: absolute;
  right: 0px;
  top: 10px;
  height: 20px;
}

img.edit-use {
  position: absolute;
  right: 30px;
  bottom: -15px;
  height: 25px;
}

.set-top .left .user-details {
  margin-left: 20px;
}

.kychead {
  margin: 5px 0;
}

.kyc-set-wrap {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
}

.kyc-set-wrap .form-check {
  margin-right: 43px;
  margin-bottom: 15px;
}

.kyc-set-wrap .form-check label {
  font-weight: 400;
}

.downloadfile-wrap {
  background-color: #f9fdfd;
  border: dashed 1px #dddddd;
  padding: 50px;
  text-align: center;
}

.downloadfile-wrap p {
  margin: 0;
}

.downloadfile-wrap p {
  margin: 0;
  color: var(--dark-color);
  font-size: 0.8375em;
  font-weight: 500;
}

p.support-file {
  color: #707070 !important;
  font-size: 0.8375em;
}

p.file-drag {
  font-weight: 600;
  margin: 8px 0;
}

.uoloaded {
  margin: 15px 20px 0 20px;
  font-size: 12px;
}

.uoloaded h6 {
  font-size: 14px;
  color: var(--dark-text);
}

.uploaded-filename {
  background: #f5feff;
  padding: 10px;
  white-space: nowrap;
  display: inline-block;
}

.uploaded-filename a {
  background: #dddddd;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 100%;
  margin: 0 0 0 20px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.upload-save-next {
  display: flex;
  justify-content: center;
  margin: 0 0 10px 0;
}

.upload-save-next a {
  padding: 5px 50px;
  margin: 0 8px;
}

.uploaded-fileprocess {
  background: transparent;
  padding: 10px;
  white-space: nowrap;
  display: block;
  border: solid 1px var(--theme-color);
  display: flex;
  justify-content: space-between;
}

.uploaded-fileprocess a {
  background: #dddddd;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 100%;
  margin: 0 0 0 20px;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.down-processwrap .progress {
  margin-top: -4px;
  background: transparent;
  height: 3px;
}

.down-processwrap .progress-bar {
  background: transparent linear-gradient(90deg, #1c8688 0%, #a9e5e5 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.after-upload-wrap {
  margin: 20px 20px 0 20px;
}

.after-upload-wrap h6 {
  font-size: 14px;
  color: var(--dark-text);
}

.afterload-file {
  background: #f5feff;
  padding: 10px;
  white-space: nowrap;
  border: solid 1px #a9e5e580;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  box-shadow: 0 4px 6px #a9e5e580;
}

.afterload-file a {
  color: var(--red);
  font-size: 14px;
}

.adhaar-wrap {
  background-color: #fff;
  box-shadow: 2px 2px 22px 2px #7090b024;
  padding: 30px 20px;
  border-radius: 8px;
  width: 85%;
  margin: 20px auto;
  text-align: center;
}

.adhaar-wrap p {
  font-size: 15px;
  color: #452cc8;
  width: 50%;
  text-align: center;
  margin: 20px auto 0;
  font-size: 0.835rem;
}

.adhaar-process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 10px 0;
  padding: 0;
  font-size: 14px;
  color: #452cc8;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}

.ongoing-ride-box {
  border: solid 1px #ddd;
  padding: 20px;
  text-align: center;
  border-radius: 14px;
  position: relative;
  margin: 10px 0 0 0;
}

.ongoing-ride-box img {
  max-width: 35%;
}

.ongoing-ride-box span {
  position: absolute;
  right: 4px;
  top: -15px;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  background-color: var(--light-theme);
  animation: animate 1.5s ease-in-out infinite forwards;
}

.help-success {
  gap: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.help-success p:first-child {
  color: var(--theme-color);
}

.help-success img {
  width: 100px;
}

@keyframes animate {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.upcoming-details .content-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
}

.upcoming-details .content-wrap-modified {
  margin: 20px 0 0 0;
}

.upcoming-details .content-wrap .date {
  color: #707070;
  display: flex;
  width: 40%;
  justify-content: end;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
}

.upcoming-details .content-wrap .date .active {
  color: var(--theme-color);
  position: relative;
}

.upcoming-details .content-wrap .date .hr-border {
  height: 1px;
  width: 50%;
  border: dashed 1px #dddddd;
  display: inline-block;
  margin: 0 10px;
}

.ride-address {
  position: relative;
}

.ride-address .pickup-ride::before {
  content: "";
  min-height: 30px;
  width: 2px;
  border-left: dashed 3px var(--theme-color);
  display: inline-block;
  margin: 21px 14px 0 3px;
}

.ride-address .pickup-ride::after {
  content: "";
  width: 10px;
  height: 10px;
  background: transparent linear-gradient(180deg, #4af3e0 0%, #14e2f3 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  left: 0;
  border-radius: 100%;
  top: 5px;
}

.ride-address p {
  display: flex;
  flex-direction: column;
}

.pickup-ride {
  display: flex;
  font-size: 12px;
  color: var(--dark-text);
}

.ExtendRide .pickup-ride::before {
  content: "";
  min-height: 166px;
  width: 2px;
  border-left: dashed 3px var(--theme-color);
  display: inline-block;
  margin: 21px 14px 0 3px;
}

.drop-ride {
  display: flex;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  color: var(--dark-text);
}

.drop-ride.active {
  color: var(--theme-color);
}

.ride-address .drop-ride::after {
  content: "";
  width: 10px;
  height: 10px;
  background: transparent linear-gradient(180deg, #4af3e0 0%, #14e2f3 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  left: 0;
  top: 7px;
}

.ride-address .drop-ride::before {
  content: "";
  display: inline-block;
  margin-right: 22px;
}

.pickup-ride span,
.drop-ride span {
  width: 35%;
  display: flex;
  font-size: 12px;
  padding-top: 2px;
}

/* Extend Ride Details */
.extentionCard {
  position: absolute;
  left: 0;
  top: 0;
}

.Extribbon {
  position: absolute;
  width: 90px;
  max-width: none;
  padding: unset;
  height: unset;
}

.extentionText {
  position: relative;
  left: 10px;
  font-size: 12px;
  color: #fff;
}

.all-ride-details {
  margin: 4px 0 0 0;
  justify-content: space-between;
  align-items: center;
}

.all-ride-details ul {
  padding: 0;
  list-style-type: none;
  display: inline-block;
  margin: 0;
}

.all-ride-details ul li {
  display: inline-block;
  border-right: solid 1px #909090;
  padding: 0 8px;
  line-height: 17px;
  font-size: 14px;
}

.all-ride-details ul li:first-child {
  padding-left: 0;
}

.all-ride-details ul li:last-child {
  border: none;
}

.all-ride-details .danger-btn {
  margin-right: 10px;
  font-size: 14px;
  padding: 5px 11px;
}

.all-ride-details .danger-btn {
  margin-right: 10px;
  font-size: 14px;
  padding: 5px 11px;
}

.all-ride-details .freedo-primary-button {
  padding: 7px 15px;
  font-size: 14px;
}

.all-ride-details .freedo-outline-btn-small {
  padding: 7px 11px;
  font-size: 13px;
}

.complete-ride {
  border-radius: 6px;
  padding: 1px 18px 15px;
  border: 1px dashed #2bc7c9;
  margin: 10px 0px 12px 0px;
  position: relative;
}

.complete-ride .content-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.complete-ride .content-wrap-modified {
  margin: 20px 0 0 0;
}

.content-wrap span.status-badge {
  position: absolute;
  right: -13px;
  top: -38px;
  padding: 5px 10px;
  border-radius: 38px;
  font-size: 12px;
  color: #fff !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

/* Rides & Status Start */
.content-wrap span.status-badge.cancelled,
.ended {
  background-color: var(--red);
  color: #fff;
}

.content-wrap span.status-badge.booked {
  background-color: #ffc30b;
  color: #fff;
}

.cancelled::after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.ended::after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.content-wrap span.status-badge.approval {
  background-color: var(--success);
}

.approval::after {
  content: "\f828";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.content-wrap span.status-badge.ongoing {
  background-color: var(--theme-color);
}

.content-wrap span.status-badge.active {
  background-color: var(--theme-color);
}

.ongoing::after {
  content: "\f21c";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}


.content-wrap span.status-badge.upcoming {
  background-color: var(--theme-color);
}

.upcoming::after {
  content: "\f21c";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.content-wrap span.status-badge.upcoming {
  background-color: #ffc30b;
  color: #021325;
}

.upcoming::after {
  content: "\f274";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booked::after {
  content: "\f274";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.content-wrap span.status-badge.completed {
  background-color: #228b22;
}

.content-wrap span.status-badge.closed {
  background-color: #228b22;
}
.completed::after {
  content: "\f11e";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.closed::after {
  content: "\f11e";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.content-wrap span.status-badge.pending {
  background-color: #ff7518;
}

.content-wrap span.status-badge.created {
  background-color: #ff7518;
}
.pending::after {
  content: "\f071";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.created::after {
  content: "\f071";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

/* Rides & Status Ends */

/* Rides & Status Start */
.booking-id-wrap span.Upcoming-ride-btn.cancelled,
.ended {
  background-color: var(--red);
  color: #fff;
}

.cancelled::after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.ended::after {
  content: "\f00d";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.approval {
  background-color: var(--success);
}

.approval::after {
  content: "\f828";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.ongoing {
  background-color: var(--theme-color);
}

.ongoing::after {
  content: "\f21c";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.upcoming {
  background-color: var(--theme-color);
}

.upcoming::after {
  content: "\f21c";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.upcoming {
  background-color: #ffc30b;
  color: #021325;
}

.upcoming::after {
  content: "\f274";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.completed {
  background-color: #228b22;
}

.completed::after {
  content: "\f11e";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

.booking-id-wrap span.Upcoming-ride-btn.pending {
  background-color: #ff7518;
}

.pending::after {
  content: "\f071";
  font-family: "FontAwesome";
  float: left;
  padding-right: 5px;
}

/* Rides & Status Ends */
.complete-ride .content-wrap .date {
  color: #707070;
  display: flex;
  width: 40%;
  justify-content: end;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
}

.complete-ride .content-wrap .date .active {
  color: var(--theme-color);
  position: relative;
}

.complete-ride .content-wrap .date .hr-border {
  height: 1px;
  width: 40%;
  border: solid 1px #dddddd;
  display: inline-block;
  margin: 0 10px;
}

.content-wrap img {
  margin-right: 6px;
}

.ride-address.endride-address .pickup-ride::before {
  content: "";
  min-height: 30px;
  width: 2px;
  border-left: solid 3px var(--theme-color);
  display: inline-block;
  margin: 22px 14px 0 3px;
}

.ride-address.endride-address .pickup-ride span,
.ride-address.endride-address .drop-ride span {
  font-weight: 400;
  font-size: 12px;
}

.ride-address.endride-address .pickup-ride {
  font-weight: 400;
}

.Selfie-wrap {
  background-color: #f9fdfd;
  border: dashed 1px #dddddd;
  padding: 20px;
  text-align: center;
  margin: 0 auto;
}

.take-selfie-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}

.uselfie_card {
  background: var(--light-theme);
  border: dashed 1px var(--theme-color);
  padding: 10px 20px;
  margin: 0 30px;
  border-radius: 10px;
  white-space: nowrap;
}

.tselfie_card {
  background: var(--light-theme);
  border: dashed 1px var(--theme-color);
  padding: 20px;
  margin: 0 30px;
  border-radius: 10px;
  white-space: nowrap;
}

.tselfie_card img,
.uselfie_card img {
  margin-right: 16px;
  cursor: pointer;
}

.uselfie_card span,
.tselfie_card span {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.Selfie-wrap p {
  margin: 0;
  color: var(--dark-color);
  font-size: 0.8375em;
  font-weight: 500;
}

.selfie {
  height: 180px;
  border: none;
}

.camera i,
.other-doc i {
  color: var(--light-theme);
  background-color: #2bc7c9;
  padding: 13px;
  border-radius: 55px;
  line-height: 0.9em;
  margin: 0 10px;
}

.other-img {
  height: 60px;
}

.KYC-button-wrap {
  text-align: center;
  margin: 20px 0 0 0;
}

.KYC-button-wrap button {
  width: 70%;
}

.successfully-card {
  background-color: #f9fdfd;
  border: dashed 1px #dddddd;
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.successfully-card img {
  margin: 0 0 20px 0;
}

.successfully-card p {
  color: var(--theme-color);
  margin: 0;
}

.success-text p {
  color: var(--theme-color);
  margin: 0;
}

.success-text-dl {
  color: var(--theme-color);
  margin: 0;
  font-size: 16px !important;
  font-weight: 400;
}

.address-card a.address-box {
  width: 23%;
  position: relative;
}

.address-card a.address-box:hover {
  border: solid 1px #2bc7c9 !important;
  transition: all ease 0.3s;
}

.address-card .card-body {
  position: relative;
}

.address-card .card-text {
  font-size: 0.835rem;
}

.address-card i {
  position: absolute;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  top: -12px;
  box-shadow: 1px 1px 8px 0px #2bc7c94f;
}

.area,
.area2 {
  display: block;
  clear: both;
}

.end-ride-slot.time-slot-details ul.nav.nav-tabs.time-slot {
  margin: 10px;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.end-ride-slot.time-slot-details .time-slot li.nav-item {
  width: 33%;
  text-align: center;
  white-space: nowrap;
  padding: 0;
}

.default-address {
  color: var(--list-color);
  font-size: 12px;
}

.default-address i {
  background: #fff;
  box-shadow: 2px 3px 9px 2px #4df3de4a;
  border-radius: 100%;
  border-radius: 20px;
  border: solid 5px #fff;
}

.pagination-wrap .page-link {
  font-size: 14px;
  color: var(--theme-color);
  background-color: var(--bs-pagination-bg);
}

.pagination-wrap .disabled>.page-link,
.pagination-wrap .page-link.disabled {
  color: var(--list-color);
  pointer-events: none;
  background-color: transparent;
  border-color: var(--bs-pagination-disabled-border-color);
}

.pagination-wrap .active>.page-link,
.pagination-wrap .page-link.active,
.pagination-wrap .page-link:hover {
  background-color: var(--theme-color);
  color: white;
  border-color: var(--theme-color);
}

.pagination-wrap .page-link:focus {
  box-shadow: none;
}

/* Query-model */
#queryModal a {
  color: var(--theme-color);
  text-decoration: none;
}

#queryModal .form-control,
#queryModal .form-select {
  font-size: 0.875em;
  font-weight: 400;
  background-color: #fefefe;
  border: var(--bs-border-width) solid #8ecce570;
  color: #595c5f !important;
}

#queryModal .form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875em;
  font-weight: 500;
}

/*---------------- Blog-page-------------- */
.blog-main-img {
  width: 40%;
}

blog-main-img img {
  width: 100%;
}

.blog-content-main {
  width: 60%;
  background: #fff;
  margin-left: -60px;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 17px 22px #74747414;
}

.bg-freedo-theme {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box;
}

.badge {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}

.blog-time {
  font-size: 12px;
}

.time-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-time span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.blog-content {
  background: #fff;
  margin-top: -50px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 17px 22px #74747414;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.popular-blog-img {
  width: 14%;
  margin-right: 10px;
}

.popular-content {
  width: 82%;
}

.popular-content p {
  font-size: 12px;
}

.search-blog .input-group {
  box-shadow: 0px 8px 17px #dddddd4a;
  border-radius: 8px;
}

.search-blog .input-group-text {
  background: #f4f4f4;
  border-color: transparent;
  padding: 15px 25px;
}

.search-blog .form-control {
  border: none;
  padding: 15px 15px;
  font-size: 15px;
}

.search-options {
  position: relative;
  height: 100%;
}

.search-options ul {
  list-style-type: none;
  background: #fff;
  display: flex;
  box-shadow: 2px 3px 8px #ddd;
  padding: 20px 10px;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
}

.search-options ul li {
  background: transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box;
  padding: 5px 10px;
  margin: 5px 3px;
  border-radius: 5px;
  font-size: 13px;
}

.search-options ul li a {
  color: #fff;
}

.location-options {
  position: relative;
}

.location-options ul {
  list-style-type: none;
  background: #fff;
  box-shadow: 2px 3px 8px #ddd;
  padding: 20px 20px;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  margin: 0;
}

.location-options ul li a {
  color: var(--text-dark);
  display: block;
  line-height: 35px;
  font-size: 14px;
}

.location-options ul li a.active {
  color: var(--theme-color);
}

.action-icon-wrap {
  margin-top: -17px;
}

/* Single-blog-page */
.single-blog-main-img {
  width: 100%;
}

.single-blog-main-img img {
  width: 100%;
}

.single-blog-content-main {
  width: 90%;
  background: #fff;
  margin-left: 0;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 17px 22px #74747414;
  margin: -50px auto 20px;
}

p.single-blog-detail {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 20px;
}

/*--------------- DL-Css start ---------------*/

.downloadfile-wrap h6 {
  color: var(--theme-color);
  font-weight: normal;
}

.front-cam,
.back-cam {
  text-align: center;
  margin: 20px;
}

.front-cam img,
.back-cam img {
  width: 203px;
  height: 113px;
}

.front-cam button,
.back-cam button {
  background-color: transparent;
  border: none;
  display: block;
}

.front-cam button img,
.back-cam button img {
  width: 48px;
  height: 48px;
}

/*-------------------DL-Css-End -------------------*/

/*------------------- REsponsive -------------------*/
@media screen and (max-width: 1280px) {

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 30%;
  }

  .day-select-filter .form-select {
    padding: 0.675rem 3.25rem 0.675rem 0.975rem;
    margin-left: 0px;
  }

  .detect-filter .form-control {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1200px) {

  /* Home */
  .slider-content h1 {
    font-size: 3.125em;
  }

  .slider-content p {
    font-size: 0.875em;
  }

  .slider-img-wrap {
    width: 210px;
  }

  .slider-img-wrap video,
  .slider-img-wrap img.slide-3 {
    height: 265px;
  }

  .slider-img-wrap img {
    height: 150px;
  }

  .slider-right-wrap .top-content,
  .slider-right-wrap .bottom-content {
    font-size: 1em;
  }

  .features-content {
    overflow-x: auto;
  }

  .features-content .card {
    width: 220px;
    margin: 0 10px 23px 5px;
  }

  .time-box {
    width: 142px;
  }

  .mid-wrap.time-wrap {
    height: 145px;
    overflow-y: auto;
  }

  .time-box {
    padding: 6px 16px 6px 5px;
    width: 102px;
    margin: 7px;
  }

  .deposite-wrap {
    flex-wrap: wrap;
    margin: 15px 0 0 0;
  }

  .deposite-wrap span {
    margin-bottom: 10px;
  }

  p.price {
    font-size: 0.995em;
  }

  .deposite-wrap span i {
    padding: 0 4px;
  }

  /* ------Rent ------*/
  .filter-offcanvasbtn {
    display: none;
  }

  .filter-row {
    flex-wrap: wrap;
  }

  .day-select-filter {
    width: 40%;
    margin: 20px 0;
  }

  .rent-search-filter {
    margin: 10px 0;
  }

  .date-filter {
    width: 55%;
    margin-bottom: 20px;
  }

  .detect-filter {
    width: 40%;
    margin-bottom: 20px;
  }

  .vihicle-type-box {
    justify-content: start;
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .filter-list-wrap .form-check {
    margin: 12px 4px;
  }

  /* List */
  .list-view-wrap {
    width: 25% !important;
    padding: 8px 10px !important;
  }

  /* Booking-details */
  .extent-ride-details {
    font-size: 14px;
    margin-right: 45px;
  }

  .extend-ride-form h6 {
    font-size: 14px;
  }

  /* Settings Page */
  .pickup-ride span,
  .drop-ride span {
    width: auto;
    white-space: nowrap;
    margin-right: 20px;
  }

  .upcoming-details .content-wrap .date,
  .complete-ride .content-wrap .date {
    width: 57%;
  }

  .tselfie_card img,
  .uselfie_card img {
    display: block;
    margin: 16px auto;
  }

  .address-card a.address-box {
    width: 30%;
    position: relative;
  }

  .display-result-filter .form-select {
    width: 25%;
  }
}

@media screen and (max-width: 280px) {
  .all-ride-details .freedo-primary-button {
    padding: 7px 15px;
    font-size: 12px;
  }

  .all-ride-details .danger-btn {
    margin-right: 10px;
    font-size: 12px;
    padding: 5px 11px;
  }

  .shine {
    top: 0px;
    left: 110px;
    height: 36px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1300ms infinite;
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(50px);
    }

    100% {
      transform: skewX(20deg) translateX(100px);
    }
  }
}

@media screen and (max-width: 325px) {
  .filter-list-wrap .form-check {
    margin: 12px 23px;
  }

  .shine {
    top: 0px;
    left: 110px;
    height: 36px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1300ms infinite;
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(50px);
    }

    100% {
      transform: skewX(20deg) translateX(100px);
    }
  }
}

@media screen and (max-width: 992px) {
  .contact-wrap {
    text-align: center;
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    color: var(--white);
  }

  .offcanvas.offcanvas-end .navbar-nav {
    margin: 30px 0 0 0 !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 15px 25px;
  }

  .login-wrap {
    margin-left: 25px;
    margin-top: 15px;
  }

  .main-content-wrapper {
    margin: 0 20px;
  }

  .container-fluid {
    padding: 0 20px;
  }

  .Info-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Slider */
  .slider-img-wrap {
    width: 185px;
  }

  .slider-content h1 {
    font-size: 2.5em;
  }

  .slider-wrapper .col-12 {
    padding: 0;
  }

  .slider-content p {
    font-size: 0.875em;
  }

  .light-freedo-btn {
    padding: 10px 10px;
  }

  .light-freedo-btn {
    padding: 10px 10px;
    background-color: #fafafa;
    font-size: 1em;
    border: none;
    width: 60%;
  }

  .location-wrap input {
    padding-left: 105px;
  }

  .slider-right-wrap .top-content {
    top: 10px;
    right: 0;
    font-size: 0.9375em;
  }

  .slider-right-wrap .bottom-content {
    bottom: 20px;
    left: 0;
    font-size: 1em;
  }

  .slider-right-wrap .top-content .fa-solid.fa-circle-check,
  .slider-right-wrap .bottom-content .fa-solid.fa-circle-check {
    font-size: 28px;
    margin: 0 10px;
    color: var(--dark-color);
  }

  .Renting-wrapper .nav-pills .nav-link {
    color: var(--dark-color);
  }

  .vehicle-wrapper {
    overflow-x: auto;
    grid-gap: 35px;
    display: flex;
  }

  .Renting-wrapper .card {
    width: 300px;
    margin-bottom: 20px;
  }

  .product-details img.bike-right {
    max-width: 100%;
  }

  .details-wrapper .vr {
    margin: 0 22px;
  }

  /*-------------------- booking journey --------------------*/
  .journey-btn {
    left: 19%;
    right: 20%;
  }

  .time-box {
    width: 100%;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 32%;
    padding-bottom: 10px;
  }

  /* .city-model-wrap .city-name  {
    width: 62%;
  } */

  .mid-wrap {
    height: 126px;
    width: 126px;
  }

  .mid-wrap img {
    max-width: 100%;
    height: 100%;
  }

  .time-box {
    margin: 4px 7px;
  }

  .time-box {
    height: 40px;
    padding-left: 15px;
  }

  .mid-wrap.time-wrap {
    height: 126px;
  }

  .Journey-wrap p {
    margin-bottom: 1em;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 28px;
  }

  .step-process {
    top: 40px;
  }

  .modal-dialog-centered {
    margin: 30px 20px;
  }

  .location-wrap .freedo-primary-button,
  .freedo-outline-btn {
    font-size: 0.875em;
    padding: 8px 20px;
    width: 150px;
  }

  .shine {
    top: 0px;
    left: 110px;
    height: 36px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1800ms infinite;
    transform: skewX(20deg) translateX(300px);
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(50px);
    }

    100% {
      transform: skewX(20deg) translateX(120px);
    }
  }

  
  .freedo-primary-button-lg {
    padding: 10px 45px;
  }

  .progressbar {
    width: 38px;
  }

  .progressbar span.active::after {
    width: 30px;
  }

  .mid-wrap.datepicker {
    width: 200px;
  }

  .Renting-wrapper .nav-pills .nav-link {
    margin: 0px 0;
  }

  ul.loacton-list {
    white-space: nowrap;
    overflow-x: auto;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 10px 0 0;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 185px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img {
    height: 150px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap img.slide-3,
  .innerpage-slider-right-wrap .slider-img-wrap img.slide-2 {
    height: 190px;
  }

  .innerpage-img-head h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  .inner-bottom-text {
    margin-right: 20px;
  }

  .display-result-filter .form-select {
    width: 40%;
    font-size: 0.9375em;
    font-weight: 300;
  }

  .filter-row .form-control {
    font-size: 0.9375em;
    font-weight: 300;
  }

  .rent-filter-result .vehicle-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 45%;
  }

  .faq-banner-wrap {
    height: 100px;
  }

  footer p {
    padding: 0px 0;
  }

  .change-address-wrapper .offcanvas-body {
    background: #fff;
  }

  /* Booking-details */
  #Booking-Status .modal-dialog {
    max-width: 92%;
    margin-right: auto;
    margin-left: auto;
  }

  .extend-ride-wrapper .modal-dialog {
    max-width: 92%;
  }

  #EndrideModal .modal-dialog {
    max-width: 92%;
  }

  .booking-status-box img {
    margin: 0 0 20px 0;
  }

  .toggle-title a {
    text-decoration: underline;
    font-size: 15px;
  }

  .toggle-title i {
    right: 0;
    font-size: 15px;
    top: 7px;
  }

  .refund-staus-details h6 {
    font-size: 14px;
  }

  .refund-staus-details p {
    color: #95989a;
    padding: 3px 0 0 0;
    font-size: 15px;
  }

  .booking-status-box {
    width: 40%;
  }

  a.address-box {
    width: 40%;
    border-radius: 5px;
    border: solid 1px #d1f3f3;
    padding: 10px;
    margin: 0 15px 20px 0;
    background: #fff;
    color: var(--dark-color);
  }

  .address-box p {
    margin: 8px 0 0 20px;
    font-size: 13px;
  }

  #coupon-selected .offcanvas-body {
    background-color: #fff;
  }

  .delivery-address-details-wrap #map-view {
    width: 25%;
  }

  /*------------------ Settings Page ------------------*/
  .navbar-collapse {
    background-color: var(--dark-color);
  }

  .pickup-ride span,
  .drop-ride span {
    width: 30%;
  }

  .all-ride-details {
    margin: 9px 0 28px 0;
    flex-wrap: wrap;
  }

  .all-ride-details ul {
    margin: 0 0 10px 0;
  }

  .ongoing-ride-box img {
    max-width: 45%;
  }

  .complete-ride .content-wrap {
    margin: 20px 0;
    flex-wrap: wrap;
    align-items: center;
  }

  .setting-tab-wrap .accordion-button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .accordion-button:not(.collapsed) {
    color: var(--dark-color);
  }

  .accordion-button::after {
    background-image: var(--bs-accordion-btn-icon);
    width: 15px;
    height: 15px;
    background-size: 15px;
  }

  #EndrideModal2 .modal-dialog {
    max-width: 95%;
  }

  /*----------------- blog-page----------------------- */
  .blog-main-img {
    width: 50%;
  }

  .blog-content-main {
    width: 57%;
    margin-left: -81px;
  }
}

@media screen and (max-width: 865px) {
  :root {
    --main-text: 14px;
    --main-heading: 20px;
  }

  /* .apply-coupon a {
    border: solid 1px var(--theme-color);
    color: var(--theme-color);
    padding: 6px 15px;
    border-radius: 31px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  } */

  .apply-coupon {
    flex-wrap: wrap;
  }

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .helmate-wrap form p {
    margin: 0;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
    margin: 10px 0;
  }

  .value-button {
    width: 100% !important;
  }

  input#number {
    width: 100% !important;
  }

  .time-slot-details .timing-sets {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  /*-------------------- booking journey --------------------*/
  .journey-btn {
    left: 9%;
    right: 9%;
  }

  .mid-wrap {
    margin: 0 auto;
    font-weight: 500;
  }

  .mid-wrap img {
    height: 125px;
  }

  .city-wrap::after,
  .date-wrap::after,
  .reset-wrap::after {
    margin-bottom: 0px;
  }

  .innerpage-slider-right-wrap .slider-img-wrap {
    width: 165px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 0px;
  }

  .mission-wrapper .content-wrap {
    padding: 0 10px;
  }

  .mission-wrapper .content-wrap p {
    margin: 10px 0 15px 0;
  }

  .mission-wrapper .content-wrap h6 {
    margin-bottom: 30px;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
  }

  .day-select-filter {
    width: 100%;
  }

  .steps-inner-wrap {
    justify-content: start;
    overflow-x: auto;
  }

  /* ---------------Rent-page--------------- */
  .vehicle-wrapper .card a {
    margin-top: 20px;
  }

  .name-price-wrap {
    flex-wrap: wrap;
  }

  .deposite-wrap {
    margin: 0;
  }

  @media screen and (max-width: 865px) {
    .deposite-wrap {
      margin: 6px -7px;
    }
  }

  /* ----------------- Setting-page ------------------*/
  h6 {
    font-size: 0.875rem;
  }

  .adhaar-process li {
    margin: 0 15px;
  }

  .adhaar-wrap p {
    width: 83%;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --main-spacing-top: 30px;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  /* ------------------- 18px ------------------- */
  h4 {
    font-size: 0.875em;
  }

  /* ------------------- 15px ------------------- */
  h5 {
    font-size: 0.9375em;
  }

  /* ------------------- 14px ------------------- */
  .small-text {
    font-size: 0.875em;
  }

  p {
    font-size: 0.875em;
  }

  .main-content-wrapper .row.xs-reverse {
    flex-direction: column-reverse;
  }

  .product-details img.bike-right {
    float: left;
  }

  .delivery-details button.Change-Address {
    float: left;
    margin-top: 14px;
  }

  .coupon-details-wrapper {
    margin-top: 20px;
  }

  .mid-wrap {
    height: 130px;
  }

  .mid-wrap.time-wrap {
    height: 130px;
  }

  .mid-wrap img {
    height: 130px !important;
  }

  .mid-wrap .verified-use {
    position: absolute;
    top: -3px;
    height: 15px !important;
    left: -6px;
  }

  .step-process {
    top: 65px;
  }

  .modal-dialog-centered {
    margin: 30px 15px;
  }

  .date-progress {
    justify-content: space-between;
    margin: auto;
  }

  .progressbar-wrap {
    font-size: 0.8125em;
  }

  .blog-wrap .row {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .blog-wrap .card {
    margin-bottom: 20px;
  }

  /*  .blog-wrap .card .card-img-top {
    height: 210px;
    width: 100% !important;
    object-fit: cover;
    border-radius: 14px;
  } */

  .counter-row .counter-number {
    font-size: 1.875em;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
  }

  .download-app-section h4 {
    margin-top: 20px;
  }

  .download-app-btn-wrap img {
    margin-bottom: 20px;
  }

  .innerpage-slider-right-wrap {
    display: none;
  }

  .innerpage-main-head {
    padding: 20px 0;
    text-align: center;
  }

  .innerpage-main-head h1 {
    font-size: 3.0625em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .innerpage-main-head p {
    font-size: 16px;
    margin-top: 15px;
  }

  .innerpage--wrapper {
    padding: 20px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  }

  .steps-border {
    width: 100px;
  }

  .helmate-wrap form p br {
    display: none;
  }

  .apply-coupon a {
    width: 30%;
    margin: 0;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 50%;
  }

  .renting-filter-wrapper .filter-offcanvasbtn {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .renting-filter-wrapper .responsive-sm {
    display: block;
  }

  .renting-filter-wrapper .responsive-xl {
    display: none;
  }

  /* Rent */
  .filter-row {
    margin: 28px 0 0 0;
    flex-wrap: wrap;
  }

  .detect-filter {
    width: 35%;
  }

  .date-filter {
    width: 60%;
  }

  .day-select-filter {
    margin: 20px 0;
    width: 35%;
  }

  .rent-search-filter {
    margin: 20px 0;
  }

  .display-result-filter .form-select {
    width: 49%;
  }

  .renting-filter-wrapper .offcanvas-body {
    background: var(--light-theme);
    height: 100%;
  }

  .rent-filter-result .vehicle-wrapper {
    margin-top: 25px;
  }

  .freedo-primary-button.rounded-pill.text-dark.filter-offcanvasbtn {
    display: block;
  }

  .booknow-model .carousel-indicators {
    bottom: 28px;
    margin-bottom: 0;
  }

  .booknow-model h6.bike-brand {
    margin: 60px 0 7px 0;
  }

  .booknow-model .modal.show .modal-dialog {
    max-width: 90%;
  }

  /* Rent-end */

  .main-faq-wrapper {
    margin: 30px 0 0 0;
  }

  .contact-content {
    margin: 30px 0;
  }

  .office-content .row {
    flex-direction: column-reverse;
  }

  .support-wrap {
    margin: 20px 0 0 0;
  }

  .socail-support {
    margin: 0 0 10px 0;
  }

  /* Booking-details */
  .booking-status-box {
    width: 42%;
  }

  .booking-status-wrapper h5 {
    font-size: 18px;
  }

  .extend-ride-form button {
    margin-top: 10px;
  }

  #EndrideModal .modal-body {
    padding: 10px;
  }

  a.address-box {
    width: 60%;
  }

  /*----------------seting-page----------------------*/
  .upcoming-details .content-wrap .date,
  .complete-ride .content-wrap .date {
    width: 34%;
  }

  .all-ride-details {
    border-bottom: solid 1px #ddd;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .setting-tab-wrap hr {
    display: none;
  }

  .pickup-ride span,
  .drop-ride span {
    width: 20%;
  }

  .take-selfie-wrap {
    flex-direction: column;
  }

  .uselfie_card {
    margin: 0 0 15px 0;
    font-size: 0.935rem;
    width: 100%;
  }

  .tselfie_card {
    margin: 15px 0 0 0;
    font-size: 0.935rem;
    width: 100%;
  }

  .emergency-wrap {
    margin: 18px 0 0 0;
  }

  /* --------------------Blog-page-------------------*/
  .blog-main-img {
    width: 100%;
  }

  .blog-content-main {
    width: 100%;
    background: #fff;
    margin: -100px 20px 0 20px;
  }
}

@media screen and (max-width: 830px) {
  .mode-wrap {
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .mode-wrap h6 {
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 0;
    width: 100%;
  }

  .mode-wrap .form-check {
    margin: 9px 20px 0 0;
  }

  .need-help-btn-container {
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 6%;
  }
}

@media screen and (max-width: 769px) {
  /*-------------------- booking journey --------------------*/

  /* slider */

  .slider-right-wrap {
    position: relative;
    display: none;
  }

  .slider-wrapper {
    padding: 20px 0 50px 0;
    background: transparent linear-gradient(22deg, #e4fffa 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  }

  .slider-content p br,
  .slider-content h1 br {
    display: none;
  }

  .slider-content {
    text-align: center;
    justify-content: center;
  }

  .carousel-inner {
    overflow: visible;
  }

  .location-wrap {
    margin: 0 auto;
    width: 68%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }

  .shine {
    position: absolute;
    top: 0;
    left: 200px;
    height: 38px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1800ms infinite;
    z-index: 1;
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(80px);
    }

    100% {
      transform: skewX(20deg) translateX(130px);
    }
  }

  .location-wrap input {
    padding-left: 135px;
    width: 100%;
  }

  .carousel-indicators {
    bottom: -63px;
  }

  .city-wrap,
  .date-wrap,
  .reset-wrap {
    width: 100%;
    margin: 10px;
    padding-bottom: 20px;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 200px;
  }

  .mid-wrap img {
    max-width: 100%;
  }

  .modal-body {
    padding: 5px 15px;
  }

  .apply-coupon a {
    width: 30%;
  }

  /* List */
  .list-view-wrap {
    width: 30% !important;
  }

  /* Setting page */
  .address-card a.address-box {
    width: 45%;
  }

  .vr {
    display: none;
  }

  .need-help-btn-container {
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 3.3%;
  }
}

@media screen and (max-width: 768px) {
  .need-help-btn-container {
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 5%;
  }
}

@media screen and (max-width: 575px) {
  .steps-border {
    width: 20px;
  }

  .mode-delivery .form-check {
    float: left;
    margin-top: 10px;
  }

  .product-details span.hero {
    margin-top: 12px;
  }

  .delivery-details .text-end {
    text-align: left !important;
    margin: 10px 0 0 0;
  }

  .time-slot-details .time-slot .nav-link.active,
  .time-slot-details .time-slot .nav-link:hover {
    font-size: 13px;
  }

  .time-slot-details .time-slot .nav-link {
    font-size: 13px;
  }

  .form-check-input {
    width: 15px;
    height: 15px;
  }

  .coupon-details-wrapper {
    padding: 15px;
  }

  /* 
  \\.apply-coupon a {
    padding: 7px 10px;
  } */

  .helmate-wrap {
    flex-wrap: wrap;
  }

  .slider-content h1 {
    font-size: 1.563em;
  }

  .location-wrap input {
    font-size: 0.875em;
  }

  .location-wrap button {
    position: relative;
    z-index: 0;
    width: 170px;
  }

  .shine {
    position: absolute;
    top: 0;
    left: 190px;
    height: 38px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1800ms infinite;
    z-index: 1;
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(80px);
    }

    100% {
      transform: skewX(20deg) translateX(160px);
    }
  }

  #cityModalBtn {
    width: 100%;
  }

  .location-wrap {
    margin: 0 auto;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }

  .location-wrap input {
    padding-left: 125px;
  }

  .counter-row .counter-number {
    font-size: 1.5625em;
  }

  .faq-wrap {
    margin: 30px 0;
    padding: 11px;
  }

  .faq-wrap .accordion-item {
    margin: 20px 20px 0 20px;
  }

  .faq-wrapper {
    padding: 0;
  }

  footer p {
    padding: 0px 0;
    margin-bottom: 20px;
  }

  .footer-content {
    text-align: center;
  }

  .footer-content br {
    display: none;
  }

  .download-app-section {
    text-align: center;
  }

  ul.loacton-list {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  ul.loacton-list li a {
    padding: 20px 8px;
  }

  .download-app-btn-wrap img {
    width: 160px;
    margin-right: 10px;
    margin-bottom: 14px;
  }

  .copyright-wrap {
    padding: 10px 0;
  }

  footer {
    padding: 30px 0 0 0;
  }

  .signin-wrapper .offcanvas.offcanvas-end {
    width: 100%;
    margin: 0;
  }

  .signin-wrapper .offcanvas-body {
    padding: 15px;
  }

  .mission-wrapper .content-wrap {
    padding: 20px;
  }

  .mission-wrapper {
    margin: 30px 0 0 0;
    text-align: center;
  }

  .story-wrap img,
  .mission-wrap img,
  .vision-wrap img {
    max-width: 100%;
    height: 300px;
    margin: 0 auto;
    text-align: center;
  }

  .story-wrap,
  .mission-wrap,
  .vision-wrap {
    background-color: #f9f9f9;
    margin: 30px 0;
    padding: 30px;
  }

  .mission-wrap .row {
    flex-direction: column-reverse;
  }

  .check-out-step-wrap {
    display: none;
  }

  .breadcrumb-wrap a,
  .breadcrumb-wrap li {
    font-size: 0.875rem;
  }

  button.pay-now {
    width: 100%;
  }

  .content-wrap h2::after {
    content: "";
    width: 44px;
    height: 4px;
    background-color: var(--theme-color);
    position: absolute;
    bottom: -5px;
    left: 50%;
    top: 28px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* Rent */
  .day-select-filter {
    width: 60%;
  }

  .detect-filter,
  .date-filter {
    width: 100%;
    margin-right: 0px;
  }

  .day-select-filter {
    width: 100%;
  }

  .rent-filter-result .vehicle-wrapper .card {
    width: 100%;
    margin-bottom: 10px;
  }

  .offcanvas {
    width: 100% !important;
  }

  .filter-head-wrap {
    margin-top: 35px;
  }

  /* Rent-end */
  .faq-banner-wrap {
    padding: 30px 0;
    height: 77px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 395px;
  }

  .change-address-wrapper .offcanvas.offcanvas-end {
    width: 100%;
  }

  .coupon-right {
    padding: 0;
  }

  .change-address-wrapper-inner {
    position: relative;
  }

  .edit-del-add {
    position: absolute;
    top: 0;
    right: 0;
  }

  .change-address-wrapper-inner button {
    margin-left: 22px;
  }

  /* -----------Booking-details-------------- */

  .booking-bike-wrap {
    flex-wrap: wrap;
  }

  .bike-model-details .bike-name {
    font-size: 16px;
    margin: 3px 0 0 0;
  }

  .bike-model-details h6 {
    margin: 0;
    font-size: 14px;
  }

  .bike-model-details {
    font-size: 13px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .booking-bike-model {
    width: 42%;
  }

  .booking-status-wrapper {
    padding: 20px;
  }

  .Upcoming-ride-btn {
    font-size: 12px;
  }

  .booking-id-wrap h6 {
    font-size: 13px;
  }

  .booking-id-wrap h4 {
    font-size: 15px;
  }

  .booking-id-mid {
    font-size: 13px;
  }

  .booking-id-mid h4 {
    font-size: 14px;
    padding: 6px 0 5px 0;
  }

  .booking-detailsfaq .accordion-body {
    font-size: 14px;
  }

  .booking-detailsfaq .accordion-button.collapsed {
    font-size: 15px;
  }

  .booking-status-box {
    width: 70%;
  }

  .booking-status-wrapper h5 {
    font-size: 18px;
  }

  .booking-status-wrapper .modal-header {
    margin: 0;
    padding: 0;
  }

  .mode-wrap {
    white-space: nowrap;
    flex-wrap: wrap;
  }

  .mode-wrap h6 {
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 0;
    width: 100%;
  }

  .mode-wrap .form-check {
    margin: 9px 20px 0 0;
  }

  .address-box {
    width: 62%;
  }

  .saved-address {
    flex-wrap: wrap;
  }

  .saved-address h2 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .booking-id-last a {
    margin: 0 5px;
  }

  #EndrideModal .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0;
  }

  .time-slot-details .timing-sets {
    margin: 0;
  }

  /* .modal-header .btn-close {
        margin: 0;
        padding: 0;
    } */

  .saved-address h2 {
    font-size: 14px;
  }

  .mode-wrap h6 {
    font-size: 14px;
  }

  .delivery-address-details-wrap {
    flex-wrap: wrap;
  }

  .delivery-address-details-wrap #map-view {
    width: 100%;
  }

  .delivery-address-details-wrap .details {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Setting-page */
  .upcoming-details .content-wrap .date,
  .complete-ride .content-wrap .date {
    width: 100%;
    margin: 10px 0 0 0;
    justify-content: start;
  }

  .complete-ride .content-wrap .date .hr-border {
    width: 19%;
  }

  .upcoming-details .content-wrap {
    flex-wrap: wrap;
  }

  .upcoming-details .content-wrap .date .hr-border {
    width: 20%;
  }

  .upload-save-next a {
    padding: 4px 41px;
    margin: 0 8px;
    font-size: 0.835rem;
  }

  .adhaar-wrap {
    width: 95%;
  }

  .adhaar-wrap img {
    max-width: 40%;
  }

  .adhaar-wrap p {
    width: 100%;
  }

  .ride-address.endride-address .pickup-ride::before,
  .ride-address .pickup-ride::before {
    display: none;
  }

  .vr {
    display: none;
  }

  .ExtendRide .pickup-ride::before {
    content: "";
    min-height: 30px;
    width: 2px;
    border-left: dashed 3px var(--theme-color);
    display: inline-block;
    margin: 21px 14px 0 -18px;
  }

  .ride-address.endride-address .pickup-ride,
  .pickup-ride {
    margin-left: 22px;
  }

  .drop-ride {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 22px;
  }

  .ride-address.endride-address .pickup-ride,
  .pickup-ride {
    margin-left: 0;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 22px;
    margin-bottom: 10px;
  }

  .ride-address .drop-ride::before {
    margin-right: 0;
  }

  .pickup-ride span,
  .drop-ride span {
    width: 100%;
  }

  /* Blog page */
  .blog-content-main {
    margin: -100px 5px 0 5px;
    padding: 15px;
  }

  .blog-content {
    padding: 15px;
  }

  .blog-content {
    padding: 15px;
    margin: -50px 5px 0 5px;
  }
}

@media screen and (max-width: 460px) {
  .use-button-wrap {
    width: 100%;
    white-space: nowrap;
  }

  .address-details-wrap {
    width: 100%;
  }

  .change-address-wrapper-inner .freedo-outline-btn.rounded-pill {
    margin-left: 22px;
  }

  /* List */
  .list-view-wrap {
    width: 40% !important;
  }

  /* MITC form Purpose to hire */
  .purpose-hire-box {
    display: flex;
    flex-direction: column;
  }

  .need-help-btn-container {
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 3.1%;
  }

  .shine {
    position: absolute;
    top: 0;
    left: 150px;
    height: 38px;
    width: 20px;
    background: rgba(167, 253, 254, 0.4);
    transition: all .3s linear;
    animation: glowing 1800ms infinite;
    z-index: 1;
  }

  @keyframes glowing {
    0% {
      transform: skewX(20deg) translateX(30px);
    }

    50% {
      transform: skewX(20deg) translateX(80px);
    }

    100% {
      transform: skewX(20deg) translateX(120px);
    }
  }
}

@media screen and (max-width: 480px) {

  /* Booking-details */
  .booking-status-top {
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
  }

  .booking-bike-wrap {
    margin-bottom: 15px;
  }

  .booking-status-wrapper a.freedo-outline-btn-small {
    width: 100%;
    text-align: center;
  }

  /*--------------- Setting page ---------------*/
  .address-card a.address-box {
    width: 100%;
  }

  /*--------------- DL-Css start ---------------*/
  .front-cam img,
  .back-cam img {
    width: 138px;
    height: 78px;
  }

  .front-cam button img,
  .back-cam button img {
    width: 38px;
    height: 38px;
  }

  .camera-wrap {
    flex-wrap: wrap;
  }

  .uoloaded,
  .after-upload-wrap {
    margin: 15px 0 0 0;
  }

  /*--------------- DL-Css End ---------------*/
}

@media screen and (max-width: 414px) {
  .journey-btn {
    bottom: -2%;
  }

  .mid-wrap,
  .mid-wrap.time-wrap {
    width: 215px;
  }

  button.close-model {
    font-size: 18px;
  }

  .product-details img.bike-right {
    display: block;
    float: none;
    margin: 0 auto;
  }

  .coupon-details-wrapper .helmate-wrap nav {
    width: 100%;
  }

  /* Rent */
  .date-progress {
    flex-wrap: wrap;
  }

  .day-one {
    margin: 7px 0 0 0;
  }

  /* -----------Booking-details-------------- */
  .booking-id-first {
    flex-wrap: wrap;
  }

  .booking-status-box {
    width: 95%;
  }

  .extent-ride-details {
    margin-right: 30px;
  }

  .booking-status-wrapper {
    padding: 10px;
  }

  .bike-model-details {
    margin-left: 5px;
  }

  .Upcoming-ride-btn {
    /* margin: 10px 0; */
    top: 5px;
  }

  .extened-coupon-btn {
    white-space: nowrap;
  }

  .coupon-box-wrap {
    flex-wrap: wrap;
  }

  .coupon-content {
    margin-left: 0;
  }

  .coupon-box-wrap .left {
    width: 100%;
  }

  .apply-sec {
    margin-left: 18%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px #efefef;
    margin: 10px 0 0 0;
  }

  .apply-sec a {
    margin: 10px 0 0 0;
  }

  .save-code {
    margin: 10px 0 0 0;
  }

  a.address-box {
    width: 100%;
  }

  /* ---------------Setting-page ---------------*/

  .upload-save-next a {
    margin: 10px 8px;
  }
}

@media screen and (max-width: 360px) {
  button.close-model {
    right: -6px;
    top: -12px;
    font-size: 14px;
    background: #fff;
    width: 25px;
    height: 25px;
    border: solid 1px #ccc;
    border-radius: 100%;
  }

  h4 {
    font-size: 0.9375em;
  }

  .contact-wrap a {
    display: block;
  }

  /* Rent */
  .progressbar-wrap {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .booknow-model .carousel-item {
    min-height: 200px;
  }

  .specific-wrap ul li span {
    width: 49%;
    white-space: break-spaces;
    display: inline-block;
    line-height: 35px;
    font-weight: 500;
    vertical-align: top;
  }

  .display-result-filter .form-select {
    width: 100%;
  }
}

.slider {
  width: 100%;
  margin: 20px auto;
}

.slick-slide {
  margin: 0px 10px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #242b35;
}

.slick-active {
  opacity: 1;
}

.slick-current {
  opacity: 1;
}

.card-3 .price-type-low {
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-low::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #82cd47;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-high {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.card-3 .price-type-high::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fc2828;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .price-type-medium {
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  color: #1c2541;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.card-3 .price-type-medium::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ff6d28;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  left: -13px;
}

.card-3 .card-title {
  font-size: 16px;
  color: #1c2541;
  text-align: left;
}

/* .slick-slide.slick-active:hover {
    border: solid 3px #1de5f0;
    box-shadow: 3px 2px 19px 0 #1de5f01c;
} */

.card-3 p {
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  color: #1c2541;
  text-align: left;
}

.package {
  color: #14b8bb !important;
  font-size: 13px !important;
  padding: 3px 0 0 0;
}

.warehouse-img {
  border-radius: 10px;
}

.card-3 {
  border: none;
  transition: all ease-in-out 0.3s;
  opacity: 1;
  box-shadow: 3px 2px 19px 0 #00000026;
  margin: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 44% !important;
}

.card-3 .warehouse-img {
  border-radius: 10px;
  object-position: center;
  width: 131px !important;
  height: 97px;
  margin: -20px auto 0;
  box-shadow: 4px 3px 10px 3px #d9f3fb;
}

.card-3 .warhouse-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px 0;
}

.card-3 .pricetag {
  /* background: #1C2541; */
  color: #1c2541;
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
}

.list-slider .slick-prev::before,
.list-slider .slick-next::before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-slider .slick-prev::before {
  background-image: url(../../assets/images/arrow-left.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.list-slider .slick-next {
  right: 0;
}

.list-slider .slick-prev {
  left: -15px;
}

.list-slider .slick-next::before {
  background-image: url(../../assets/images/arrow-right.png);
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
}

.fee-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 14px;
}

.zero {
  margin-right: 10px;
}

.billing-wrap .table-responsive {
  border-radius: 7px;
  padding: 5px 10px;
}

.billing-wrap .table-responsive .table th {
  padding-bottom: 8px;
  font-size: 0.9375em;
}

.billing-wrap .table-responsive .table td {
  font-size: var(--small-text);
  width: 73%;
}

button.pay-now {
  margin-top: -47px;
  width: 100%;
  margin-bottom: 10px;
}

.billing-wrap .table-responsive .table td.active {
  color: #021325;
  font-weight: 500;
}

@media screen and (max-width: 340px) {
  .date-filter {
    flex-wrap: wrap;
  }

  .date-filter .progressbar {
    width: 100px;
  }

  .date-filter .progressbar span.active::after {
    width: 88px;
  }

  .rent-search-filter a {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    font-size: 14px;
  }

  .display-result-filter .form-select {
    padding: 0.375rem 1.395rem 0.375rem 0.795rem;
  }

  /* booking-details */
  .time-slot-details ul.nav.nav-tabs.time-slot {
    margin: 10px 0;
  }

  .searchbox-wrap button {
    padding-right: 7px;
  }

  .searchbox-wrap input {
    padding: 10px 7px;
    font-size: 14px;
  }

  .searchbox-wrap button span {
    margin-left: 0px;
    font-size: 13px;
  }

  /* ---------------Setting-page--------------- */
  .pickup-ride {
    margin-bottom: 10px;
  }

  .Selfie-wrap {
    padding: 10px;
  }

  .gender-set-wrap .form-check {
    margin-right: 14px;
  }

  .end-ride-slot.time-slot-details .time-slot li.nav-item {
    width: 54%;
  }
}

.small-text .text-decoration-underline {
  padding: 0px 5px;
}

.pagination {
  float: right;
}

.pagination .page-item a {
  padding: 3px 5px;
  font-size: 13px;
  color: #00bcca;
}

.active>.page-link,
.page-link.active {
  background-color: #18e3f2;
  color: white !important;
  border-color: #00bcca;
}

.disabled>.page-link,
.page-link.disabled {
  color: grey !important;
}

.completed .card-body {
  display: flex;
  flex-wrap: wrap;
}

.completed .pagination {
  width: 100%;
  justify-content: right;
}

.custom-setting .select_address {
  display: none;
}

.custom-setting .saved-address {
  margin: 0px;
  padding: 0px 10px;
}

.custom-setting .saved-address h2 {
  display: inline-block;
}

.backdrop {
  background: #938e8e7a;
}

.no-current-booking {
  justify-content: center !important;
  flex-direction: column;
  line-height: 34px;
}

.no-current-booking h2::after {
  width: 58px !important;
  left: 40% !important;
}

.no-current-booking .freedo-primary-button {
  padding: 3px 22px !important;
  color: black;
}

.Price-details-accordian .table-responsive {
  padding: unset;
}


.profileImgPreview {
  height: 190px;
  width: 190px;
  border-radius: 50%;
  padding: 0px !important;
}

.profileImgPreviewProgress {
  height: 210px;
  width: 210px;
  border-radius: 50%;
  padding: 0px !important;
}

.upload-list-options {
  background-color: #fff;
  position: absolute;
  top: 50%;
  width: 180px;
  border-radius: 4px;
  box-shadow: 2px 3px 11px 2px #00000024;
  cursor: pointer;
}

.upload-list-options ul li {
  list-style-type: none;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
}

.upload-list-options ul li a {
  color: var(--dark-color);
  display: flex;
  align-items: center;
}

.upload-list-options img {
  width: 25px !important;
  margin-right: 8px;
  height: auto !important;
  border-radius: 0% !important;
}

.overlay:focus .upload-list-options {
  display: none;
  opacity: 0;
}

/* check animation custom CSS here */
:root {
  --check-size: 80px;
  --check-border-width: 5px;
  --checkmark-width: calc(var(--check-size) / 2);
  --checkmark-height: calc(var(--checkmark-width) / 2);
  --checkmark-left: calc(var(--checkmark-width) / 2);
  --checkmark-top: 50%;
  --checkmark-color: var(--theme-color);
}

.checkmark-wrapper {
  width: var(--check-size);
  height: var(--check-size);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.checkmark-wrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--check-border-width) solid #f8f8f8;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  z-index: 0;
}

.checkmark-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--check-border-width) solid transparent;
  border-left-color: var(--checkmark-color);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  z-index: 1;
  animation: circle linear forwards .7s;
}

.checkmark {
  height: var(--checkmark-height);
  width: var(--checkmark-width);
  position: absolute;
  opacity: 0;
  left: var(--checkmark-left);
  top: var(--checkmark-top);
  display: block;
  border-left: var(--check-border-width) solid var(--checkmark-color);
  border-bottom: var(--check-border-width) solid var(--checkmark-color);
  transform-origin: left top;
  transform: rotate(-45deg);
  animation: checkmark linear both 1s;
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }

  80% {
    height: 0;
    width: 0;
    opacity: 0;
  }

  90% {
    height: var(--checkmark-height);
    width: 0;
    opacity: 1;
  }

  100% {
    height: var(--checkmark-height);
    width: var(--checkmark-width);
    opacity: 1;
  }
}

@keyframes circle {
  0% {
    border-color: transparent;
    border-left-color: var(--checkmark-color);
  }

  90% {
    transform: rotate(-360deg);
    border-color: transparent;
    border-left-color: var(--checkmark-color);
  }

  100% {
    transform: rotate(-360deg);
    border-color: var(--checkmark-color);
  }
}