.freedo-partner-wrapper {
    background-color: #F0FEFC;
}

.freedo-partner-wrapper h1 {
    font-size: 3.125rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.freedo-partner-wrapper p {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 26px;
    color: #707070;
}

.partner-banner-right {
    position: relative;
    display: flex;
    width: 900px;
    justify-content: end;
    height: 100%;
}

.partner-services-wrap {
    margin: 50px 0 0 0;
}

.partner-services-icon-row p {
    text-align: left;
    line-height: 26px;
    color: #707070;
}

.freedo-business {
    margin: 50px 0 0 0;
    position: relative;
    padding: 50px 0;
}

.freedo-business::after {
    content: "";
    position: absolute;
    background: url('../../../assets/images/theme-bg.png');
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    width: 693px;
    height: 100%;
    background-size: contain;
}

.freedo-business::before {
    content: "";
    position: absolute;
    background: url('../../../assets/images/theme-bg-2.png');
    background-repeat: no-repeat;
    right: 0;
    bottom: 0;
    width: 302px;
    height: 100%;
    background-size: cover;
}

.freedo-business .business-content {
    text-align: left;
    margin-left: 30px;
}

.freedo-business .business-content h1 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}

.freedo-business .business-content p {
    line-height: 26px;
    color: #707070;
    margin-bottom: 10px;
}

.joinfreedo-wrap h2 {
    font-size: 44px;
}

.join-freedo-content h5 {
    font-size: 18px;
    font-weight: 500;
}

.join-freedo-content {
    margin: 20px 0 0 0;
}

.joinfreedo-wrap p {
    line-height: 26px;
    color: #707070;
}

.renting-vehicle-wrap {
    background: url('../../../assets/images/join-us.png');
    background-size: cover;
    width: 100%;
    padding: 80px 0;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    margin: 50px 0 0 0;
}

.renting-vehicle-wrap h4 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 15px;

}

.renting-vehicle-wrap p {
    font-size: 18px;
    margin: 0 0 30px 0;
}

.startup-form .modal-body {
    padding: 0;
}

.form-wrapper-partner {
    padding: 30px 30px 30px 10px;
    width: 100%;
}

.startup-form .modal-header {
    border: 0;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 999999;
}

.startup-form .modal-header .btn-close {
    font-size: 14px;
    opacity: 1;
}

.startup-form .modal-dialog.modal-dialog-centered.modal-xl {
    max-width: 95%;
}

.form-wrapper-partner ul#pills-tab {
    background: #f7f7f7;
    border-radius: 5px;
    width: 90%;
    border: none;
    margin: 0 auto;
}


.form-wrapper-partner li.nav-item {
    width: 50%;
    text-align: center;
}

.form-wrapper-partner .nav-pills .nav-link {
    width: 100%;
    opacity: .5;
    transition: all ease .3s;
    color: var(--dark-color);
}

.form-wrapper-partner .nav-pills .nav-link.active,
.form-wrapper-partner .nav-pills .nav-link:hover,
.form-wrapper-partner .nav-pills .show>.nav-link {
    color: var(--dark-color);
    background: transparent linear-gradient(90deg, #59F7DA 0%, #18E3F2 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    background-color: transparent;
    opacity: 1;
    transition: all ease .3s;

}

.form-wrapper-partner .tab-content .input-group-text {
    border-right: 0;
    background-color: #fff
}

.form-wrapper-partner .tab-content .form-control,
.form-wrapper-partner .tab-content .form-select {
    border-left: 0;
    font-size: 14px;
    color: var(--dark-color);
    padding: 10px;
}

.form-wrapper-partner .tab-content .form-label {

    font-size: 14px;
    color: var(--dark-color);
}

.form-wrapper-partner .tab-content .vr {
    align-self: center;
    width: 2px;
    background-color: #ddd;
    opacity: 1;
    height: 25px;
    display: flex;
    align-items: center;
}

.modelimg {
    object-fit: cover;
}

@media screen and (max-width: 1199px) {
    .freedo-business .business-content h1 {
        font-size: 44px;
    }
}

@media screen and (max-width: 1107px) {
    .freedo-partner-wrapper h1 {
        font-size: 2.125rem;
    }

}

@media screen and (max-width: 1080px) {
    .freedo-business .business-content h1 br {
        display: none;
    }

    .freedo-business .business-content h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 991px) {
    .freedo-partner-wrapper h1 {
        font-size: 1.875rem;
    }

    .freedo-partner-wrapper p {
        font-size: 14px;
    }

    .freedo-business .business-content h1 {
        font-size: 30px;
    }

    .joinfreedo-wrap h2 {
        font-size: 30px;
    }

    .joinfreedo-wrap h2 br {
        display: none;
    }

    .partner-services-wrap {
        margin: 30px 0 0 0;
    }

    .freedo-business {
        margin: 30px 0 0 0;
        padding: 30px 0;
    }

    .renting-vehicle-wrap {
        padding: 40px 0;
        margin: 20px 0 0 0;
    }
}

@media screen and (max-width: 767px) {
    .partner-banner-right {
        width: 590px;
    }

    .freedo-partner-wrapper h1 {
        font-size: 1.25rem;
        margin: 10px 0;
    }

    .freedo-business .business-content {
        text-align: center;
        margin-left: 0;
        margin-top: 17px;
    }

    .joinfreedo-wrap .row {
        justify-content: center;
    }

    .joinfreedo-wrap h2 {
        font-size: 1.25em;
        text-align: center;
        margin-bottom: 20px;
    }

    .join-freedo-content p {
        margin-bottom: 20px;
    }

    .freedo-business::after,
    .freedo-business::before {
        width: 300px;
    }

    .renting-vehicle-wrap h4 {
        font-size: 1.5em;
    }

    .renting-vehicle-wrap p {
        font-size: 1em;
    }

    .modelimg {
        display: none;
    }

    .form-wrapper-partner {
        text-align: left;
        margin: 10px;
        padding: 30px 10px;
    }

    .form-wrapper-partner ul#pills-tab {
        width: 100%;
    }
}

@media screen and (max-width:680px) {
    .freedo-part-ban-content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .partner-banner-right {
        width: 400px;
        margin-bottom: 30px;
    }

    .freedo-partner-wrapper {
        text-align: center;
        padding: 30px 0;
    }

    .partner-services-icon-row .services-icons {
        padding: 4px;
        margin: 10px 0;
        font-size: 15px;
    }

    .freedo-business .business-content h1 {
        font-size: 1.25rem
    }
}

@media screen and (max-width:575px) {
    .partner-banner-right {
        width: 300px;
    }

    .faq-wrap .accordion-body {
        font-size: 14px;
    }

    .form-wrapper-partner .nav-pills .nav-link.active,
    .form-wrapper-partner .nav-pills .nav-link:hover,
    .form-wrapper-partner .nav-pills .show>.nav-link,
    .form-wrapper-partner .nav-pills .nav-link {

        font-size: 14px;
        padding: 10px 5px;
    }
}